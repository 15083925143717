import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { getToken, setToken } from './authentication';
import i18n from '~/i18n';
import { getTenant } from '~/services/multiTenant';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

api.CancelToken = axios.CancelToken;

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `${token}`;
  }

  const tenant = getTenant();
  if (tenant) {
    // eslint-disable-next-line no-param-reassign
    config.headers['x-tenant-id'] = `${tenant}`;
  }
  // eslint-disable-next-line no-param-reassign
  config.headers.Language = i18n.language;
  return config;
});

// Adicionar tratamento erro de sessao expirada
api.interceptors.response.use(
  async (response) => {
    const { data } = response;
    if (data.new_token) {
      setToken(data.new_token);
    }
    return response;
  },
  async (error) => {
    console.error(error);
    if (error.message === 'Network Error') {
      NotificationManager.warning(
        i18n.t('common:NoConnection'),
        i18n.t('common:NetworkError'),
        5000,
      );
    }

    return Promise.reject(error);
  },
);

export default api;
