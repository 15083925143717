import { withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PF2MCustomDragLayer from '~/components/PF2MCustomDragLayer';
import PF2MTabs from '~/components/PF2MTabs';
import useTabletConfiguration from '~/services/tabletConfiguration';
import { getLoadAllocations, getTruckAllocations } from '~/store/dispatch/actions';
import { getMaterials } from '~/store/nref/actions';
import AllocationsTab from './AllocationsTab';
import AutoAllocationLogs from './AutoAllocationLogs';
import MappingsTab from './MappingsTab';
import styles from './styles';

const LoadSchedules = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const { value: dispatchUpdateTime } = useTabletConfiguration('dispatch_update_time');

  const tabs = [
    { key: 0, label: translate('common:Allocation') },
    { key: 1, label: translate('common:Mapping') },
    { key: 2, label: translate('common:AutoAllocationLogs'), allowedUserLevels: [1] },
  ];

  const handleChange = (event, value) => {
    setSelectedTab(value);
  };

  useEffect(() => {
    async function fetchData() {
      dispatch({ type: 'SKIP_LOADING', payload: true });
      dispatch(getLoadAllocations());
      dispatch(getTruckAllocations());

      dispatch(getMaterials());
      dispatch({ type: 'SKIP_LOADING', payload: false });
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    if (dispatchUpdateTime !== undefined) {
      const time = dispatchUpdateTime * 1000;
      const interval = setInterval(() => setDataLoaded(false), time);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [dispatchUpdateTime]);

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        hideSyncButton
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (
        <>
          <AllocationsTab />
          <PF2MCustomDragLayer />
        </>
      ) : null}
      {selectedTab === 1 ? (<MappingsTab />) : null}
      {selectedTab === 2 ? (<AutoAllocationLogs />) : null}
    </>
  );
};

export default withStyles(styles)(LoadSchedules);
