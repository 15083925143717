import { withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PF2MTabs from '~/components/PF2MTabs';
import EquipmentStatusTab from './EquipmentStatusTab';
import styles from './styles';

const EquipmentStatus = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const { t: translate } = useTranslation();


  const tabs = [
    { key: 0, label: translate('common:EquipmentStatus') },
  ];

  const handleChange = (event, value) => {
    setSelectedTab(value);
  };

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        hideSyncButton
      >
        {tabs}
      </PF2MTabs>
      {selectedTab === 0 ? (<EquipmentStatusTab />) : null}
    </>
  );
};

export default withStyles(styles)(EquipmentStatus);
