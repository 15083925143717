import React, { useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ZendeskAPI } from 'react-zendesk';
import styles from './styles';
import PF2MMenu from '~/components/PF2MMenu';
import OperatorGroup from '../OperatorGroup';
import EquipmentGroup from '../EquipmentGroup';
import MaterialGroup from '../MaterialGroup';
import Elements from '../Elements';
import Main from '../Main';
import TurnGroup from '../TurnGroup';
import Codes from '../Codes';
import Loads from '../Loads';
import Weighings from '../Weighings';
import Checklist from '../Checklist';
import DMT from '../DMT';
import TeamGroup from '../TeamGroup';
import Drilling from '../Drilling';
import Tractor from '../Tractor';
import OperationType from '../OperationType';
import HourmeterManager from '../HourmeterManager';
import WebUsers from '../WebUsers';
import Closures from '~/pages/Closure';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import { setAlertDialog } from '~/store/manager/actions';
import Goals from '../Goals';
import Tasks from '../Tasks';
import CyclesAutomation from '../CyclesAutomation';

const Manager = ({
  classes,
  match,
  history,
  isTurnsGroupsDirty,
  isShiftScalesDirty,
  isEquipmentTurnGroupsDirty,
  isHandlingTypesDirty,
  isMaterialMovementsDirty,
  isMaterialElementsDirty,
  isWeighingsDirty,
  alertDialog,
  setAlertDialog: setDialog,
  isTeamGroupsDirty,
  isTeamsDirty,
  isTeamsListingDirty,
  isFireIdsDirty,
  isElementsDirty,
  isSubElementsDirty,
  isElementsTypesDirty,
  user,
}) => {
  const { t: translate } = useTranslation();

  const validateNavigation = useCallback(({ pathname = '' }) => {
    if ((isTurnsGroupsDirty
      || isShiftScalesDirty
      || isEquipmentTurnGroupsDirty) && !pathname.includes('turns')) {
      setDialog();
      return false;
    }

    if ((isHandlingTypesDirty
      || isMaterialMovementsDirty
      || isEquipmentTurnGroupsDirty) && !pathname.includes('materials')) {
      setDialog();
      return false;
    }

    if ((isMaterialElementsDirty
      || isElementsDirty
      || isSubElementsDirty
      || isElementsTypesDirty) && !pathname.includes('elements')) {
      setDialog();
      return false;
    }

    if (isWeighingsDirty && !pathname.includes('weighings')) {
      setDialog();
      return false;
    }

    if ((isTeamGroupsDirty
      || isTeamsDirty
      || isTeamsListingDirty) && !pathname.includes('teams')) {
      setDialog();
      return false;
    }

    if ((isFireIdsDirty) && !pathname.includes('drilling')) {
      setDialog();
      return false;
    }

    return true;
  }, [
    isTurnsGroupsDirty,
    isShiftScalesDirty,
    isEquipmentTurnGroupsDirty,
    isHandlingTypesDirty,
    isMaterialMovementsDirty,
    isMaterialElementsDirty,
    isWeighingsDirty,
    isTeamGroupsDirty,
    isTeamsDirty,
    isTeamsListingDirty,
    isFireIdsDirty,
    isElementsDirty,
    isSubElementsDirty,
    isElementsTypesDirty,
    setDialog]);

  const handleNavigation = (e, pathname = '') => {
    if (!validateNavigation(pathname)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (history.location && history.location.length > 0) {
      validateNavigation(history.location);
    }
  }, [history.location, validateNavigation]);

  useEffect(() => {
    const timer = setTimeout(() => {
      ZendeskAPI('webWidget', 'hide');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Main>
      <div className={classes.container}>
        <PF2MScrollbar style={{ width: 140, padding: 10, paddingBottom: 60 }}>
          <PF2MMenu
            handleNavigation={handleNavigation}
            userLevel={user.level}
            // eslint-disable-next-line no-underscore-dangle
            roles={user._roles}
            autoOperationTypeEnabled={
              user?.config
                ? user?.config.find(c => c.key === 'automation_operation_type')?.value === '1'
                : false
            }
          />
        </PF2MScrollbar>
        {/* </div> */}
        <div style={{ flex: 1 }}>
          <Switch>
            <Route exact path={`${match.path}/operators`} component={OperatorGroup} />
            <Route exact path={`${match.path}/turns`} component={TurnGroup} />
            <Route exact path={`${match.path}/equipments`} component={EquipmentGroup} />
            <Route exact path={`${match.path}/materials`} component={MaterialGroup} />
            <Route exact path={`${match.path}/elements`} component={Elements} />
            {user.level <= 2 && <Route exact path={`${match.path}/cycleautomation`} component={CyclesAutomation} />}
            <Route exact path={`${match.path}/codes`} component={Codes} />
            <Route exact path={`${match.path}/loads`} component={Loads} />
            <Route exact path={`${match.path}/tasks`} component={Tasks} />
            <Route exact path={`${match.path}/weighings`} component={Weighings} />
            <Route exact path={`${match.path}/checklist`} component={Checklist} />
            <Route exact path={`${match.path}/dmt`} component={DMT} />
            <Route exact path={`${match.path}/teams`} component={TeamGroup} />
            <Route exact path={`${match.path}/drilling`} component={Drilling} />
            <Route exact path={`${match.path}/operationtype`} component={OperationType} />
            <Route exact path={`${match.path}/tractor`} component={Tractor} />
            <Route exact path={`${match.path}/hourmetermanager`} component={HourmeterManager} />
            {user.level <= 3 && <Route exact path={`${match.path}/goals`} component={Goals} />}
            {user.level <= 2 && <Route exact path={`${match.path}/webusers`} component={WebUsers} />}
            {user.level <= 2 && <Route exact path={`${match.path}/closures`} component={Closures} />}
            <Redirect from={`${match.path}`} to={`${match.path}/operators`} />
          </Switch>
        </div>
      </div>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:YouHaveUnsavedData')}
        open={alertDialog}
        onConfirm={() => setDialog()}
      />
    </Main>
  );
};

Manager.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isTurnsGroupsDirty: PropTypes.bool,
  isShiftScalesDirty: PropTypes.bool,
  isEquipmentTurnGroupsDirty: PropTypes.bool,
  isHandlingTypesDirty: PropTypes.bool,
  isMaterialMovementsDirty: PropTypes.bool,
  isMaterialElementsDirty: PropTypes.bool,
  isWeighingsDirty: PropTypes.bool,
  alertDialog: PropTypes.bool,
  setAlertDialog: PropTypes.func,
  isTeamGroupsDirty: PropTypes.bool,
  isTeamsDirty: PropTypes.bool,
  isTeamsListingDirty: PropTypes.bool,
  isFireIdsDirty: PropTypes.bool,
  isElementsDirty: PropTypes.bool,
  isSubElementsDirty: PropTypes.bool,
  isElementsTypesDirty: PropTypes.bool,
  user: PropTypes.object,
};

Manager.defaultProps = {
  isTurnsGroupsDirty: false,
  isShiftScalesDirty: false,
  isEquipmentTurnGroupsDirty: false,
  isHandlingTypesDirty: false,
  isMaterialMovementsDirty: false,
  isMaterialElementsDirty: false,
  isWeighingsDirty: false,
  alertDialog: false,
  setAlertDialog: () => {},
  isTeamGroupsDirty: false,
  isTeamsDirty: false,
  isTeamsListingDirty: false,
  isFireIdsDirty: false,
  isElementsDirty: false,
  isSubElementsDirty: false,
  isElementsTypesDirty: false,
  user: {},
};

const mapStateToProps = state => ({
  isTurnsGroupsDirty: state.manager.isTurnsGroupsDirty,
  isShiftScalesDirty: state.manager.isShiftScalesDirty,
  isEquipmentTurnGroupsDirty: state.manager.isEquipmentTurnGroupsDirty,
  isHandlingTypesDirty: state.manager.isHandlingTypesDirty,
  isMaterialMovementsDirty: state.manager.isMaterialMovementsDirty,
  isMaterialElementsDirty: state.manager.isMaterialElementsDirty,
  isWeighingsDirty: state.manager.isWeighingsDirty,
  alertDialog: state.manager.alertDialog,
  isTeamGroupsDirty: state.manager.isTeamGroupsDirty,
  isTeamsDirty: state.manager.isTeamsDirty,
  isTeamsListingDirty: state.manager.isTeamsListingDirty,
  isFireIdsDirty: state.manager.isFireIdsDirty,
  isElementsDirty: state.manager.isElementsDirty,
  isSubElementsDirty: state.manager.isSubElementsDirty,
  isElementsTypesDirty: state.manager.isElementsTypesDirty,
  user: state.auth.user,
});

const mapDispatchToProps = {
  setAlertDialog,
};

export default connect(mapStateToProps,
  mapDispatchToProps)(withRouter(withStyles(styles)(Manager)));
