import {
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
  withStyles,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MButton from '~/components/PF2MButton';
import PF2MOutlinedInput from '~/components/PF2MOutlinedInput';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import { usePlatformConfigurationAuth } from '~/services/platformConfiguration';
import {
  setCurrentEquipmentStatus,
  setOriginalCurrentEquipmentStatus,
  updateEquipmentStatus,
} from '~/store/dispatch/actions';
import {
  getCodeGroups as getMaintenanceCodeGroups,
  getCodes as getMaintenanceCodes,
  getCodeTypes as getMaintenanceCodeTypes,
} from '~/store/maintenance/actions';
import { getOperators, getOperatorsGroups } from '~/store/manager/actions';
import { parseDateTimeToTimestamp } from '~/utils/moment';
import styles from '../styles';


const EquipmentStatusEditModal = ({ classes, handleClose }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const codes = useSelector(state => state.nref.codes);
  const codeTypes = useSelector(state => state.nref.codeTypes.map(e => ({ ...e, name: translate(`common:${e.name}`) })));
  const codeGroups = useSelector(state => state.nref.codeGroups);

  const elements = useSelector(state => state.nref.elements);
  const subElements = useSelector(state => state.nref.subElements);

  const operators = useSelector(state => state.manager.operators);
  const operatorGroups = useSelector(state => state.manager.operatorsGroups);

  const maintenanceCodes = useSelector(state => state.maintenance.codes);
  const maintenanceCodeTypes = useSelector(state => state.maintenance.codeTypes.map(e => ({ ...e, name: translate(`common:${e.name}`) })));
  const maintenanceCodeGroups = useSelector(state => state.maintenance.codeGroups);

  const config = useSelector(state => state.auth.user.config);

  const currentEquipmentStatus = useSelector(
    state => state.dispatch.currentEquipmentStatus,
  );
  const originalEquipmentStatus = useSelector(
    state => state.dispatch.originalCurrentEquipmentStatus,
  );
  const checkboxState = usePlatformConfigurationAuth('dispatch_equipmentstatus_checkbox') || '0';

  useEffect(() => {
    if (!dataLoaded) {
      dispatch(getOperators());
      dispatch(getOperatorsGroups());

      dispatch(getMaintenanceCodes());
      dispatch(getMaintenanceCodeTypes());
      dispatch(getMaintenanceCodeGroups());

      setDataLoaded(true);
    }
  }, [dispatch, dataLoaded]);

  // eslint-disable-next-line max-len
  const equipStatusHasChanged = () => currentEquipmentStatus.code_type !== originalEquipmentStatus.code_type
    || currentEquipmentStatus.id_code_group !== originalEquipmentStatus.id_code_group
    || currentEquipmentStatus.id_code !== originalEquipmentStatus.id_code
    || currentEquipmentStatus.element_id !== originalEquipmentStatus.element_id
    || currentEquipmentStatus.point_id !== originalEquipmentStatus.point_id
    || currentEquipmentStatus.comment !== originalEquipmentStatus.comment
    || currentEquipmentStatus.code_maintenance_id !== originalEquipmentStatus.code_maintenance_id
    || currentEquipmentStatus.id_code_type_maintenance
    !== originalEquipmentStatus.id_code_type_maintenance
    || currentEquipmentStatus.id_code_group_maintenance
    !== originalEquipmentStatus.id_code_group_maintenance
    || parseInt(currentEquipmentStatus.id_operator, 10)
    !== parseInt(originalEquipmentStatus.id_operator, 10)
    || parseInt(currentEquipmentStatus.id_operator_group, 10)
    !== parseInt(originalEquipmentStatus.id_operator_group, 10);

  const filterCodeGroups = (u) => {
    const {
      code_type: codeType,
      equip_type: equipType,
    } = currentEquipmentStatus;

    return equipType === u.id_equip
      && (codeType === -1 || u.code_type === codeType);
  };

  const filterCodes = (c) => {
    const {
      id_code_group: codeGroup,
      equip_type: equipType,
      code_type: codeType,
    } = currentEquipmentStatus;

    return codeType === c.code_type
      && equipType === c.id_equip
      && (codeGroup === 0 || c.id_group === codeGroup);
  };

  const filterSubElements = (c) => {
    const { element_id: element } = currentEquipmentStatus;
    return element === 0 || c.id_element === element;
  };

  const filterOperators = (c) => {
    const { id_operator_group: group } = currentEquipmentStatus;
    return group === 0 || c.id_group === group;
  };

  const modalHandleChange = (field, value) => {
    dispatch(setCurrentEquipmentStatus(currentEquipmentStatus, field, value));
  };

  const fieldsAreValid = (equipStatus) => {
    const hasCodeType = equipStatus.code_type !== -1;
    const hasCodeGroup = equipStatus.id_code_group !== 0;
    const hasCode = equipStatus.id_code !== 0;
    const hasOperatorGroup = equipStatus.id_operator_group !== 0;
    const hasOperator = equipStatus.id_operator !== 0;

    const hasMaintenance = config.find(
      c => c.key === 'dispatch_equipment_status_maintenance' && c.value === '1',
    ) ? (
        equipStatus.code_maintenance_id !== 0
        && equipStatus.id_code_type_maintenance !== 0
        && equipStatus.id_code_group_maintenance !== 0
      ) || equipStatus.code_type !== 0
      : true;

    return (
      hasCodeType
      && hasCodeGroup
      && hasCode
      && hasOperatorGroup
      && hasOperator
      && hasMaintenance
    );
  };

  const handleUpdateItem = (equipmentStatus) => {
    const newEquipmentStatus = equipmentStatus;

    const now = moment().add(1, 'second');
    const currentDateNow = now.format('DD/MM/YYYY');
    const currentTimeNow = now.format('HH:mm:ss');
    const startTimestamp = parseDateTimeToTimestamp(currentDateNow, currentTimeNow) / 1000;

    newEquipmentStatus.start_timestamp = startTimestamp;
    newEquipmentStatus.start_date = currentDateNow;
    newEquipmentStatus.start_time = currentTimeNow;

    dispatch(updateEquipmentStatus(newEquipmentStatus));
  };

  const renderHeader = () => (
    <div>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
      >
        {`${translate('common:EquipmentStatus')}: ${currentEquipmentStatus.equip_name}`}
      </Typography>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: 10,
        gridRowGap: 10,
      }}
      >
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:EquipmentType')}:`}
          </span>
          <span style={{ color: '#647886' }}>
            {currentEquipmentStatus.equip_type_name}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:EquipmentGroup')}:`}
          </span>
          <span style={{ color: '#647886' }}>
            {currentEquipmentStatus.equip_group_name}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:Equipment')}:`}
          </span>
          <span style={{ color: '#647886' }}>
            {currentEquipmentStatus.equip_name}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:Duration')}:`}
          </span>
          <span style={{ color: '#647886' }}>
            {currentEquipmentStatus.duration}
          </span>
        </div>
      </div>
    </div>
  );

  const renderFormControl = (
    item,
    key,
    title,
    collection,
    initialValue = 0,
    collectionId = 'id',
    disabled = false,
  ) => (
    <FormControl>
      <FormLabel className={classes.formLabel}>
        {title}
      </FormLabel>
      <FormGroup>
        <PF2MSearchSelectOutlined
          placeholder={null}
          className={classnames(classes.field)}
          value={item[key] || 0}
          onChange={e => modalHandleChange(key, e.target.value)}
          disabled={disabled}
        >
          {[{ value: initialValue, label: translate('common:Select') }]
            .concat(collection
              .map(e => ({ value: e[collectionId], label: e.name || '0' })))}
        </PF2MSearchSelectOutlined>
      </FormGroup>
    </FormControl>
  );

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="warning">
            <CreateIcon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {renderHeader()}
          <hr />
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridColumnGap: 10,
            gridRowGap: 10,
          }}
          >
            {renderFormControl(currentEquipmentStatus, 'code_type', translate('common:CodeType'), codeTypes, -1)}
            {renderFormControl(currentEquipmentStatus, 'id_code_group', translate('common:CodeGroup'), codeGroups.filter(filterCodeGroups))}
            {renderFormControl(currentEquipmentStatus, 'id_code', translate('common:Code'), codes.filter(filterCodes))}

            {config.find(c => c.key === 'dispatch_equipment_status_maintenance' && c.value === '1') && (
              <>
                {renderFormControl(currentEquipmentStatus, 'id_code_type_maintenance', translate('common:TaskCodeType'),
                  maintenanceCodeTypes, 0, 'id',
                  currentEquipmentStatus.code_type !== 0)}
                {renderFormControl(currentEquipmentStatus, 'id_code_group_maintenance', translate('common:TaskCodeGroup'),
                  maintenanceCodeGroups.filter(m => m.id_code_type_maintenance
                    === currentEquipmentStatus.id_code_type_maintenance),
                  0, 'id',
                  currentEquipmentStatus.code_type !== 0)}
                {renderFormControl(currentEquipmentStatus, 'code_maintenance_id', translate('common:Task'),
                  maintenanceCodes.filter(m => m.id_code_group_maintenance
                    === currentEquipmentStatus.id_code_group_maintenance),
                  0, 'id',
                  currentEquipmentStatus.code_type !== 0)}
              </>
            )
            }


            {renderFormControl(currentEquipmentStatus, 'element_id', translate('common:Element'), elements)}
            {renderFormControl(currentEquipmentStatus, 'point_id', translate('common:SubElement'), subElements.filter(filterSubElements))}
          </div>
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridColumnGap: 10,
            gridRowGap: 10,
            marginTop: 10,
          }}
          >
            {renderFormControl(currentEquipmentStatus, 'id_operator_group', translate('common:OperatorGroup'), operatorGroups)}
            {renderFormControl(currentEquipmentStatus, 'id_operator', translate('common:Operator'), operators.filter(filterOperators), 0, 'id_operator')}
          </div>
          <div style={{ marginTop: 10 }}>
            <FormControl style={{
              display: 'block',
              marginTop: 10,
            }}
            >
              <FormGroup>
                <FormLabel className={classes.formLabel}>
                  {`${translate('common:Comment')}:`}
                </FormLabel>
                <PF2MOutlinedInput
                  className={classes.textArea}
                  value={currentEquipmentStatus.comment || ''}
                  onBlur={e => modalHandleChange('comment', e.target.value)}
                  multiline
                  rows={4}
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  style={{ marginLeft: 0 }}
                  control={(
                    <Checkbox
                      defaultChecked={checkboxState.value === '1'}
                      onChange={
                        e => modalHandleChange('sent_from_tablet', e.target.checked)
                      }
                    />
                  )}
                  label={translate('common:DefinitiveNote')}
                />
              </FormGroup>
            </FormControl>
          </div>

          <div style={{
            display: 'flex',
            marginTop: 10,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            <PF2MButton
              color="primary"
              onClick={() => {
                dispatch(setCurrentEquipmentStatus(null));
                dispatch(setOriginalCurrentEquipmentStatus(null));
                handleClose(false);
              }}
            >
              <DeleteOutline className={classes.icon} />
              {translate('common:DiscardChanges')}
            </PF2MButton>
            <Fab
              variant="extended"
              size="medium"
              color="secondary"
              onClick={() => {
                if (!fieldsAreValid(currentEquipmentStatus)) {
                  setModalOpen(true);
                  return;
                }
                if (!equipStatusHasChanged()) {
                  dispatch(setCurrentEquipmentStatus(null));
                  dispatch(setOriginalCurrentEquipmentStatus(null));
                  handleClose(false);
                  return;
                }
                handleUpdateItem(currentEquipmentStatus);
              }}
              className={classes.saveButton}
            >
              {translate('common:SaveData')}
            </Fab>
          </div>
        </CardBody>
      </Card>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:ThereAreBlankFields')}
        open={isModalOpen}
        onConfirm={() => setModalOpen(false)}
      />
    </div>
  );
};

EquipmentStatusEditModal.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
};

EquipmentStatusEditModal.defaultProps = {
  handleClose: null,
};

export default withStyles(styles)(EquipmentStatusEditModal);
