import { withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PF2MCustomDragLayer from '~/components/PF2MCustomDragLayer';
import PF2MTabs from '~/components/PF2MTabs';
import usePlatformConfiguration from '~/services/platformConfiguration';
import useTabletConfiguration from '~/services/tabletConfiguration';
import {
  getEquipmentScheduleStatuses,
  getEquipmentsSchedules,
} from '~/store/dispatch/actions';
import AnomalyLogsTab from './AnomalyLogsTab';
import ChatTab from './ChatTab';
import DestinationsTab from './DestinationsTab';
import OriginsTab from './OriginsTab';
import styles from './styles';

const DispatchControl = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const chatList = useSelector(state => (state.chat?.chatList));
  const { value: chatStatus } = usePlatformConfiguration('enable_chat');
  const { value: dispatchUpdateTime } = useTabletConfiguration('dispatch_update_time');


  const tabs = [
    { key: 0, label: translate('common:Origins') },
    { key: 1, label: translate('common:Destinations') },
    { key: 2, label: translate('common:AnomalyLogs') },
  ];
  if (chatStatus === '1') {
    tabs.push({ key: 3, label: translate('common:ChatBeta') });
  }
  const handleChange = (event, value) => setSelectedTab(value);

  useEffect(() => {
    async function fetchData() {
      dispatch({ type: 'SKIP_LOADING', payload: true });
      dispatch(getEquipmentsSchedules());
      dispatch(getEquipmentScheduleStatuses());
      dispatch({ type: 'SKIP_LOADING', payload: false });
    }

    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    if (dispatchUpdateTime !== undefined) {
      const time = dispatchUpdateTime * 1000;
      const interval = setInterval(() => setDataLoaded(false), time);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [dispatchUpdateTime]);

  return (
    <>
      <PF2MTabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        chatIndex={3}
        readStatus={
          chatList ? Object
            .values(chatList)
            .reduce((acc, e) => (e.isReaded ? acc : false), true) : true
        }
        hideSyncButton
      >
        {tabs}
      </PF2MTabs>

      {selectedTab === 0 ? (
        <OriginsTab />
      ) : null}
      {selectedTab === 1 ? (
        <DestinationsTab />
      ) : null}

      <PF2MCustomDragLayer />
      {selectedTab === 2 ? (
        <AnomalyLogsTab />
      ) : null}
      {selectedTab === 3 ? (
        <ChatTab />
      ) : null}
    </>
  );
};

export default withStyles(styles)(DispatchControl);
