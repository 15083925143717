/* eslint-disable prefer-destructuring */
import React from 'react';
import {
  TableCell,
  TableRow,
  Fab,
  withStyles,
  TextField,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import PF2MSearchSelect from '~/components/PF2MSearchSelect';
import PF2MMaskTextField from '~/components/PF2MMaskTextField';
import styles from '../styles';
import {
  formatDateToServerFormat,
  parseDateToServerFormat,
  subtractDaysFromNow,
  newMomentDate,
  parseDate,
} from '~/utils/moment';
import usePlatformConfiguration, {
  usePlatformConfigurationUserAccessLevel,
} from '~/services/platformConfiguration';


const ListRow = ({
  data,
  classes,
  onEdit,
  onDelete,
  onRestore,
  equipmentTypes,
  equipmentsGroups,
  equipments,
  codes,
  codeTypes,
  codeGroups,
  operatorsGroups,
  operators,
}) => {
  const { t: translate } = useTranslation();
  const renderNumberCell = (name, value = 0, item, editable = true) => {
    if (item.id < 1 || editable) {
      return (
        <NumberFormat
          customInput={TextField}
          value={value}
          InputProps={{ classes: { input: classes.input } }}
          onChange={e => onEdit(item, name, e.target.value)}
          disabled={item.update_status === 'D' || !editable}
        />
      );
    }
    return value;
  };

  // handle clearing outside if value can be changed outside of the component
  const handleMask = value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []);
  const userAccessLevel = useSelector(state => state.auth.user.level);
  const { value: userLimitEditConfig } = usePlatformConfigurationUserAccessLevel(userAccessLevel);
  const closures = useSelector(state => state.manager.closures);
  const { value: enableClosure } = usePlatformConfiguration('enable_closure');

  const renderDatePicker = (d, key) => {
    let userLimitEdit = subtractDaysFromNow(userLimitEditConfig);
    const isEnableClosureOn = enableClosure && closures?.length > 0;

    if (isEnableClosureOn) {
      const dateNow = newMomentDate();
      const lastClosureDate = parseDate(closures[0].timestampClose, true);
      const currentDaysFromLastClosure = dateNow.diff(lastClosureDate, 'days');

      const lockEditConfig = subtractDaysFromNow(userLimitEditConfig);
      const lockEditLastClosure = subtractDaysFromNow(currentDaysFromLastClosure);

      userLimitEdit = currentDaysFromLastClosure > userLimitEditConfig
        ? lockEditConfig : lockEditLastClosure.add(1, 'day');
    }

    return (
      <KeyboardDatePicker
        clearable
        mask={handleMask}
        format={translate('date:DateFormat')}
        disableFuture
        autoOk
        minDate={userLimitEdit}
        onChange={e => onEdit(d, key, formatDateToServerFormat(e))}
        value={d[key] ? parseDateToServerFormat(d[key]) : ''}
        invalidDateMessage={translate('validation:InvalidDate')}
        disabled={data.update_status === 'D'}
      />
    );
  };

  const renderTimePicker = (d, key) => (
    <PF2MMaskTextField
      mask="99:99:99"
      value={d[key]}
      onChange={e => onEdit(d, key, e.target.value)}
      disabled={data.update_status === 'D'}
    />
  );

  const renderHourmeter = (d, num) => (
    <TableCell className={classes.tableCell}>
      {renderNumberCell(`hourmeter_${num}`, data[`hourmeter_${num}`], data, true)}
    </TableCell>
  );

  return (
    <TableRow>
      <TableCell className={classes.tableCell}>
        {renderDatePicker(data, 'hour_machine_date')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderTimePicker(data, 'hour_machine_time')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.code_type}
          onChange={e => onEdit(data, 'code_type', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {codeTypes
            .map(e => ({ value: e.id, label: e.name, tooltip: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.code_group}
          onChange={e => onEdit(data, 'code_group', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {codeGroups
            .filter(c => c.name && (Number(c.code_type) === Number(data.code_type)
              && Number(c.id_equip) === Number(data.equip_type)))
            .map(e => ({ value: e.id, label: e.name, tooltip: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.codes_id}
          onChange={e => onEdit(data, 'codes_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {codes
            .filter(c => c.name && (c.code_type === Number(data.code_type)
              && c.id_equip === data.equip_type
              && c.id_group === data.code_group))
            .map(e => ({ value: e.id, label: e.name, tooltip: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.operator_group}
          onChange={e => onEdit(data, 'operator_group', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {operatorsGroups
            .map(e => ({ value: e.id, label: e.name, tooltip: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.operator_id}
          onChange={e => onEdit(data, 'operator_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {operators
            .filter(o => data.operator_group === 0
              || o.id_group === data.operator_group)
            .map(e => ({ value: e.id_operator, label: e.name, tooltip: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.equip_type}
          onChange={e => onEdit(data, 'equip_type', e.target.value)}
          disabled={false}
        >
          {equipmentTypes
            .map(e => ({ value: e.id, label: e.name, tooltip: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.equip_group}
          onChange={e => onEdit(data, 'equip_group', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {equipmentsGroups
            .filter(d => data.equip_type === 0 || d.id_equipament === data.equip_type)
            .map(e => ({ value: e.id, label: e.name, tooltip: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.equip_id}
          onChange={e => onEdit(data, 'equip_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {equipments
            .filter(d => data.equip_group === 0 || d.id_group === data.equip_group)
            .map(e => ({ value: e.id, label: e.name, tooltip: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.condition}
          onChange={e => onEdit(data, 'condition', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {[
            { value: 0, label: translate('common:Exit') },
            { value: 1, label: translate('common:Entry') },
            { value: 2, label: translate('common:LoginLogout') },
          ]}
        </PF2MSearchSelect>
      </TableCell>
      {renderHourmeter(data, 1)}
      {renderHourmeter(data, 2)}
      {renderHourmeter(data, 3)}
      {renderHourmeter(data, 4)}
      {renderHourmeter(data, 5)}
      {renderHourmeter(data, 6)}
      {renderHourmeter(data, 7)}
      {renderHourmeter(data, 8)}
      {renderHourmeter(data, 9)}
      {renderHourmeter(data, 10)}
      <TableCell style={{
        position: 'sticky',
        right: 0,
        height: 48,
      }}
      >
        {data.isDirty ? (
          <Fab
            size="small"
            color="primary"
            aria-label="Restore"
            onClick={() => onRestore(data)}
            disabled={!onRestore}
          >
            <RestoreIcon />
          </Fab>
        ) : (
          <Fab
            size="small"
            color="primary"
            aria-label="Delete"
            onClick={() => onDelete(data)}
            disabled={!onDelete}
          >
            <DeleteIcon />
          </Fab>
        )}
      </TableCell>
    </TableRow>
  );
};

ListRow.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func,
  codeTypes: PropTypes.array,
  equipmentTypes: PropTypes.array,
  equipmentsGroups: PropTypes.array,
  equipments: PropTypes.array,
  operatorsGroups: PropTypes.array,
  operators: PropTypes.array,
  codeGroups: PropTypes.array,
  codes: PropTypes.array,
};

ListRow.defaultProps = {
  data: {},
  classes: {},
  onEdit: null,
  onDelete: null,
  onRestore: null,
  codeTypes: [],
  equipmentTypes: [],
  equipmentsGroups: [],
  equipments: [],
  operatorsGroups: [],
  operators: [],
  codeGroups: [],
  codes: [],
};

export default withStyles(styles)(ListRow);
