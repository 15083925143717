import * as types from './types';
import {
  newMomentDate, formatTime, formatDate, diffTwoDateTimeStr,
} from '~/utils/moment';

const INITIAL_STATE = {
  originalTransportList: [],
  originalHourmeterList: [],
  originalCodeList: [],
  originalCodesRawList: [],
  originalHourMachineList: [],
  originalLoadList: [],
  originalRailroadList: [],
  originalPerforatorList: [],
  originalRefuelerList: [],
  originalTractorList: [],
  originalInfrastructureList: [],
  originalShaftList: [],
  originalFillinList: [],
  originalUndergroundList: [],
  originalMaintenanceList: [],
  originalScissorLiftList: [],
  transportList: [],
  hourmeterList: [],
  codeList: [],
  codesRawList: [],
  codesRawListPagination: { page: 0, totalRecords: 0, loadedPages: [] },
  hourMachineList: [],
  loadList: [],
  railroadList: [],
  perforatorList: [],
  refuelerList: [],
  tractorList: [],
  infrastructureList: [],
  shaftList: [],
  fillinList: [],
  undergroundList: [],
  maintenanceList: [],
  scissorLiftList: [],
  transportSortBy: { field: 'start_timestamp', order: 'asc' },
  hourmeterSortBy: { field: 'timestamp_op', order: 'asc' },
  codeSortBy: { field: 'start_timestamp', order: 'asc' },
  loadSortBy: { field: 'start_timestamp', order: 'asc' },
  perforatorSortBy: { field: 'start_time', order: 'asc' },
  refuelerSortBy: { field: 'start_time', order: 'asc' },
  tractorSortBy: { field: 'id_project_group', order: 'asc' },
  infrastructureSortBy: { field: 'start_timestamp', order: 'asc' },
  shaftSortBy: { field: 'start_timestamp', order: 'asc' },
  fillinSortBy: { field: 'start_timestamp', order: 'asc' },
  undergroundSortBy: { field: 'id_project_group', order: 'asc' },
  maintenanceSortBy: { field: 'start_timestamp', order: 'asc' },
  scissorLiftSortBy: { field: 'start_timestamp', order: 'asc' },
  isTransportListDirty: false,
  isHourmeterListDirty: false,
  isCodeListDirty: false,
  isCodesRawListDirty: false,
  isHourMachineListDirty: false,
  isLoadListDirty: false,
  isRailroadListDirty: false,
  isPerforatorListDirty: false,
  isRefuelerListDirty: false,
  isUndergroundListDirty: false,
  isMaintenanceListDirty: false,
  isTractorListDirty: false,
  isInfrastructureListDirty: false,
  isShaftListDirty: false,
  isFillinListDirty: false,
  isScissorLiftListDirty: false,
  equipmentTabs: [],
  page: 1,
  limit: 30,
  totalRecords: 0,
  filters: {},
  isSubmittingData: false,
  isLoadingList: false,
  activityList: [],
  originalActivityList: [],
  isActivityListDirty: false,
  activity_upload_loading: false,
  crusherList: [],
  originalCrusherList: [],
  isCrusherListDirty: false,
  selectedCodeForMigrations: {},
};

const remapData = (items, newItem, key) => items.map((item) => {
  if (item[key] === newItem[key]) {
    return {
      ...newItem,
    };
  }
  return item;
});

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_SELECTED_CODE_FOR_MIGRATIONS: {
      return {
        ...state,
        selectedCodeForMigrations: action.payload,
      };
    }
    case types.SET_IS_SUBMITTING_DATA: {
      return {
        ...state,
        isSubmittingData: action.payload,
      };
    }
    case types.SET_TRANSPORT_SORT_BY: {
      return {
        ...state,
        transportSortBy: action.payload,
      };
    }
    case types.SET_HOURMETER_SORT_BY: {
      return {
        ...state,
        hourmeterSortBy: action.payload,
      };
    }
    case types.SET_CODE_SORT_BY: {
      return {
        ...state,
        codeSortBy: action.payload,
      };
    }
    case types.SET_LOAD_SORT_BY: {
      return {
        ...state,
        loadSortBy: action.payload,
      };
    }
    case types.SET_PERFORATOR_SORT_BY: {
      return {
        ...state,
        perforatorSortBy: action.payload,
      };
    }
    case types.SET_REFUELER_SORT_BY: {
      return {
        ...state,
        refuelerSortBy: action.payload,
      };
    }
    case types.SET_INFRASTRUCTURE_SORT_BY: {
      return {
        ...state,
        infrastructureSortBy: action.payload,
      };
    }
    case types.SET_SHAFT_SORT_BY: {
      return {
        ...state,
        shaftSortBy: action.payload,
      };
    }
    case types.SET_FILLIN_SORT_BY: {
      return {
        ...state,
        fillinSortBy: action.payload,
      };
    }
    case types.SET_UNDERGROUND_SORT_BY: {
      return {
        ...state,
        undergroundSortBy: action.payload,
      };
    }
    case types.SET_MAINTENANCE_SORT_BY: {
      return {
        ...state,
        maintenanceSortBy: action.payload,
      };
    }
    case types.SET_TRACTOR_SORT_BY: {
      return {
        ...state,
        tractorSortBy: action.payload,
      };
    }
    case types.SET_SCISSOR_LIFT_SORT_BY: {
      return {
        ...state,
        scissorLiftSortBy: action.payload,
      };
    }
    case types.SET_TRANSPORT_LIST: {
      const {
        data, page, reorder,
      } = action.payload;

      let transportList = [];
      let originalTransportList = [];
      if (reorder) {
        transportList = [...data];
        originalTransportList = [...data];
      } else {
        transportList = [...state.transportList, ...data];
        originalTransportList = [...state.originalTransportList, ...data];
      }

      return {
        ...state,
        transportList,
        originalTransportList,
        page,
        isTransportListDirty: false,
      };
    }
    case types.SET_HOURMETER_LIST: {
      const {
        data, page, reorder,
      } = action.payload;
      let hourmeterList = [];
      let originalHourmeterList = [];

      if (reorder) {
        hourmeterList = [...data];
        originalHourmeterList = [...data];
      } else {
        hourmeterList = [...state.hourmeterList, ...data];
        originalHourmeterList = [...state.originalHourmeterList, ...data];
      }

      return {
        ...state,
        hourmeterList,
        originalHourmeterList,
        page,
        isHourmeterListDirty: false,
      };
    }
    case types.SET_CODE_LIST: {
      const {
        data, page, reorder,
      } = action.payload;
      let codeList = [];
      let originalCodeList = [];

      if (reorder) {
        codeList = [...data];
        originalCodeList = [...data];
      } else {
        codeList = [...state.codeList, ...data];
        originalCodeList = [...state.originalCodeList, ...data];
      }

      return {
        ...state,
        codeList,
        originalCodeList,
        page,
        isCodeListDirty: false,
      };
    }
    case types.SET_ACTIVITY_LIST: {
      const { data, page, reorder } = action.payload;
      let activityList = [];
      let originalActivityList = [];

      if (reorder) {
        activityList = [...data];
        originalActivityList = [...data];
      } else {
        activityList = [...state.activityList, ...data];
        originalActivityList = [...state.originalActivityList, ...data];
      }

      return {
        ...state,
        activityList,
        originalActivityList,
        page,
        isActivityListDirty: false,
      };
    }

    case types.SET_CODES_RAW_LIST: {
      const {
        data, page, totalRecords,
      } = action.payload;
      let initialList = state.codesRawList.length > 0 ? state.codesRawList : [];

      let codesRawList = [...initialList];
      let originalCodesRawList = [];
      let { codesRawListPagination } = state;

      if (page === 1) {
        initialList = [];
        codesRawListPagination = { page, totalRecords, loadedPages: [] };
      }

      codesRawList = initialList.filter(e => e.id_code > 0);
      codesRawList = [...codesRawList, ...data];

      const { loadedPages } = codesRawListPagination;
      const emptyRowsLen = totalRecords - codesRawList.length;
      const emptyRows = Array.from({ length: emptyRowsLen }, (_, i) => ({
        id: -i,
      }));

      if (!loadedPages.includes(page)) {
        codesRawList = [...codesRawList, ...emptyRows];
        originalCodesRawList = codesRawList;
        codesRawListPagination = { page, totalRecords, loadedPages: [...loadedPages, page] };
      }

      return {
        ...state,
        codesRawList,
        originalCodesRawList,
        codesRawListPagination,
        page,
        isCodeRawListDirty: false,
      };
    }
    case types.SET_HOUR_MACHINE_LIST: {
      const {
        data, page, reorder,
      } = action.payload;
      let hourMachineList = [];
      let originalHourMachineList = [];

      if (reorder) {
        hourMachineList = [...data];
        originalHourMachineList = [...data];
      } else {
        hourMachineList = [...state.hourMachineList, ...data];
        originalHourMachineList = [...state.originalHourMachineList, ...data];
      }

      return {
        ...state,
        hourMachineList,
        originalHourMachineList,
        page,
        isHourMachineListDirty: false,
      };
    }
    case types.SET_LOAD_LIST: {
      const {
        data, page, reorder,
      } = action.payload;
      let loadList = [];
      let originalLoadList = [];

      if (reorder) {
        loadList = [...data];
        originalLoadList = [...data];
      } else {
        loadList = [...state.loadList, ...data];
        originalLoadList = [...state.originalLoadList, ...data];
      }

      return {
        ...state,
        loadList,
        originalLoadList,
        page,
        isLoadListDirty: false,
      };
    }
    case types.SET_RAILROAD_LIST: {
      const {
        data, page, reorder, totalRecords,
      } = action.payload;
      let railroadList = [];
      let originalRailroadList = [];

      if (reorder) {
        railroadList = [...data];
        originalRailroadList = [...data];
      } else {
        railroadList = [...state.railroadList, ...data];
        originalRailroadList = [...state.originalRailroadList, ...data];
      }

      return {
        ...state,
        railroadList,
        originalRailroadList,
        page,
        totalRecords,
        isRailroadListDirty: false,
      };
    }
    case types.SET_PERFORATOR_LIST: {
      const {
        data, page, reorder,
      } = action.payload;
      let perforatorList = [];
      let originalPerforatorList = [];

      if (reorder) {
        perforatorList = [...data];
        originalPerforatorList = [...data];
      } else {
        perforatorList = [...state.perforatorList, ...data];
        originalPerforatorList = [...state.originalPerforatorList, ...data];
      }

      return {
        ...state,
        perforatorList,
        originalPerforatorList,
        page,
        isPerforatorListDirty: false,
      };
    }
    case types.SET_CRUSHER_LIST: {
      const {
        data, page, reorder,
      } = action.payload;
      let crusherList = [];
      let originalCrusherList = [];

      if (reorder) {
        crusherList = [...data];
        originalCrusherList = [...data];
      } else {
        crusherList = [...state.crusherList, ...data];
        originalCrusherList = [...state.originalCrusherList, ...data];
      }

      return {
        ...state,
        crusherList,
        originalCrusherList,
        page,
        isCrusherListDirty: false,
      };
    }
    case types.SET_REFUELER_LIST: {
      const {
        data, page, reorder,
      } = action.payload;
      let refuelerList = [];
      let originalRefuelerList = [];

      if (reorder) {
        refuelerList = [...data];
        originalRefuelerList = [...data];
      } else {
        refuelerList = [...state.refuelerList, ...data];
        originalRefuelerList = [...state.originalRefuelerList, ...data];
      }

      return {
        ...state,
        refuelerList,
        originalRefuelerList,
        page,
        isRefuelerListDirty: false,
      };
    }
    case types.SET_INFRASTRUCTURE_LIST: {
      const {
        data, page, reorder,
      } = action.payload;
      let infrastructureList = [];
      let originalInfrastructureList = [];

      if (reorder) {
        infrastructureList = [...data];
        originalInfrastructureList = [...data];
      } else {
        infrastructureList = [...state.infrastructureList, ...data];
        originalInfrastructureList = [...state.originalInfrastructureList, ...data];
      }

      return {
        ...state,
        infrastructureList,
        originalInfrastructureList,
        page,
        isInfrastructureListDirty: false,
      };
    }
    case types.SET_SHAFT_LIST: {
      const {
        data, page, reorder,
      } = action.payload;
      let shaftList = [];
      let originalShaftList = [];

      if (reorder) {
        shaftList = [...data];
        originalShaftList = [...data];
      } else {
        shaftList = [...state.shaftList, ...data];
        originalShaftList = [...state.originalShaftList, ...data];
      }

      return {
        ...state,
        shaftList,
        originalShaftList,
        page,
        isShaftListDirty: false,
      };
    }
    case types.SET_FILLIN_LIST: {
      const {
        data, page, reorder,
      } = action.payload;
      let fillinList = [];
      let originalFillinList = [];

      if (reorder) {
        fillinList = [...data];
        originalFillinList = [...data];
      } else {
        fillinList = [...state.fillinList, ...data];
        originalFillinList = [...state.originalFillinList, ...data];
      }

      return {
        ...state,
        fillinList,
        originalFillinList,
        page,
        isFillinListDirty: false,
      };
    }
    case types.SET_UNDERGROUND_LIST: {
      const {
        data, page, reorder,
      } = action.payload;
      let undergroundList = [];
      let originalUndergroundList = [];

      if (reorder) {
        undergroundList = [...data];
        originalUndergroundList = [...data];
      } else {
        undergroundList = [...state.undergroundList, ...data];
        originalUndergroundList = [...state.originalUndergroundList, ...data];
      }

      return {
        ...state,
        undergroundList,
        originalUndergroundList,
        page,
        isUndergroundListDirty: false,
      };
    }
    case types.SET_MAINTENANCE_LIST: {
      const {
        data, page, reorder,
      } = action.payload;
      let maintenanceList = [];
      let originalMaintenanceList = [];

      if (reorder) {
        maintenanceList = [...data];
        originalMaintenanceList = [...data];
      } else {
        maintenanceList = [...state.maintenanceList, ...data];
        originalMaintenanceList = [...state.originalMaintenanceList, ...data];
      }

      return {
        ...state,
        maintenanceList,
        originalMaintenanceList,
        page,
        isMaintenanceListDirty: false,
        isUndergroundListDirty: false,
      };
    }

    case types.SET_TRACTOR_LIST: {
      const {
        data, page, reorder,
      } = action.payload;

      let tractorList = [];
      let originalTractorList = [];
      if (reorder) {
        tractorList = [...data];
        originalTractorList = [...data];
      } else {
        tractorList = [...state.tractorList, ...data];
        originalTractorList = [...state.originalTractorList, ...data];
      }

      return {
        ...state,
        tractorList,
        originalTractorList,
        page,
        isTractorListDirty: false,
      };
    }
    case types.SET_SCISSOR_LIFT_LIST: {
      const {
        data, page, reorder,
      } = action.payload;
      let scissorLiftList = [];
      let originalScissorLiftList = [];

      if (reorder) {
        scissorLiftList = [...data];
        originalScissorLiftList = [...data];
      } else {
        scissorLiftList = [...state.scissorLiftList, ...data];
        originalScissorLiftList = [...state.originalScissorLiftList, ...data];
      }

      return {
        ...state,
        scissorLiftList,
        originalScissorLiftList,
        page,
        isScissorLiftListDirty: false,
      };
    }
    case types.SET_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case types.SET_IS_LOADING_LIST: {
      return {
        ...state,
        isLoadingList: action.payload,
      };
    }
    case types.SET_TRANSPORT_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.transportList, newItem, 'id');
      return {
        ...state,
        transportList: newData,
        isTransportListDirty: true,
      };
    }
    case types.SET_HOURMETER_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id_line < 1),
      };
      const newData = remapData(state.hourmeterList, newItem, 'id_line');
      return {
        ...state,
        hourmeterList: newData,
        isHourmeterListDirty: true,
      };
    }
    case types.SET_ACTIVITY_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id_line < 1),
      };
      const newData = remapData(state.activityList, newItem, 'id');
      return {
        ...state,
        activityList: newData,
        isActivityListDirty: true,
      };
    }
    case types.SET_CODE_VALUES: {
      const {
        start_date: sd, end_date: ed, start_time: st, end_time: et,
      } = action.payload;
      const MS_IN_MINUTES = 1000 * 60;
      const duration = (
        Math.max(0, diffTwoDateTimeStr(formatDate(sd), st, formatDate(ed), et)) / MS_IN_MINUTES
      ).toFixed(2);
      const newItem = {
        ...action.payload,
        duration,
        isDirty: !(action.payload.id < 1),
      };

      const newData = remapData(state.codeList, newItem, 'id');
      return {
        ...state,
        codeList: newData,
        isCodeListDirty: true,
      };
    }
    case types.SET_CODES_RAW_VALUES: {
      let relationedEndTimestamp = state.codesRawList.find(e => (
        e.id_code === action.payload.end_id
      ));
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      let newData = remapData(state.codesRawList, newItem, 'id_code');
      if (
        relationedEndTimestamp
      && relationedEndTimestamp.timestamp_op !== action.payload.end_timestamp
      ) {
        relationedEndTimestamp = {
          ...relationedEndTimestamp,
          timestamp_op: action.payload.end_timestamp,
          isDirty: true,
        };
        newData = remapData(newData, relationedEndTimestamp, 'id_code');
      }
      return {
        ...state,
        codesRawList: newData,
        isCodesRawListDirty: true,
      };
    }
    case types.SET_HOUR_MACHINE_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };

      const newData = remapData(state.hourMachineList, newItem, 'id');
      return {
        ...state,
        hourMachineList: newData,
        isHourMachineListDirty: true,
      };
    }
    case types.SET_LOAD_VALUES: {
      const {
        start_date: sd, end_date: ed, start_time: st, end_time: et,
      } = action.payload;
      const MS_IN_MINUTES = 1000 * 60;
      const duration = (
        Math.max(0, diffTwoDateTimeStr(
          formatDate(sd, true, true), st, formatDate(ed, true, true), et,
        )) / MS_IN_MINUTES
      ).toFixed(2);
      const newItem = {
        ...action.payload,
        duration,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.loadList, newItem, 'id');
      return {
        ...state,
        loadList: newData,
        isLoadListDirty: true,
      };
    }
    case types.SET_RAILROAD_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.railroadList, newItem, 'id');
      return {
        ...state,
        railroadList: newData,
        isRailroadListDirty: true,
      };
    }
    case types.SET_PERFORATOR_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.perforatorList, newItem, 'id');
      return {
        ...state,
        perforatorList: newData,
        isPerforatorListDirty: true,
      };
    }
    case types.SET_CRUSHER_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.crusherList, newItem, 'id');
      return {
        ...state,
        crusherList: newData,
        isCrusherListDirty: true,
      };
    }
    case types.SET_REFUELER_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.refuelerList, newItem, 'id');
      return {
        ...state,
        refuelerList: newData,
        isRefuelerListDirty: true,
      };
    }
    case types.SET_UNDERGROUND_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.undergroundList, newItem, 'id');
      return {
        ...state,
        undergroundList: newData,
        isUndergroundListDirty: true,
      };
    }
    case types.SET_MAINTENANCE_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.maintenanceList, newItem, 'id');
      return {
        ...state,
        maintenanceList: newData,
        isMaintenanceListDirty: true,
      };
    }

    case types.SET_TRACTOR_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.tractorList, newItem, 'id');
      return {
        ...state,
        tractorList: newData,
        isTractorListDirty: true,
      };
    }
    case types.SET_INFRASTRUCTURE_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.infrastructureList, newItem, 'id');
      return {
        ...state,
        infrastructureList: newData,
        isInfrastructureListDirty: true,
      };
    }
    case types.SET_SHAFT_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.shaftList, newItem, 'id');
      return {
        ...state,
        shaftList: newData,
        isShaftListDirty: true,
      };
    }
    case types.SET_FILLIN_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.fillinList, newItem, 'id');
      return {
        ...state,
        fillinList: newData,
        isFillinListDirty: true,
      };
    }
    case types.SET_SCISSOR_LIFT_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.scissorLiftList, newItem, 'id');
      return {
        ...state,
        scissorLiftList: newData,
        isScissorLiftListDirty: true,
      };
    }
    case types.RESTORE_TRANSPORT_ITEM: {
      const oldItem = state.originalTransportList.find(d => d.id === action.payload.id);
      const newData = remapData(state.transportList, oldItem, 'id');
      const isTransportListDirty = newData.some(d => d.isDirty);
      return {
        ...state,
        isTransportListDirty,
        transportList: newData,
      };
    }
    case types.RESTORE_HOURMETER_ITEM: {
      const oldItem = state.originalHourmeterList.find(d => d.id_line === action.payload.id_line);
      const newData = remapData(state.hourmeterList, oldItem, 'id_line');
      const isHourmeterListDirty = newData.some(d => d.isDirty);
      return {
        ...state,
        isHourmeterListDirty,
        hourmeterList: newData,
      };
    }
    case types.RESTORE_CODE_ITEM: {
      const oldItem = state.originalCodeList.find(d => d.id === action.payload.id);
      const newData = remapData(state.codeList, oldItem, 'id');
      const isCodeListDirty = newData.some(d => d.isDirty);
      return {
        ...state,
        isCodeListDirty,
        codeList: newData,
      };
    }
    case types.RESTORE_HOUR_MACHINE_ITEM: {
      const oldItem = state.originalHourMachineList.find(d => d.id === action.payload.id);
      const newData = remapData(state.hourMachineList, oldItem, 'id');
      const isHourMachineListDirty = newData.some(d => d.isDirty);
      return {
        ...state,
        isHourMachineListDirty,
        hourMachineList: newData,
      };
    }
    case types.RESTORE_LOAD_ITEM: {
      const oldItem = state.originalLoadList.find(d => d.id === action.payload.id);
      const newData = remapData(state.loadList, oldItem, 'id');
      const isLoadListDirty = newData.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isLoadListDirty,
        loadList: newData,
      };
    }
    case types.RESTORE_RAILROAD_ITEM: {
      const oldItem = state.originalRailroadList.find(d => d.id === action.payload.id);
      const newData = remapData(state.railroadList, oldItem, 'id');
      const isRailroadListDirty = newData.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isRailroadListDirty,
        railroadList: newData,
      };
    }
    case types.RESTORE_PERFORATOR_ITEM: {
      const oldItem = state.originalPerforatorList.find(d => d.id === action.payload.id);
      const newData = remapData(state.perforatorList, oldItem, 'id');
      const isPerforatorListDirty = newData.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isPerforatorListDirty,
        perforatorList: newData,
      };
    }
    case types.RESTORE_REFUELER_ITEM: {
      const oldItem = state.originalRefuelerList.find(d => d.id === action.payload.id);
      const newData = remapData(state.refuelerList, oldItem, 'id');
      const isRefuelerListDirty = newData.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isRefuelerListDirty,
        refuelerList: newData,
      };
    }
    case types.RESTORE_UNDERGROUND_ITEM: {
      const oldItem = state.originalUndergroundList.find(d => d.id === action.payload.id);
      const newData = remapData(state.undergroundList, oldItem, 'id');
      const isUndergroundListDirty = newData.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isUndergroundListDirty,
        undergroundList: newData,
      };
    }
    case types.RESTORE_MAINTENANCE_ITEM: {
      const oldItem = state.originalMaintenanceList.find(d => d.id === action.payload.id);
      const newData = remapData(state.maintenanceList, oldItem, 'id');
      const isMaintenanceListDirty = newData.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isMaintenanceListDirty,
        maintenanceList: newData,
      };
    }
    case types.RESTORE_TRACTOR_ITEM: {
      const oldItem = state.originalTractorList.find(d => d.id === action.payload.id);
      const newData = remapData(state.tractorList, oldItem, 'id');
      const isTractorListDirty = newData.some(d => d.isDirty);
      return {
        ...state,
        isTractorListDirty,
        tractorList: newData,
      };
    }
    case types.RESTORE_INFRASTRUCTURE_ITEM: {
      const oldItem = state.originalInfrastructureList.find(d => d.id === action.payload.id);
      const newData = remapData(state.infrastructureList, oldItem, 'id');
      const isInfrastructureListDirty = newData.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isInfrastructureListDirty,
        infrastructureList: newData,
      };
    }
    case types.RESTORE_SHAFT_ITEM: {
      const oldItem = state.originalShaftList.find(d => d.id === action.payload.id);
      const newData = remapData(state.shaftList, oldItem, 'id');
      const isShaftListDirty = newData.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isShaftListDirty,
        shaftList: newData,
      };
    }
    case types.RESTORE_FILLIN_ITEM: {
      const oldItem = state.originalFillinList.find(d => d.id === action.payload.id);
      const newData = remapData(state.fillinList, oldItem, 'id');
      const isFillinListDirty = newData.some(d => d.isDirty);
      return {
        ...state,
        isFillinListDirty,
        fillinList: newData,
      };
    }
    case types.RESTORE_SCISSOR_LIFT_ITEM: {
      const oldItem = state.originalScissorLiftList.find(d => d.id === action.payload.id);
      const newData = remapData(state.scissorLiftList, oldItem, 'id');
      const isScissorLiftListDirty = newData.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isScissorLiftListDirty,
        scissorLiftList: newData,
      };
    }
    case types.RESTORE_ORIGINAL_TRANSPORT: {
      return {
        ...state,
        transportList: [...state.originalTransportList],
        isTransportListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_HOURMETER: {
      return {
        ...state,
        hourmeterList: [...state.originalHourmeterList],
        isHourmeterListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_ACTIVITY: {
      return {
        ...state,
        activityList: [...state.originalActivityList],
        isActivityListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_CODE: {
      return {
        ...state,
        codeList: [...state.originalCodeList],
        isCodeListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_CODES_RAW: {
      return {
        ...state,
        codesRawList: [...state.originalCodesRawList],
        isCodesRawListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_HOUR_MACHINE: {
      return {
        ...state,
        hourMachineList: [...state.originalHourMachineList],
        isHourMachineListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_LOAD: {
      return {
        ...state,
        loadList: [...state.originalLoadList],
        isLoadListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_RAILROAD: {
      return {
        ...state,
        railroadList: [...state.originalRailroadList],
        isRailroadListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_PERFORATOR: {
      return {
        ...state,
        perforatorList: [...state.originalPerforatorList],
        isPerforatorListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_CRUSHER: {
      return {
        ...state,
        crusherList: [...state.originalCrusherList],
        isCrusherListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_REFUELER: {
      return {
        ...state,
        refuelerList: [...state.originalRefuelerList],
        isRefuelerListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_UNDERGROUND: {
      return {
        ...state,
        undergroundList: [...state.originalUndergroundList],
        isUndergroundListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_MAINTENANCE: {
      return {
        ...state,
        maintenanceList: [...state.originalMaintenanceList],
        isMaintenanceListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_TRACTOR: {
      return {
        ...state,
        tractorList: [...state.originalTractorList],
        isTractorListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_INFRASTRUCTURE: {
      return {
        ...state,
        infrastructureList: [...state.originalInfrastructureList],
        isInfrastructureListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_SHAFT: {
      return {
        ...state,
        shaftList: [...state.originalShaftList],
        isShaftListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_FILLIN: {
      return {
        ...state,
        fillinList: [...state.originalFillinList],
        isFillinListDirty: false,
      };
    }
    case types.RESTORE_ORIGINAL_SCISSOR_LIFT: {
      return {
        ...state,
        scissorLiftList: [...state.originalScissorLiftList],
        isScissorLiftListDirty: false,
      };
    }
    case types.CLEAN_UNDERGROUND_LIST: {
      return {
        ...state,
        undergroundList: [],
        originalUndergroundList: [],
        isUndergroundListDirty: false,
      };
    }
    case types.CLEAN_MAINTENANCE_LIST: {
      return {
        ...state,
        maintenanceList: [],
        originalMaintenanceList: [],
        isMaintenanceListDirty: false,
      };
    }
    case types.CREATE_TRANSPORT_ITEM: {
      const newItem = {
        ...action.payload,
        id: (0 - state.transportList.length),
      };
      const newData = [newItem, ...state.transportList];
      return {
        ...state,
        transportList: newData,
        isTransportListDirty: true,
      };
    }
    case types.CREATE_HOURMETER_ITEM: {
      const newItem = {
        ...action.payload,
        id_line: (0 - state.hourmeterList.length),
      };
      const newData = [newItem, ...state.hourmeterList];
      return {
        ...state,
        hourmeterList: newData,
        isHourmeterListDirty: true,
      };
    }
    case types.CREATE_ACTIVITY_ITEM: {
      const newItem = {
        ...action.payload,
        id: (0 - state.activityList.length),
      };
      const newData = [newItem, ...state.activityList];
      return {
        ...state,
        activityList: newData,
        isActivityListDirty: true,
      };
    }
    case types.CREATE_CODE_ITEM: {
      const newItem = {
        ...action.payload,
        id: (0 - state.codeList.length),
      };
      const newData = [newItem, ...state.codeList];
      return {
        ...state,
        codeList: newData,
        isCodeListDirty: true,
      };
    }
    case types.CREATE_CODES_RAW_ITEM: {
      const newItem = {
        ...action.payload,
        id: -state.codesRawList.length,
      };
      const newData = [newItem, ...state.codesRawList];
      return {
        ...state,
        codesRawList: newData,
        isCodesRawListDirty: true,
      };
    }
    case types.CREATE_HOUR_MACHINE_ITEM: {
      const newItem = {
        ...action.payload,
        id: (0 - state.hourMachineList.length),
      };
      const newData = [newItem, ...state.hourMachineList];
      return {
        ...state,
        hourMachineList: newData,
        isHourMachineListDirty: true,
      };
    }
    case types.CREATE_LOAD_ITEM: {
      const newItem = {
        ...action.payload,
        id: (0 - state.loadList.length),
      };
      const newData = [newItem, ...state.loadList];
      return {
        ...state,
        loadList: newData,
        isLoadListDirty: true,
      };
    }
    case types.CREATE_RAILROAD_ITEM: {
      const newItem = {
        ...action.payload,
        id: (0 - state.railroadList.length),
      };
      const newData = [newItem, ...state.railroadList];
      return {
        ...state,
        railroadList: newData,
        israilroadListDirty: true,
      };
    }
    case types.CREATE_PERFORATOR_ITEM: {
      const newItem = {
        ...action.payload,
        id: (0 - state.perforatorList.length),
      };
      const newData = [newItem, ...state.perforatorList];
      return {
        ...state,
        perforatorList: newData,
        isPerforatorListDirty: true,
      };
    }
    case types.CREATE_CRUSHER_ITEM: {
      const newItem = {
        ...action.payload,
        id: (0 - state.crusherList.length),
      };
      const newData = [newItem, ...state.crusherList];
      return {
        ...state,
        crusherList: newData,
        isCrusherListDirty: true,
      };
    }
    case types.CREATE_REFUELER_ITEM: {
      const newItem = {
        ...action.payload,
        id: (0 - state.refuelerList.length),
      };
      const newData = [newItem, ...state.refuelerList];
      return {
        ...state,
        refuelerList: newData,
        isRefuelerListDirty: true,
      };
    }
    case types.CREATE_UNDERGROUND_ITEM: {
      const newItem = {
        update_status: 'I',
        active: 1,
        id: (0 - state.undergroundList.length),
        // date: newMomentDate(),
        // time: formatTime(newMomentDate(), 'HH:mm:ss'),
        start_date: formatDate(newMomentDate()),
        start_time: formatTime(newMomentDate(), 'HH:mm:ss'),
        end_date: formatDate(newMomentDate()),
        end_time: formatTime(newMomentDate(), 'HH:mm:ss'),
        id_project_group: 0,
        id_element: 0,
        id_element_point: 0,
        id_unitary_operation_group: 0,
        id_unitary_operation: 0,
        id_code_type_ug: 0,
        id_code_group_ug: 0,
        id_code_ug: 0,
        estimated_advance: 0.0,
        measured_advance: 0.0,
        cicle: 0, // 1,
        id_turn: 0,
        id_team: 0,
        id_equipment_type: 0,
        id_equipment_groups: 0,
        id_group_equipament_links: 0,
        code_time: 0, // '10:44',
        unitary_operation_time: 0, // '09:44',
      };

      const newData = [newItem, ...state.undergroundList];
      return {
        ...state,
        undergroundList: newData,
        isUndergroundListDirty: true,
      };
    }
    case types.CREATE_MAINTENANCE_ITEM: {
      const newItem = {
        update_status: 'I',
        active: 1,
        id: (0 - state.maintenanceList.length),
        start_date: newMomentDate(),
        end_date: newMomentDate(),
        start_time: formatTime(newMomentDate(), 'HH:mm:ss'),
        end_time: formatTime(newMomentDate(), 'HH:mm:ss'),
        operator_id: 0,
        code_maintenance_id: 0,
        code_group_maintenance_id: 0,
        code_type_maintenance_id: 0,
        code_time: 0,
        comment: '',
        turn_id: 0,
        team_id: 0,
        equipaments_id: 0,
        equipament_groups_id: 0,
        group_equipament_links_id: 0,
      };

      const newData = [newItem, ...state.maintenanceList];
      return {
        ...state,
        maintenanceList: newData,
        isMaintenanceListDirty: true,
      };
    }
    case types.CREATE_TRACTOR_ITEM: {
      const newItem = {
        update_status: 'I',
        active: 1,
        id: (0 - state.tractorList.length),
        start_date: formatTime(newMomentDate(), 'DD/MM/YYYY'),
        start_time: formatTime(newMomentDate(), 'HH:mm:ss'),
        end_date: formatTime(newMomentDate(), 'DD/MM/YYYY'),
        end_time: formatTime(newMomentDate(), 'HH:mm:ss'),
        equip_group_id: 0,
        equip_id: 0,
        operator_group_id: 0,
        operator_id: 0,
        return_date: formatTime(newMomentDate(), 'DD/MM/YYYY'),
        return_time: formatTime(newMomentDate(), 'HH:mm:ss'),
        return_latitude: 0,
        return_longitude: 0,
        return_altitude: 0,
        return_gps_area: 0,
        return_gps_subarea: 0,
        dist_return: 0,
        return_duration: 0,
        cut_date: formatTime(newMomentDate(), 'DD/MM/YYYY'),
        cut_time: formatTime(newMomentDate(), 'HH:mm:ss'),
        cut_latitude: 0,
        cut_longitude: 0,
        cut_altitude: 0,
        cut_gps_area: 0,
        cut_gps_subarea: 0,
        material: 0,
        cut_type: 0,
        prod: 5,
        dist_cut: 0,
        cut_duration: 0,
        code_time: 0,
        turn: 0,
        duration: 0,
        idle_time: null,
      };

      const newData = [newItem, ...state.tractorList];
      return {
        ...state,
        tractorList: newData,
        isTractorListDirty: true,
      };
    }
    case types.CREATE_INFRASTRUCTURE_ITEM: {
      const newItem = {
        ...action.payload,
        id: (0 - state.infrastructureList.length),
      };
      const newData = [newItem, ...state.infrastructureList];
      return {
        ...state,
        infrastructureList: newData,
        isInfrastructureListDirty: true,
      };
    }
    case types.CREATE_SHAFT_ITEM: {
      const newItem = {
        ...action.payload,
        id: (0 - state.shaftList.length),
      };
      const newData = [newItem, ...state.shaftList];
      return {
        ...state,
        shaftList: newData,
        isShaftListDirty: true,
      };
    }
    case types.CREATE_FILLIN_ITEM: {
      const newItem = {
        ...action.payload,
        id: (0 - state.fillinList.length),
      };
      const newData = [newItem, ...state.fillinList];
      return {
        ...state,
        fillinList: newData,
        isFillinListDirty: true,
      };
    }
    case types.CREATE_SCISSOR_LIFT_ITEM: {
      const newItem = {
        ...action.payload,
        id: (0 - state.scissorLiftList.length),
      };
      const newData = [newItem, ...state.scissorLiftList];
      return {
        ...state,
        scissorLiftList: newData,
        isScissorLiftLististDirty: true,
      };
    }
    case types.DELETE_TRANSPORT_ITEM: {
      let data = [];
      if (action.payload.id <= 0) {
        data = state.transportList.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.transportList, action.payload, 'id');
      }
      const isTransportListDirty = data.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isTransportListDirty,
        transportList: [...data],
      };
    }
    case types.DELETE_ACTIVITY_ITEM: {
      let data = [];
      if (action.payload.id <= 0) {
        data = state.activityList.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.activityList, action.payload, 'id');
      }
      const isActivityListDirty = data.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isActivityListDirty,
        activityList: [...data],
      };
    }
    case types.DELETE_HOURMETER_ITEM: {
      let data = [];
      if (action.payload.id_line <= 0) {
        data = state.hourmeterList.filter(l => l.id_line !== action.payload.id_line);
      } else {
        data = remapData(state.hourmeterList, action.payload, 'id_line');
      }
      const isHourmeterListDirty = data.some(d => d.isDirty || d.id_line < 0);
      return {
        ...state,
        isHourmeterListDirty,
        hourmeterList: [...data],
      };
    }
    case types.DELETE_CODE_ITEM: {
      let data = [];
      if (action.payload.id <= 0) {
        data = state.codeList.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.codeList, action.payload, 'id');
      }
      const isCodeListDirty = data.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isCodeListDirty,
        codeList: [...data],
      };
    }
    case types.DELETE_CODES_RAW_ITEM: {
      let data = [];
      if (action.payload.id_code <= 0) {
        data = state.codesRawList.filter(l => l.id_code !== action.payload.id_code);
      } else {
        data = remapData(state.codesRawList, action.payload, 'id_code');
      }
      const isCodesRawListDirty = data.some(d => d.isDirty || d.id_code < 0);
      return {
        ...state,
        isCodesRawListDirty,
        codesRawList: [...data],
      };
    }
    case types.DELETE_HOUR_MACHINE_ITEM: {
      let data = [];
      if (action.payload.id <= 0) {
        data = state.hourMachineList.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.hourMachineList, action.payload, 'id');
      }
      const isHourMachineListDirty = data.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isHourMachineListDirty,
        hourMachineList: [...data],
      };
    }
    case types.DELETE_LOAD_ITEM: {
      let data = [];
      if (action.payload.id <= 0) {
        data = state.loadList.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.loadList, action.payload, 'id');
      }
      const isLoadListDirty = data.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isLoadListDirty,
        loadList: [...data],
      };
    }
    case types.DELETE_RAILROAD_ITEM: {
      let data = [];
      if (action.payload.id <= 0) {
        data = state.railroadList.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.railroadList, action.payload, 'id');
      }
      const isRailroadListDirty = data.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isRailroadListDirty,
        railroadList: [...data],
      };
    }
    case types.DELETE_PERFORATOR_ITEM: {
      let data = [];
      if (action.payload.id <= 0) {
        data = state.perforatorList.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.perforatorList, action.payload, 'id');
      }
      const isPerforatorListDirty = data.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isPerforatorListDirty,
        perforatorList: [...data],
      };
    }

    case types.DELETE_CRUSHER_ITEM: {
      let data = [];
      if (action.payload.id <= 0) {
        data = state.crusherList.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.crusherList, action.payload, 'id');
      }
      const isCrusherListDirty = data.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isCrusherListDirty,
        crusherList: [...data],
      };
    }

    case types.DELETE_REFUELER_ITEM: {
      let data = [];
      if (action.payload.id <= 0) {
        data = state.refuelerList.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.refuelerList, action.payload, 'id');
      }
      const isRefuelerListDirty = data.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isRefuelerListDirty,
        refuelerList: [...data],
      };
    }
    case types.DELETE_UNDERGROUND_ITEM: {
      let data = [];
      if (action.payload.id <= 0) {
        data = state.undergroundList.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.undergroundList, action.payload, 'id');
      }
      const isUndergroundListDirty = data.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isUndergroundListDirty,
        undergroundList: [...data],
      };
    }
    case types.DELETE_MAINTENANCE_ITEM: {
      let data = [];
      if (action.payload.id <= 0) {
        data = state.maintenanceList.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.maintenanceList, action.payload, 'id');
      }
      const isMaintenanceListDirty = data.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isMaintenanceListDirty,
        maintenanceList: [...data],
      };
    }
    case types.DELETE_TRACTOR_ITEM: {
      let data = [];
      if (action.payload.id <= 0) {
        data = state.tractorList.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.tractorList, action.payload, 'id');
      }
      const isTractorListDirty = data.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isTractorListDirty,
        tractorList: [...data],
      };
    }
    case types.DELETE_INFRASTRUCTURE_ITEM: {
      let data = [];
      if (action.payload.id <= 0) {
        data = state.infrastructureList.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.infrastructureList, action.payload, 'id');
      }
      const isInfrastructureListDirty = data.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isInfrastructureListDirty,
        infrastructureList: [...data],
      };
    }
    case types.DELETE_SHAFT_ITEM: {
      let data = [];
      if (action.payload.id <= 0) {
        data = state.shaftList.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.shaftList, action.payload, 'id');
      }
      const isShaftListDirty = data.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isShaftListDirty,
        shaftList: [...data],
      };
    }
    case types.DELETE_FILLIN_ITEM: {
      let data = [];
      if (action.payload.id <= 0) {
        data = state.fillinList.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.fillinList, action.payload, 'id');
      }
      const isFillinListDirty = data.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isFillinListDirty,
        fillinList: [...data],
      };
    }
    case types.DELETE_SCISSOR_LIFT_ITEM: {
      let data = [];
      if (action.payload.id <= 0) {
        data = state.scissorLiftList.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.scissorLiftList, action.payload, 'id');
      }
      const isScissorLiftListDirty = data.some(d => d.isDirty || d.id < 0);
      return {
        ...state,
        isScissorLiftListDirty,
        scissorLiftList: [...data],
      };
    }
    case types.SET_ACTIVITY_UPLOAD_LOADING: {
      return {
        ...state,
        activityUploadLoading: action.payload,
      };
    }
    case types.SET_EQUIPMENT_TYPES_TABS: {
      return {
        ...state,
        equipmentTabs: [...action.payload],
      };
    }
    default:
      return state;
  }
}
