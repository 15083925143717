/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MDatePickerOutlined from '~/components/PF2MDatePickerOutlined';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MTable from '~/components/PF2MTable';
import { newMomentDate, formatTimestamp, parseDate } from '~/utils/moment';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import { useTranslation } from 'react-i18next';
import i18n from '~/i18n';
import styles from '../styles';

import {
  getClosures,
  updateClosures,
} from '~/store/manager/actions';

const INITIAL_COLUMNS = [
  {
    field: 'timestampClose',
    title: i18n.t('common:ClosureDate').toUpperCase(),
  },
  {
    field: 'timestampOp',
    title: i18n.t('common:ConfirmationDate').toUpperCase(),
  },
  {
    field: 'webUserId',
    title: i18n.t('common:User').toUpperCase(),
  },
  {
    field: 'commentsOfClose',
    title: i18n.t('common:Comment').toUpperCase(),
  },
];

const formatObjectToServer = obj => ({
  ...obj,
  timestampOp: formatTimestamp(obj.timestampOp),
  timestampClose: formatTimestamp(obj.timestampClose),
});

const ClosuresTab = ({
  classes,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const formikRef = useRef();
  const user = useSelector(state => state.auth.user);
  const [alertOpen, setAlertOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const closures = useSelector(state => state.manager.closures);

  useEffect(() => {
    async function fetchData() {
      dispatch(getClosures());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);


  const confirmClosure = (e) => {
    e.preventDefault();
    setAlertOpen(true);
  };

  const renderHeader = () => (
    <Formik
      ref={formikRef}
      validateOnChange
      validateOnBlur
      initialValues={{
        timestampClose: newMomentDate(),
        timestampOp: newMomentDate(),
        webUserId: user.id,
        commentsOfClose: '',
      }}
      validate={(values) => {
        const errors = {};

        const {
          timestampClose,
          timestampOp,
          webUserId,
          commentsOfClose,
        } = values;

        const lastClosure = closures[0];

        if (!timestampClose) {
          errors.timestampClose = translate('validation:RequiredField');
        }

        if (timestampClose && timestampClose <= parseDate(lastClosure.timestampClose)) {
          errors.timestampClose = translate('validation:InvalidClosurePeriod');
        }

        if (commentsOfClose === '') {
          errors.commentsOfClose = translate('validation:RequiredField');
        }

        if (commentsOfClose?.length > 128) {
          errors.commentsOfClose = translate('validation:maxLength128');
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        dispatch(updateClosures([formatObjectToServer(values)]));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        touched,
        isValid,
        submitForm,
      }) => (
        <Form style={{ display: 'flex', alignItems: 'center' }}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {translate('common:ClosureDate')}
            </FormLabel>
            <FormGroup>
              <Field
                name="timestampClose"
                render={({ field, form }) => (
                  <PF2MDatePickerOutlined
                    {...field}
                    disableFuture
                    disablePast={false}
                    onChange={(e) => {
                      form.setFieldValue('timestampClose', e);
                      form.setError('timestampClose', e);
                    }}
                  />
                )}
              />
            </FormGroup>
            { errors.timestampClose && (
              <ErrorMessage
                name="timestampClose"
                component="span"
                className={classes.errorMessage}
              />
            )}
          </FormControl>
          <FormControl className={classes.closureName}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Comment')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                type="text"
                name="commentsOfClose"
                className={errors.commentsOfClose ? classes.fieldError : classes.field}
              />
            </FormGroup>
            <ErrorMessage
              name="commentsOfClose"
              component="span"
              className={classes.errorMessage}
            />
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <PF2MAddButton disabled={isSubmitting || !isValid} onClick={e => confirmClosure(e)} />
          </div>
          <PF2MAlertDialog
            cancelText={translate('common:Cancel')}
            confirmText={translate('common:Save')}
            description={translate('validation:ClosureSavingWarning')}
            open={alertOpen}
            onConfirm={() => {
              submitForm();
              setAlertOpen(false);
            }}
            onClose={() => {
              setAlertOpen(false);
            }}
          />
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={closures}
        columns={INITIAL_COLUMNS}
        className={classes.grid}
      />
    </div>
  );
};

ClosuresTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (withStyles(styles)(ClosuresTab));
