import * as types from './types';
import { remapData } from '~/utils';
import { parseTime, addHours, formatDate } from '~/utils/moment';
import i18n from '~/i18n';

const INITIAL_STATE = {
  operatorsGroups: [],
  operators: [],
  equipmentsGroups: [],
  equipments: [],
  materialsGroups: [],
  materials: [],
  originalTurnsGroups: [],
  turnsGroups: [],
  isTurnsGroupsDirty: false,
  isShiftScalesDirty: false,
  originalShiftScales: [],
  shiftScales: [],
  isEquipmentTurnGroupsDirty: false,
  originalEquipmentTurnGroups: [],
  equipmentTurnGroups: [],
  isHandlingTypesDirty: false,
  originalHandlingTypes: [],
  handlingTypes: [],
  isElementsDirty: false,
  originalElements: [],
  elements: [],
  isSubElementsDirty: false,
  originalSubElements: [],
  subElements: [],
  isMaterialMovementsDirty: false,
  originalMaterialMovements: [],
  materialMovements: [],
  isMaterialElementsDirty: false,
  originalMaterialElements: [],
  materialElements: [],
  alertDialog: false,
  codes: [],
  codeTypes: [],
  codeGroups: [],
  codeMigrationRules: [],
  equipmentTypes: [],
  isWeighingsDirty: false,
  originalWeighings: [],
  weighings: [],
  checklist: [],
  checklistMaps: [],
  isChecklistMapsDirty: false,
  originalChecklistMaps: [],
  isTurnsDirty: false,
  originalTurns: [],
  turns: [],
  originalTeamGroups: [],
  isTeamGroupsDirty: false,
  teamGroups: [],
  originalTeams: [],
  isTeamsDirty: false,
  teams: [],
  originalTeamsListing: [],
  isTeamsListingDirty: false,
  teamsListing: [],
  originalFireIds: [],
  isFireIdsDirty: false,
  fireIds: [],
  originalDrillholeTypes: [],
  isDrillholeTypesDirty: false,
  drillholeTypes: [],
  isDrillholeDepthsDirty: false,
  originalDrillholeDepths: [],
  drillholeDepths: [],
  calendarTurnsTeams: [],
  isCalendarTurnsTeamsDirty: false,
  originalCalendarTurnsTeams: [],
  isElementsOdDirty: false,
  originalElementsOd: [],
  elementsOd: [],
  isCodesDirty: false,
  originalCodes: [],
  hourMachine: [],
  isHourMachineDirty: false,
  originalHourMachine: [],
  operationTypes: [],
  isOperationTypesDirty: false,
  originalOperationTypes: [],
  operationTypeActivities: [],
  transportOperationTypes: [],
  isTransportOperationTypesDirty: false,
  originalTransportOperationTypes: [],
  tractorProductivities: [],
  isTractorProductivitiesDirty: false,
  originalTractorProductivities: [],
  exceptionTypes: [],
  sleepLevels: [],
  isFandrillFansDirty: false,
  fandrillFans: [],
  originalFandrillFans: [],
  isFandrillBitDiametersDirty: false,
  originalFandrillBitDiameters: [],
  fandrillBitDiameters: [],
  isHourmeterNamesDirty: false,
  originalHourmeterNames: [],
  hourmeterNames: [],
  isFandrillBitDiametersNamesDirty: false,
  originalFandrillBitDiametersNames: [],
  fandrillBitDiametersNames: [],
  loads: [],
  originalLoads: [],
  isLoadsDirty: false,
  loadFactors: [],
  originalLoadFactors: [],
  isLoadFactorsDirty: false,
  tabletConfiguration: [],
  isTabletConfigurationDirty: false,
  originalTabletConfiguration: [],
  platformConfiguration: [],
  isPlatformConfigurationDirty: false,
  originalPlatformConfiguration: [],
  subElementTypes: [],
  flowMovements: [],
  isFlowMovementsDirty: false,
  originalFlowMovements: [],
  flowTypes: [],
  webUsers: [],
  originalWebUsers: [],
  isWebUsersDirty: false,
  contents: [],
  contentVariables: [],
  unitOperations: [],
  unitOperationGroups: [],
  activityTypesChecklist: [],
  originalActivityTypesChecklist: [],
  isActivityTypeChecklistDirty: false,
  activityItemsChecklist: [],
  originalActivityItemsChecklist: [],
  isActivityItemsChecklistDirty: false,
  goalIndicators: [],
  boomReasons: [],
  originalBoomReasons: [],
  codesRawRulesMatch: [],
  controlTypes: [],
  beaconTypes: [],
  beacons: [],
  originalBeacons: [],
  isBeaconsDirty: false,
  loadingMigrationCodeMatch: false,
  fileUploadErrors: [],
  closures: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.ADD_BEACON: {
      const newItem = {
        ...action.payload,
        id: 0 - state.beacons.length,
        update_status: 'I',
        active: 1,
      };
      const newData = [newItem, ...state.beacons];
      return {
        ...state,
        beacons: newData,
      };
    }
    case types.RESET_BEACONS: {
      return {
        ...state,
        isBeaconsDirty: false,
        beacons: [...state.originalBeacons],
      };
    }
    case types.SET_BEACONS_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: action.payload.id >= 1,
      };
      const newData = remapData(state.beacons, newItem, 'id');
      const isBeaconsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isBeaconsDirty,
        beacons: newData,
      };
    }
    case types.SET_BEACONS_LIST: {
      return {
        ...state,
        beacons: action.payload,
        originalBeacons: action.payload,
      };
    }
    case types.SET_BEACON_TYPES_LIST: {
      return {
        ...state,
        beaconTypes: action.payload,
      };
    }
    case types.SET_CONTROL_TYPES_LIST: {
      return {
        ...state,
        controlTypes: action.payload,
      };
    }
    case types.SET_CODES_RAW_RULES_MATCH: {
      return {
        ...state,
        codesRawRulesMatch: action.payload,
      };
    }
    case types.SET_CODES_RAW_RULES_MATCH_VALUES: {
      const newItem = {
        ...action.payload,
      };
      const newData = remapData(state.codesRawRulesMatch, newItem, 'id');
      return {
        ...state,
        codesRawRulesMatch: newData,
      };
    }
    case types.TOGGLE_LOADING_MIGRATION_CODE_MATCH: {
      return {
        ...state,
        loadingMigrationCodeMatch: Boolean(action.payload.state),
      };
    }
    case types.SET_UNIT_OPERATIONS_LIST: {
      return {
        ...state,
        unitOperations: action.payload,
      };
    }
    case types.SET_ORIGIN_TYPE_LIST: {
      return {
        ...state,
        originTypes: action.payload,
      };
    }
    case types.SET_GOAL_INDICATORS_LIST: {
      return {
        ...state,
        goalIndicators: action.payload,
      };
    }
    case types.SET_BOOM_REASON_LIST: {
      return {
        ...state,
        boomReasons: action.payload,
        originalBoomReasons: action.payload,
      };
    }
    case types.SET_UNIT_OPERATION_GROUPS_LIST: {
      return {
        ...state,
        unitOperationGroups: action.payload,
      };
    }
    case types.SET_CONTENTS_LIST: {
      return {
        ...state,
        contents: action.payload,
      };
    }
    case types.SET_CONTENT_VARIABLES_LIST: {
      return {
        ...state,
        contentVariables: action.payload,
      };
    }
    case types.SET_OPERATORS_GROUPS_LIST: {
      return {
        ...state,
        operatorsGroups: action.payload,
      };
    }
    case types.SET_OPERATORS_LIST: {
      return {
        ...state,
        operators: action.payload,
      };
    }
    case types.SET_TABLET_CONFIGURATION_LIST: {
      return {
        ...state,
        tabletConfiguration: action.payload,
        originalTabletConfiguration: action.payload,
        isTabletConfigurationDirty: false,
      };
    }
    case types.SET_TABLET_CONFIGURATION_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.tabletConfiguration, newItem, 'key');
      const isTabletConfigurationDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTabletConfigurationDirty,
        tabletConfiguration: newData,
      };
    }
    case types.SET_BOOM_REASON_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.boomReasons, newItem, 'id');
      return {
        ...state,
        boomReasons: newData,
      };
    }
    case types.RESET_TABLET_CONFIGURATIONS: {
      return {
        ...state,
        isTabletConfigurationDirty: false,
        tabletConfiguration: [...state.originalTabletConfiguration],
      };
    }
    case types.SET_PLATFORM_CONFIGURATION_LIST: {
      return {
        ...state,
        platformConfiguration: action.payload,
        originalPlatformConfiguration: action.payload,
        isPlatformConfigurationDirty: false,
      };
    }
    case types.SET_PLATFORM_CONFIGURATION_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.platformConfiguration, newItem, 'key');
      const isPlatformConfigurationDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isPlatformConfigurationDirty,
        platformConfiguration: newData,
      };
    }
    case types.RESET_PLATFORM_CONFIGURATIONS: {
      return {
        ...state,
        isPlatformConfigurationDirty: false,
        platformConfiguration: [...state.originalPlatformConfiguration],
      };
    }
    case types.SET_EQUIPMENTS_GROUPS_LIST: {
      return {
        ...state,
        equipmentsGroups: action.payload,
      };
    }
    case types.SET_SUBELEMENT_TYPES_LIST: {
      return {
        ...state,
        subElementTypes: action.payload,
      };
    }
    case types.SET_FLOW_TYPES_LIST: {
      return {
        ...state,
        flowTypes: action.payload,
      };
    }
    case types.SET_FLOW_MOVEMENTS_LIST: {
      return {
        ...state,
        isFlowMovementsDirty: false,
        originalFlowMovements: action.payload,
        flowMovements: action.payload,
      };
    }
    case types.SET_EQUIPMENTS_LIST: {
      return {
        ...state,
        equipments: action.payload,
      };
    }
    case types.SET_MATERIALS_GROUPS_LIST: {
      return {
        ...state,
        materialsGroups: action.payload,
      };
    }
    case types.SET_MATERIALS_LIST: {
      return {
        ...state,
        materials: action.payload,
      };
    }
    case types.SET_TURNS_GROUPS_LIST: {
      return {
        ...state,
        isTurnsGroupsDirty: false,
        originalTurnsGroups: action.payload,
        turnsGroups: action.payload,
      };
    }
    case types.SET_TURN_GROUP_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.turnsGroups, newItem, 'id');
      const isTurnsGroupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTurnsGroupsDirty,
        turnsGroups: newData,
      };
    }
    case types.DELETE_TURN_ITEM: {
      let data = [];

      if (action.payload.id <= 0) {
        data = state.turnsGroups.filter(l => l.id !== action.payload.id);
      } else {
        data = remapData(state.turnsGroups, action.payload, 'id');
      }
      const isTurnsGroupsDirty = data.some(d => d.update_status);
      return {
        ...state,
        isTurnsGroupsDirty,
        turnsGroups: [...data],
      };
    }
    case types.RESTORE_TURN_ITEM: {
      const oldItem = state.originalTurnsGroups.find(
        d => d.id === action.payload.id,
      );
      const newData = remapData(state.turnsGroups, oldItem, 'id');
      const isTurnsGroupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTurnsGroupsDirty,
        turnsGroups: newData,
      };
    }
    case types.ADD_TURN_GROUP: {
      const newItem = {
        ...action.payload,
        id: 0 - state.turnsGroups.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };

      const newData = [newItem, ...state.turnsGroups];
      const isTurnsGroupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTurnsGroupsDirty,
        turnsGroups: newData,
      };
    }
    case types.RESET_TURNS_GROUPS: {
      return {
        ...state,
        isTurnsGroupsDirty: false,
        turnsGroups: [...state.originalTurnsGroups],
      };
    }
    case types.SET_SHIFT_SCALES_LIST: {
      return {
        ...state,
        isShiftScalesDirty: false,
        originalShiftScales: action.payload,
        shiftScales: action.payload,
      };
    }
    case types.SET_SHIFT_SCALE_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      let newData = remapData(state.shiftScales, newItem, 'id');
      const currentItemIndex = state.shiftScales.findIndex(
        s => s.id === newItem.id,
      );
      const nextItem = state.shiftScales[currentItemIndex + 1];
      if (nextItem && nextItem.turn_group === newItem.turn_group) {
        const newNextItem = {
          ...nextItem,
          start_turn: newItem.end_turn,
          isDirty: true,
          update_status: nextItem.update_status === 'I' ? 'I' : 'U',
        };
        newData = remapData(newData, newNextItem, 'id');
      }
      const isShiftScalesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isShiftScalesDirty,
        shiftScales: newData,
      };
    }
    case types.ADD_SHIFT_SCALE: {
      const {
        turn_group: turnGroup,
        turn_group_name: turnGroupName,
        intervals,
      } = action.payload;
      let newIntervals = intervals;
      const existingShiftByTurn = state.shiftScales.filter(
        s => s.turn_group === turnGroup,
      );

      if (existingShiftByTurn && existingShiftByTurn.length > 0) {
        newIntervals = intervals + existingShiftByTurn.length;
      }
      const newItems = [];
      const time = Math.round((24 / newIntervals) * 10) / 10;

      let start = parseTime(0);
      let end = parseTime(time);
      let blocked = false;
      let id = 0 - state.shiftScales.length;
      let existingItem = null;
      let newItem = null;
      for (let i = 1; i < newIntervals + 1; i += 1) {
        if (i === newIntervals) {
          end = parseTime(0);
          blocked = true;
        }

        if (existingShiftByTurn && existingShiftByTurn.length > 0) {
          existingItem = existingShiftByTurn.find(e => e.interval === i);
        }

        if (existingItem) {
          newItem = {
            ...existingItem,
            blocked,
            interval: i,
            name: `${i18n.t('common:Interval')} ${i}`,
            update_status: existingItem.update_status === 'I' ? 'I' : 'U',
            isDirty: true,
            start_turn: start,
            end_turn: end,
          };
        } else {
          newItem = {
            id,
            blocked,
            turn_group_name: turnGroupName,
            turn_group: turnGroup,
            id_turn: 0,
            interval: i,
            name: `${i18n.t('common:Interval')} ${i}`,
            mon: 0,
            tue: 0,
            wed: 0,
            thu: 0,
            fri: 0,
            sat: 0,
            sun: 0,
            update_status: 'I',
            active: 1,
            isDirty: true,
            start_turn: start,
            end_turn: end,
          };
        }

        newItems.push(newItem);

        start = end;
        end = addHours(end, time);
        id -= 1;
      }

      const newData = [
        ...newItems,
        ...state.shiftScales.filter(s => s.turn_group !== turnGroup),
      ];
      const isShiftScalesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isShiftScalesDirty,
        shiftScales: newData,
      };
    }
    case types.RESET_SHIFT_SCALES: {
      return {
        ...state,
        isShiftScalesDirty: false,
        shiftScales: [...state.originalShiftScales],
      };
    }
    case types.SET_EQUIPMENT_TURN_GROUPS_LIST: {
      return {
        ...state,
        isEquipmentTurnGroupsDirty: false,
        originalEquipmentTurnGroups: action.payload,
        equipmentTurnGroups: action.payload,
      };
    }
    case types.SET_EQUIPMENT_TURN_GROUP_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.equipmentTurnGroups, newItem, 'id');
      const isEquipmentTurnGroupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isEquipmentTurnGroupsDirty,
        equipmentTurnGroups: newData,
      };
    }
    case types.ADD_EQUIPMENT_TURN_GROUP: {
      const newItem = {
        ...action.payload,
        id: 0 - state.equipmentTurnGroups.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.equipmentTurnGroups];
      const isEquipmentTurnGroupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isEquipmentTurnGroupsDirty,
        equipmentTurnGroups: newData,
      };
    }
    case types.ADD_BOOM_REASON: {
      const newItem = {
        ...action.payload,
        id: 0 - state.boomReasons.length,
        update_status: 'I',
        active: 1,
      };
      const newData = [newItem, ...state.boomReasons];
      return {
        ...state,
        boomReasons: newData,
      };
    }
    case types.RESET_EQUIPMENT_TURN_GROUPS: {
      return {
        ...state,
        isEquipmentTurnGroupsDirty: false,
        equipmentTurnGroups: [...state.originalEquipmentTurnGroups],
      };
    }
    case types.RESET_BOOM_REASONS: {
      return {
        ...state,
        boomReasons: [...state.originalBoomReasons],
      };
    }
    case types.SET_HANDLING_TYPES_LIST: {
      return {
        ...state,
        isHandlingTypesDirty: false,
        originalHandlingTypes: action.payload,
        handlingTypes: action.payload,
      };
    }
    case types.SET_HANDLING_TYPE_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.handlingTypes, newItem, 'id');
      const isHandlingTypesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isHandlingTypesDirty,
        handlingTypes: newData,
      };
    }
    case types.ADD_HANDLING_TYPE: {
      const newItem = {
        ...action.payload,
        id: 0 - state.handlingTypes.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.handlingTypes];
      const isHandlingTypesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isHandlingTypesDirty,
        handlingTypes: newData,
      };
    }
    case types.RESET_HANDLING_TYPES: {
      return {
        ...state,
        isHandlingTypesDirty: false,
        handlingTypes: [...state.originalHandlingTypes],
      };
    }
    case types.SET_ELEMENTS_LIST: {
      return {
        ...state,
        isElementsDirty: false,
        elements: action.payload,
        originalElements: action.payload,
      };
    }
    case types.SET_SUBELEMENTS_LIST: {
      return {
        ...state,
        isSubElementsDirty: false,
        subElements: action.payload,
        originalSubElements: action.payload,
      };
    }
    case types.SET_MATERIAL_MOVEMENTS_LIST: {
      return {
        ...state,
        isMaterialMovementsDirty: false,
        originalMaterialMovements: action.payload,
        materialMovements: action.payload,
      };
    }
    case types.SET_MATERIAL_MOVEMENT_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.materialMovements, newItem, 'id');
      const isMaterialMovementsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isMaterialMovementsDirty,
        materialMovements: newData,
      };
    }
    case types.ADD_MATERIAL_MOVEMENT: {
      const newItem = {
        ...action.payload,
        id: 0 - state.materialMovements.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.materialMovements];
      const isMaterialMovementsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isMaterialMovementsDirty,
        materialMovements: newData,
      };
    }

    case types.RESET_MATERIAL_MOVEMENTS: {
      return {
        ...state,
        isMaterialMovementsDirty: false,
        materialMovements: [...state.originalMaterialMovements],
      };
    }

    case types.ADD_FLOW_MOVEMENT: {
      const newItem = {
        ...action.payload,
        id: 0 - state.flowMovements.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.flowMovements];
      const isFlowMovementsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isFlowMovementsDirty,
        flowMovements: newData,
      };
    }
    case types.RESET_FLOW_MOVEMENTS: {
      return {
        ...state,
        isFlowMovementsDirty: false,
        flowMovements: [...state.originalFlowMovements],
      };
    }
    case types.SET_FLOW_MOVEMENT_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.flowMovements, newItem, 'id');
      const isFlowMovementsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isFlowMovementsDirty,
        flowMovements: newData,
      };
    }
    case types.SET_MATERIAL_ELEMENTS_LIST: {
      return {
        ...state,
        isMaterialElementsDirty: false,
        originalMaterialElements: action.payload,
        materialElements: action.payload,
      };
    }
    case types.RESET_MATERIAL_ELEMENTS: {
      return {
        ...state,
        isMaterialElementsDirty: false,
        materialElements: [...state.originalMaterialElements],
      };
    }
    case types.SET_MATERIAL_ELEMENTS_VALUES: {
      const { materialId = 0, origin, destination } = action.payload;
      const materialElement = state.materialElements.find(m => m.id_material === materialId) || {};
      const {
        origins = [],
        destinations = [],
        id = 0 - state.materialElements.length,
      } = materialElement;

      let newOrigins = [];
      let newDestinations = [];

      if (origin) {
        if (origin.checked) {
          newOrigins = [...origins, Number(origin.id)];
        } else {
          newOrigins = origins.filter(m => m !== origin.id);
        }
      } else {
        newOrigins = [...origins];
      }

      if (destination) {
        if (destination.checked) {
          newDestinations = [...destinations, Number(destination.id)];
        } else {
          newDestinations = destinations.filter(m => m !== destination.id);
        }
      } else {
        newDestinations = [...destinations];
      }

      let newData = [];
      const newItem = {
        id,
        id_material: materialId,
        origins: newOrigins,
        destinations: newDestinations,
        isDirty: !(action.payload.id < 1),
        update_status: action.payload.update_status === 'U' ? 'U' : 'I',
      };

      if (!materialElement) {
        newData = [...state.materialElements, newItem];
      } else {
        newData = [
          newItem,
          ...state.materialElements.filter(s => s.id_material !== materialId),
        ];
      }

      const isMaterialElementsDirty = newData.some(d => d.update_status);

      return {
        ...state,
        isMaterialElementsDirty,
        materialElements: newData,
      };
    }
    case types.SET_ALERT_DIALOG: {
      return {
        ...state,
        alertDialog: !state.alertDialog,
      };
    }
    case types.SET_HOUR_MACHINE_LIST: {
      return {
        ...state,
        isHourMachineDirty: false,
        hourMachine: action.payload,
        originalHourMachine: action.payload,
      };
    }
    case types.ADD_CODE: {
      const newItem = {
        ...action.payload,
        id_pk: 0 - state.codes.length,
        id: 0 - state.codes.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };

      const newData = [newItem, ...state.codes];
      const isCodesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isCodesDirty,
        codes: newData,
      };
    }
    case types.SET_CODES_LIST: {
      return {
        ...state,
        isCodesDirty: false,
        codes: action.payload,
        originalCodes: action.payload,
      };
    }
    case types.SET_CODE_TYPES_LIST: {
      return {
        ...state,
        codeTypes: action.payload,
      };
    }
    case types.SET_CODE_GROUPS_LIST: {
      return {
        ...state,
        codeGroups: action.payload,
      };
    }
    case types.SET_CODES_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: true,
      };
      const newData = remapData(state.codes, newItem, 'id_pk');
      const isCodesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isCodesDirty,
        codes: newData,
      };
    }
    case types.RESET_CODES: {
      return {
        ...state,
        isCodesDirty: false,
        codes: [...state.originalCodes],
      };
    }
    case types.SET_EQUIPMENT_TYPES_LIST: {
      return {
        ...state,
        equipmentTypes: action.payload,
      };
    }
    case types.SET_WEIGHINGS_LIST: {
      const { payload: data = [] } = action;
      const newData = data.map((d) => {
        const { shift_scales: shifts = [], ...attrs } = d;
        shifts.forEach((s) => {
          attrs[`shiftScale_${s.id}`] = s.value;
          const PeriodAleatory = s.percentual_period_aleatory ? s.percentual_period_aleatory : 0;
          attrs.percentual_period_aleatory = PeriodAleatory;
          const probabilityInAleatory = s.probability_in_aleatory ? s.probability_in_aleatory : 100;
          attrs.probability_in_aleatory = probabilityInAleatory;
        });

        return attrs;
      });
      return {
        ...state,
        isWeighingsDirty: false,
        originalWeighings: newData,
        weighings: newData,
      };
    }
    case types.RESET_WEIGHINGS_LIST: {
      return {
        ...state,
        isWeighingsDirty: false,
        weighings: [...state.originalWeighings],
      };
    }
    case types.SET_WEIGHING_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.weighings, newItem, 'id');
      const isWeighingsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isWeighingsDirty,
        weighings: newData,
      };
    }
    case types.SET_CHECKLIST_LIST: {
      return {
        ...state,
        checklist: action.payload,
      };
    }
    case types.SET_CHECKLIST_MAPS_LIST: {
      return {
        ...state,
        isChecklistMapsDirty: false,
        originalChecklistMaps: action.payload,
        checklistMaps: action.payload,
      };
    }
    case types.RESET_CHECKLIST_MAPS: {
      return {
        ...state,
        isChecklistMapsDirty: false,
        checklistMaps: [...state.originalChecklistMaps],
      };
    }
    case types.SET_CHECKLIST_MAP_VALUES: {
      const {
        equipmentType = 0,
        equipmentGroup = 0,
        item = null,
      } = action.payload;

      const checklistMap = state.checklistMaps.find(
        m => m.eq_type === equipmentType && m.eq_group === equipmentGroup,
      ) || {};

      const {
        checklist = [],
        id = 0 - state.checklistMaps.length,
      } = checklistMap;

      let newChecklistMaps = [];

      if (item) {
        if (item.checked) {
          newChecklistMaps = [...checklist, item.id];
        } else {
          newChecklistMaps = checklist.filter(m => m !== item.id);
        }
      } else {
        newChecklistMaps = [...checklist];
      }

      let newData = [];
      const newItem = {
        id,
        eq_type: equipmentType,
        eq_group: equipmentGroup,
        checklist: newChecklistMaps,
        isDirty: !(action.payload.id < 1),
        update_status: action.payload.update_status === 'U' ? 'U' : 'I',
      };

      if (!checklistMap) {
        newData = [...state.checklistMaps, newItem];
      } else {
        newData = [
          newItem,
          ...state.checklistMaps.filter(
            s => s.eq_type !== equipmentType && s.eq_group === equipmentGroup,
          ),
        ];
      }

      const isChecklistMapsDirty = newData.some(d => d.update_status);

      return {
        ...state,
        isChecklistMapsDirty,
        checklistMaps: newData,
      };
    }
    case types.SET_TURN_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.turns, newItem, 'id');
      const isTurnsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTurnsDirty,
        turns: newData,
      };
    }
    case types.ADD_TURN: {
      const newItem = {
        ...action.payload,
        id: 0 - state.turns.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.turns];
      const isTurnsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTurnsDirty,
        turns: newData,
      };
    }
    case types.RESET_TURNS: {
      return {
        ...state,
        isTurnsDirty: false,
        turns: [...state.originalTurns],
      };
    }
    case types.SET_TURNS_LIST: {
      return {
        ...state,
        isTurnsDirty: false,
        originalTurns: action.payload,
        turns: action.payload,
      };
    }
    case types.SET_DMTS_LIST: {
      return {
        ...state,
        dmts: action.payload,
      };
    }
    case types.ADD_LOAD: {
      const newItem = {
        ...action.payload,
        id_pk: 0 - state.loads.length,
        id: 0 - state.loads.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };

      const newData = [newItem, ...state.loads];
      const isLoadsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isLoadsDirty,
        loads: newData,
      };
    }
    case types.SET_LOADS_LIST: {
      return {
        ...state,
        originalLoads: action.payload,
        loads: action.payload,
      };
    }
    case types.SET_LOADS_VALUES: {
      const newItem = {
        ...action.payload,
        value: parseInt(action.payload.value),
        operation_type_name: action.payload.operation_type_name
          ? action.payload.operation_type_name : null,
        isDirty: true,
      };
      const newData = remapData(state.loads, newItem, 'id_link');
      const isLoadsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isLoadsDirty,
        loads: newData,
      };
    }
    case types.RESET_LOADS: {
      return {
        ...state,
        isTeamsDirty: false,
        loads: [...state.originalLoads],
      };
    }
    case types.SET_TEAM_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.teams, newItem, 'id');
      const isTeamsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTeamsDirty,
        teams: newData,
      };
    }
    case types.ADD_TEAM: {
      const newItem = {
        ...action.payload,
        id: 0 - state.teams.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.teams];
      const isTeamsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTeamsDirty,
        teams: newData,
      };
    }
    case types.RESET_TEAMS: {
      return {
        ...state,
        isTeamsDirty: false,
        teams: [...state.originalTeams],
      };
    }
    case types.SET_TEAMS_LIST: {
      return {
        ...state,
        isTeamsDirty: false,
        originalTeams: action.payload,
        teams: action.payload,
      };
    }
    case types.SET_TEAM_GROUP_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.teamGroups, newItem, 'id');
      const isTeamGroupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTeamGroupsDirty,
        teamGroups: newData,
      };
    }
    case types.ADD_TEAM_GROUP: {
      const newItem = {
        ...action.payload,
        id: 0 - state.teamGroups.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.teamGroups];
      const isTeamGroupsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTeamGroupsDirty,
        teamGroups: newData,
      };
    }
    case types.RESET_TEAM_GROUPS: {
      return {
        ...state,
        isTeamGroupsDirty: false,
        teamGroups: [...state.originalTeamGroups],
      };
    }
    case types.SET_TEAM_GROUPS_LIST: {
      return {
        ...state,
        isTeamGroupsDirty: false,
        originalTeamGroups: action.payload,
        teamGroups: action.payload,
      };
    }
    case types.SET_TEAMS_LISTING_LIST: {
      const items = action.payload.map((t) => {
        const newItem = {
          id_turn_group: t.id_turn_group,
          id_team_group: t.id_team_group,
          day: `${i18n.t('common:Day')} ${t.day_number}`,
          day_number: t.day_number,
          id: t.id,
          active: true,
        };
        if (t.listing) {
          for (let i = 0; i < t.listing.length; i += 1) {
            const current = t.listing[i];
            newItem[`turn_${current.id_turn}`] = current.id_team;
            newItem[`listing_${current.id_turn}`] = current.id;
          }
        }
        return newItem;
      });

      return {
        ...state,
        isTeamsListingDirty: false,
        originalTeamsListing: action.payload,
        teamsListing: items, // action.payload,
      };
    }
    case types.SET_TEAM_LISTING_VALUES: {
      const { turnGroup, teamGroup, ...rest } = action.payload;
      const newItem = {
        ...rest,
        isDirty: !(action.payload.id < 1),
      };

      const newData = remapData(state.teamsListing, newItem, 'id');
      const isTeamsListingDirty = newData.some(d => d.update_status);

      return {
        ...state,
        isTeamsListingDirty,
        teamsListing: newData,
      };
    }
    case types.ADD_TEAM_LISTING: {
      const {
        turns, turnGroup, teamGroup, days,
      } = action.payload;
      const items = [];
      const teamsListingLength = state.teamsListing.length;
      const start = teamsListingLength + 1;
      const end = days + teamsListingLength;
      for (let index = start; index < end + 1; index += 1) {
        const newId = 0 - index;
        const item = {
          id_turn_group: turnGroup,
          id_team_group: teamGroup,
          day: `${i18n.t('common:Day').toUpperCase()} ${index}`,
          day_number: index,
          id: newId,
          update_status: 'I',
          active: 1,
          isDirty: true,
          turn_0: 0,
        };
        for (let i = 0; i < turns.length; i += 1) {
          item[`turn_${turns[i].id}`] = 0;
        }
        items.push(item);
      }

      const newData = [...items, ...state.teamsListing];
      const isTeamsListingDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTeamsListingDirty,
        teamsListing: newData.sort((a, b) => a.day_number - b.day_number),
      };
    }
    case types.RESET_TEAMS_LISTING: {
      return {
        ...state,
        isTeamsListingDirty: false,
        teamsListing: [...state.originalTeamsListing],
      };
    }
    case types.REMOVE_TEAM_LISTING_DAY: {
      const { day_number: day } = action.payload;
      const current = state.teamsListing.find(l => l.day_number === day);
      current.update_status = 'D';
      const newItems = state.teamsListing
        .filter(l => l.id !== current.id)
        .sort((a, b) => a.day_number - b.day_number)
        .map((l, i) => ({
          ...l,
          day: `${i18n.t('common:Day').toUpperCase()} ${i + 1}`,
          day_number: i + 1,
        }));

      const newData = [...newItems, current];
      const isTeamsListingDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTeamsListingDirty,
        teamsListing: newData,
      };
    }
    case types.SET_FIRE_ID_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.fireIds, newItem, 'id');
      const isFireIdsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isFireIdsDirty,
        fireIds: newData,
      };
    }
    case types.ADD_FIRE_ID: {
      const newItem = {
        ...action.payload,
        id: 0 - state.fireIds.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.fireIds];
      const isFireIdsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isFireIdsDirty,
        fireIds: newData,
      };
    }
    case types.RESET_FIRE_IDS: {
      return {
        ...state,
        isFireIdsDirty: false,
        fireIds: [...state.originalFireIds],
      };
    }
    case types.SET_FIRE_IDS_LIST: {
      return {
        ...state,
        isFireIdsDirty: false,
        originalFireIds: action.payload,
        fireIds: action.payload,
      };
    }
    case types.SET_DRILLHOLE_TYPE_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.drillholeTypes, newItem, 'id');
      const isDrillholeTypesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isDrillholeTypesDirty,
        drillholeTypes: newData,
      };
    }
    case types.ADD_DRILLHOLE_TYPE: {
      const newItem = {
        ...action.payload,
        id: 0 - state.drillholeTypes.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.drillholeTypes];
      const isDrillholeTypesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isDrillholeTypesDirty,
        drillholeTypes: newData,
      };
    }
    case types.RESET_DRILLHOLE_TYPES: {
      return {
        ...state,
        isDrillholeTypesDirty: false,
        drillholeTypes: [...state.originalDrillholeTypes],
      };
    }
    case types.SET_DRILLHOLE_TYPES_LIST: {
      return {
        ...state,
        isDrillholeTypesDirty: false,
        originalDrillholeTypes: action.payload,
        drillholeTypes: action.payload,
      };
    }
    case types.SET_DRILLHOLE_DEPTH_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.drillholeDepths, newItem, 'id');
      const isDrillholeDepthsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isDrillholeDepthsDirty,
        drillholeDepths: newData,
      };
    }
    case types.RESET_DRILLHOLE_DEPTHS: {
      return {
        ...state,
        isDrillholeDepthsDirty: false,
        drillholeDepths: [...state.originalDrillholeDepths],
      };
    }
    case types.SET_DRILLHOLE_DEPTHS_LIST: {
      return {
        ...state,
        isDrillholeDepthsDirty: false,
        originalDrillholeDepths: action.payload,
        drillholeDepths: action.payload,
      };
    }
    case types.ADD_DRILLHOLE_DEPTH: {
      const newItem = {
        ...action.payload,
        id: 0, // (0 - state.drillholeDepths.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.drillholeDepths];
      const isDrillholeDepthsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isDrillholeDepthsDirty,
        drillholeDepths: newData,
      };
    }
    case types.RESET_CALENDAR_TURNS_TEAMS: {
      return {
        ...state,
        isCalendarTurnsTeamsDirty: false,
        calendarTurnsTeams: [],
      };
    }
    case types.SET_CALENDAR_TURNS_TEAMS: {
      return {
        ...state,
        isCalendarTurnsTeamsDirty: false,
        calendarTurnsTeams: action.payload,
        originalCalendarTurnsTeams: action.payload,
      };
    }
    case types.SET_CALENDAR_TURN_TEAM: {
      const {
        id_turn_group: turnGroup,
        id_team_group: teamGroup,
        day_off: dayOff = false,
        update_status: updateStatus,
      } = action.payload;
      const existingCalendar = state.calendarTurnsTeams.find(
        c => c.id_turn_group === turnGroup
          && c.id_team_group === teamGroup
          && c.day_off === dayOff,
      );
      let newCalendar = {};

      if (existingCalendar && existingCalendar.id) {
        newCalendar = {
          ...existingCalendar,
          day_off: dayOff,
          ...action.payload,
          update_status: updateStatus || 'U',
        };
      } else {
        newCalendar = {
          day_off: dayOff,
          ...action.payload,
          update_status: 'I',
        };
      }
      const newData = [
        ...state.calendarTurnsTeams.filter(
          c => (c.id_turn_group !== turnGroup && c.id_team_group !== teamGroup)
            || c.day_off === true,
        ),
        newCalendar,
      ];

      const isCalendarTurnsTeamsDirty = newData.some(i => i.update_status);
      return {
        ...state,
        isCalendarTurnsTeamsDirty,
        calendarTurnsTeams: newData,
      };
    }
    case types.SET_DAYS_OFF: {
      const {
        id_turn_group: turnGroup,
        id_team_group: teamGroup,
      } = action.payload[0];
      const existing = state.calendarTurnsTeams.find(
        c => c.id_turn_group === turnGroup
          && c.id_team_group === teamGroup
          && c.day_off === false,
      );

      const newItems = action.payload
        .filter(
          d => (d.update_status && d.update_status !== 'D')
            || (d.update_status === 'D' && d.id > 0),
        )
        .map((m) => {
          const { update_status: updateStatus } = m;
          const newItem = {
            ...m,
            update_status: updateStatus || (m.id > 0 ? 'U' : 'I'),
          };

          if (existing) {
            newItem.color = existing.color;
          }

          newItem.start_date = formatDate(newItem.start_date, true);
          newItem.end_date = formatDate(newItem.end_date, true);
          return newItem;
        });
      const existingValidDays = [
        ...state.calendarTurnsTeams.filter(
          d => !d.day_off || (d.day_off && !d.update_status),
        ),
      ];
      const newData = [...existingValidDays, ...newItems];

      const isCalendarTurnsTeamsDirty = newData.some(i => i.update_status);
      return {
        ...state,
        isCalendarTurnsTeamsDirty,
        calendarTurnsTeams: newData,
      };
    }
    case types.SET_ELEMENTS_OD_LIST: {
      return {
        ...state,
        isElementsOdDirty: false,
        originalElementsOd: action.payload,
        elementsOd: action.payload,
      };
    }
    case types.RESET_ELEMENTS_OD: {
      return {
        ...state,
        isElementsOdDirty: false,
        elementsOd: [...state.originalElementsOd],
      };
    }
    case types.SET_ELEMENTS_OD_VALUES: {
      const { id } = action.payload;
      const currentElement = state.elementsOd.find(s => s.id === id);

      const newItem = {
        ...currentElement,
        ...action.payload,
        update_status: 'U',
      };

      const newData = [newItem, ...state.elementsOd.filter(s => s.id !== id)];

      const isElementsOdDirty = newData.some(d => d.update_status);

      return {
        ...state,
        isElementsOdDirty,
        elementsOd: newData,
      };
    }
    case types.SET_ELEMENT_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.elements, newItem, 'id');
      const isElementsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isElementsDirty,
        elements: newData,
      };
    }
    case types.RESET_ELEMENTS: {
      return {
        ...state,
        isElementsDirty: false,
        elements: [...state.originalElements],
      };
    }
    case types.SET_SUBELEMENT_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.subElements, newItem, 'id');
      const isSubElementsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isSubElementsDirty,
        subElements: newData,
      };
    }
    case types.RESET_SUBELEMENTS: {
      return {
        ...state,
        isSubElementsDirty: false,
        subElements: [...state.originalSubElements],
      };
    }
    case types.SET_OPERATION_TYPE_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.operationTypes, newItem, 'id');
      const isOperationTypesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isOperationTypesDirty,
        operationTypes: newData,
      };
    }
    case types.ADD_OPERATION_TYPE: {
      const newItem = {
        ...action.payload,
        id: 0 - state.operationTypes.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.operationTypes];
      const isOperationTypesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isOperationTypesDirty,
        operationTypes: newData,
      };
    }
    case types.RESET_OPERATION_TYPES: {
      return {
        ...state,
        isOperationTypesDirty: false,
        operationTypes: [...state.originalOperationTypes],
      };
    }
    case types.SET_OPERATION_TYPES_LIST: {
      return {
        ...state,
        isOperationTypesDirty: false,
        originalOperationTypes: action.payload,
        operationTypes: action.payload,
      };
    }
    case types.SET_OPERATION_TYPE_ACTIVITIES_LIST: {
      return {
        ...state,
        operationTypeActivities: action.payload,
      };
    }
    case types.SET_TRANSPORT_OPERATION_TYPE_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !action.payload.id < 1,
      };
      const newData = remapData(state.transportOperationTypes, newItem, 'id');
      const isTransportOperationTypesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTransportOperationTypesDirty,
        transportOperationTypes: newData,
      };
    }
    case types.SET_TRANSPORT_OPERATION_TYPES_LIST: {
      const newData = action.payload.map(item => ({
        ...item,
        id_equipment_type: 2,
        active_name: item.active ? i18n.t('common:Active') : i18n.t('common:Inactive'),
        equipment_type_name: i18n.t('common:TruckEquipment'),
      }));
      return {
        ...state,
        isTransportOperationTypesDirty: false,
        originalTransportOperationTypes: action.payload,
        transportOperationTypes: newData,
      };
    }
    case types.RESET_TRANSPORT_OPERATION_TYPES: {
      const newData = state.originalTransportOperationTypes.map(item => ({
        ...item,
        id_equipment_type: 2,
        active_name: item.active ? i18n.t('common:Active') : i18n.t('common:Inactive'),
        equipment_type_name: i18n.t('common:TruckEquipment'),
      }));
      return {
        ...state,
        isTransportOperationTypesDirty: false,
        transportOperationTypes: [...newData],
      };
    }
    case types.SET_TRACTOR_PRODUCTIVITY_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.tractorProductivities, newItem, 'id');
      const isTractorProductivitiesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTractorProductivitiesDirty,
        tractorProductivities: newData,
      };
    }
    case types.ADD_TRACTOR_PRODUCTIVITY: {
      const newItem = {
        ...action.payload,
        id: 0 - state.tractorProductivities.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.tractorProductivities];
      const isTractorProductivitiesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isTractorProductivitiesDirty,
        tractorProductivities: newData,
      };
    }
    case types.RESET_TRACTOR_PRODUCTIVITIES: {
      return {
        ...state,
        isTractorProductivitiesDirty: false,
        tractorProductivities: [...state.originalTractorProductivities],
      };
    }
    case types.SET_TRACTOR_PRODUCTIVITIES_LIST: {
      return {
        ...state,
        isTractorProductivitiesDirty: false,
        originalTractorProductivities: action.payload,
        tractorProductivities: action.payload,
      };
    }
    case types.SET_EXCEPTION_TYPES_LIST: {
      return {
        ...state,
        exceptionTypes: action.payload,
      };
    }
    case types.SET_SLEEP_LEVEL_LIST: {
      return {
        ...state,
        sleepLevels: action.payload,
      };
    }
    case types.SET_FANDRILL_FAN_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.fandrillFans, newItem, 'id');
      const isFandrillFansDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isFandrillFansDirty,
        fandrillFans: newData,
      };
    }
    case types.ADD_FANDRILL_FAN: {
      const newItem = {
        ...action.payload,
        id: 0 - state.fandrillFans.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.fandrillFans];
      const isFandrillFansDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isFandrillFansDirty,
        fandrillFans: newData,
      };
    }
    case types.RESET_FANDRILL_FANS: {
      return {
        ...state,
        isFandrillFansDirty: false,
        fandrillFans: [...state.originalFandrillFans],
      };
    }
    case types.SET_FANDRILL_FAN_LIST: {
      return {
        ...state,
        isFandrillFansDirty: false,
        originalFandrillFans: action.payload,
        fandrillFans: action.payload,
      };
    }
    // bitdiametername
    case types.SET_FANDRILL_BIT_DIAMETER_NAME_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.fandrillBitDiametersNames, newItem, 'id');
      const isFandrillBitDiametersNamesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isFandrillBitDiametersNamesDirty,
        fandrillBitDiametersNames: newData,
      };
    }
    case types.ADD_FANDRILL_BIT_DIAMETER_NAME: {
      const newItem = {
        ...action.payload,
        id: 0 - state.fandrillBitDiametersNames.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.fandrillBitDiametersNames];
      const isFandrillBitDiametersNamesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isFandrillBitDiametersNamesDirty,
        fandrillBitDiametersNames: newData,
      };
    }
    case types.RESET_FANDRILL_BIT_DIAMETERS_NAMES: {
      return {
        ...state,
        isFandrillBitDiametersNamesDirty: false,
        fandrillBitDiametersNames: [...state.originalFandrillBitDiametersNames],
      };
    }
    case types.SET_FANDRILL_BIT_DIAMETERS_NAMES_LIST: {
      return {
        ...state,
        isFandrillBitDiametersNamesDirty: false,
        originalFandrillBitDiametersNames: action.payload,
        fandrillBitDiametersNames: action.payload,
      };
    }
    //
    case types.SET_HOURMETER_NAMES_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.hourmeterNames, newItem, 'id');
      const isHourmeterNamesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isHourmeterNamesDirty,
        hourmeterNames: newData,
      };
    }
    case types.ADD_HOURMETER_NAME: {
      const newItem = {
        ...action.payload,
        id: 0 - state.hourmeterNames.length,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.hourmeterNames];
      const isHourmeterNamesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isHourmeterNamesDirty,
        hourmeterNames: newData,
      };
    }
    case types.RESET_HOURMETER_NAMES: {
      return {
        ...state,
        isHourmeterNamesDirty: false,
        hourmeterNames: [...state.originalHourmeterNames],
      };
    }
    case types.SET_HOURMETER_NAMES_LIST: {
      return {
        ...state,
        isHourmeterNamesDirty: false,
        originalHourmeterNames: action.payload,
        hourmeterNames: action.payload,
      };
    }
    case types.SET_FANDRILL_BIT_DIAMETER_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.fandrillBitDiameters, newItem, 'id');
      const isFandrillBitDiametersDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isFandrillBitDiametersDirty,
        fandrillBitDiameters: newData,
      };
    }
    case types.RESET_FANDRILL_BIT_DIAMETERS: {
      return {
        ...state,
        isFandrillBitDiametersDirty: false,
        fandrillBitDiameters: [...state.originalFandrillBitDiameters],
      };
    }
    case types.SET_FANDRILL_BIT_DIAMETERS_LIST: {
      return {
        ...state,
        isFandrillBitDiametersDirty: false,
        originalFandrillBitDiameters: action.payload,
        fandrillBitDiameters: action.payload
          .map(bitDiameter => ({ ...bitDiameter, name: bitDiameter.diameter })),
      };
    }
    case types.ADD_FANDRILL_BIT_DIAMETER: {
      const newItem = {
        ...action.payload,
        id: 0,
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.fandrillBitDiameters];
      const isFandrillBitDiametersDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isFandrillBitDiametersDirty,
        fandrillBitDiameters: newData,
      };
    }
    case types.SET_LOAD_FACTOR_LIST: {
      return {
        ...state,
        isLoadFactorsDirty: false,
        originalLoadFactors: action.payload,
        loadFactors: action.payload,
      };
    }
    case types.SET_LOAD_FACTOR_VALUE: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.loadFactors, newItem, 'id');
      const isLoadFactorsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isLoadFactorsDirty,
        loadFactors: newData,
      };
    }
    case types.ADD_LOAD_FACTOR: {
      const newItem = {
        ...action.payload,
        id: (0 - state.loadFactors.length),
        update_status: 'I',
        active: 1,
        isDirty: true,
      };
      const newData = [newItem, ...state.loadFactors];
      const isLoadFactorsDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isLoadFactorsDirty,
        loadFactors: newData,
      };
    }
    case types.RESET_LOAD_FACTOR: {
      return {
        ...state,
        isLoadFactorsDirty: false,
        loadFactors: [...state.originalLoadFactors],
      };
    }
    case types.WEB_USERS_LIST: {
      return {
        ...state,
        isWebUsersDirty: false,
        webUsers: [...action.payload],
        originalWebUsers: [...action.payload],
      };
    }
    case types.SET_WEB_USERS_VALUES: {
      const newItem = {
        ...action.payload,
        isDirty: !(action.payload.id < 1),
      };
      const newData = remapData(state.webUsers, newItem, 'id');
      const isWebUsersDirty = newData.some(d => d.update_status);
      return {
        ...state,
        isWebUsersDirty,
        webUsers: newData,
      };
    }
    case types.RESET_WEB_USERS: {
      return {
        ...state,
        isWebUsersDirty: false,
        webUsers: [...state.originalWebUsers],
      };
    }
    case types.ADD_WEB_USERS: {
      return {
        ...state,
        isWebUsersDirty: true,
        webUsers: [...state.webUsers, action.payload],
      };
    }
    case types.RESET_WEB_USERS_PASSWORD: {
      const newData = remapData(state.webUsers, action.payload, 'id');
      return {
        ...state,
        webUsers: newData,
      };
    }
    case types.SET_ACTIVITY_TYPES_CHECKLIST_LIST: {
      return {
        ...state,
        originalActivityTypesChecklist: action.payload,
        activityTypesChecklist: action.payload,
        isActivityTypeChecklistDirty: false,
      };
    }
    case types.SET_ACTIVITY_TYPES_CHECKLIST_VALUES: {
      const newItem = { ...action.payload, isDirty: !(action.payload.id < 1) };
      const newData = remapData(state.activityTypesChecklist, newItem, 'id');
      const isActivityTypeChecklistDirty = newData.some(d => d.update_status);
      return {
        ...state,
        activityTypesChecklist: newData,
        isActivityTypeChecklistDirty,
      };
    }
    case types.RESET_ACTIVITY_TYPES_CHECKLIST: {
      return {
        ...state,
        activityTypesChecklist: state.originalActivityTypesChecklist,
        isActivityTypeChecklistDirty: false,
      };
    }
    case types.ADD_ACTIVITY_TYPES_CHECKLIST: {
      const activityTypesChecklist = [
        ...state.activityTypesChecklist,
        { ...action.payload, update_status: 'I' },
      ];
      return {
        ...state,
        activityTypesChecklist,
        isActivityTypeChecklistDirty: true,
      };
    }
    case types.SET_ACTIVITY_ITEMS_CHECKLIST_LIST: {
      return {
        ...state,
        originalActivityItemsChecklist: action.payload,
        activityItemsChecklist: action.payload,
        isActivityItemsChecklistDirty: false,
      };
    }
    case types.SET_ACTIVITY_ITEMS_CHECKLIST_VALUES: {
      const newItem = { ...action.payload, isDirty: !(action.payload.id < 1) };
      const newData = remapData(state.activityItemsChecklist, newItem, 'id');
      const isActivityItemsChecklistDirty = newData.some(d => d.update_status);
      return {
        ...state,
        activityItemsChecklist: newData,
        isActivityItemsChecklistDirty,
      };
    }
    case types.RESET_ACTIVITY_ITEMS_CHECKLIST: {
      return {
        ...state,
        activityItemsChecklist: state.originalActivityItemsChecklist,
        isActivityItemsChecklistDirty: false,
      };
    }
    case types.ADD_ACTIVITY_ITEMS_CHECKLIST: {
      const activityItemsChecklist = [
        ...state.activityItemsChecklist,
        { ...action.payload, update_status: 'I' },
      ];
      return {
        ...state,
        activityItemsChecklist,
        isActivityItemsChecklistDirty: true,
      };
    }
    case types.SET_MIGRATION_RULES_LIST: {
      return {
        ...state,
        originalCodeMigrationRules: action.payload,
        codeMigrationRules: action.payload,
        isCodeMigrationRulesDirty: false,
      };
    }
    case types.ADD_MIGRATION_RULE: {
      const codeMigrationRules = [
        ...state.codeMigrationRules,
        { ...action.payload, update_status: 'I' },
      ];
      return {
        ...state,
        codeMigrationRules,
        isCodeMigrationRulesDirty: true,
      };
    }
    case types.RESET_MIGRATION_RULES: {
      return {
        ...state,
        codeMigrationRules: state.originalCodeMigrationRules,
        isCodeMigrationRulesDirty: false,
      };
    }
    case types.SET_MIGRATION_RULES_VALUES: {
      const newItem = { ...action.payload, isDirty: (action.payload.id >= 1) };
      const newData = remapData(state.codeMigrationRules, newItem, 'id');
      const isCodeMigrationRulesDirty = newData.some(d => d.update_status);
      return {
        ...state,
        codeMigrationRules: newData,
        isCodeMigrationRulesDirty,
      };
    }
    case types.SET_FILE_UPLOAD_ERRORS: {
      return {
        ...state,
        fileUploadErrors: action.payload,
      };
    }
    case types.RESET_FILE_UPLOAD_ERRORS: {
      return {
        ...state,
        fileUploadErrors: [],
      };
    }
    case types.SET_CLOSURES_LIST: {
      return {
        ...state,
        closures: action.payload,
      };
    }
    default:
      return state;
  }
}
