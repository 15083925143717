import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getTurnsGroups,
  getTurns,
  setTurnValue,
  updateTurns,
  addTurn,
  resetTurns,
} from '~/store/manager/actions';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';

const TurnsTab = ({
  classes,
  turns,
  turnsGroups,
  getTurns: getAll,
  getTurnsGroups: loadTurnsGroups,
  setTurnValue: updateItem,
  updateTurns: update,
  addTurn: add,
  resetTurns: reset,
  isTurnsDirty,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [successDialogMessage, setSuccessDialogMessage] = useState('');

  useEffect(() => {
    async function fetchData() {
      loadTurnsGroups();
      await getAll(true);
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, loadTurnsGroups]);

  const warningPopup = () => {
    const message = (
      `${translate('validation:CompleteTheRegistrationMenuTabs')}`
    );
    setSuccessDialogMessage(message);
    setShowSuccessDialog(true);
  };

  const columns = [
    {
      field: 'turn_group_name', title: translate('common:ShiftGroup'),
    },
    {
      field: 'name', title: translate('common:Name'), width: '100%', editable: true, updateOnChange: true,
    },
    { field: 'active', title: 'STATUS', editable: true },
  ];
  const renderHeader = () => (
    <Formik
      initialValues={{ name: '', turnGroupId: 0 }}
      validate={(values) => {
        const errors = {};
        if (!values.name) {
          errors.name = 'Required';
        }
        if (values.turnGroupId <= 0) {
          errors.turnGroupId = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        const { turnGroupId, name } = values;
        const turnGroup = turnsGroups.find(t => t.id === turnGroupId);
        const newItem = {
          name,
          id_turn_group: turnGroupId,
          turn_group_name: turnGroup.name,
        };

        await add(newItem);
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form style={{ display: 'flex', alignItems: 'center' }}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:ShiftGroup')}:`}
            </FormLabel>
            <FormGroup>
              <Field
                name="turnGroupId"
                render={({ field, form }) => (
                  <PF2MSearchSelectOutlined
                    {...field}
                    placeholder={null}
                    style={{
                      color: '#647886',
                      width: 290,
                      borderWidth: 0,
                    }}
                    onChange={e => form.setFieldValue('turnGroupId', e.target.value)}
                  >
                    {[{ value: 0, label: translate('common:Select') }]
                      .concat(...turnsGroups
                        .map(e => ({ value: e.id, label: e.name })))}
                  </PF2MSearchSelectOutlined>
                )}
              />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.turnName}>
            <FormLabel className={classes.formLabel}>
              {translate('common:Name')}
            </FormLabel>
            <FormGroup>
              <Field
                type="text"
                name="name"
                className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
              />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <PF2MAddButton disabled={isSubmitting || !isValid} />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={turns}
        columns={columns}
        updateItem={updateItem}
      />
      <PF2MAlertDialog
        title={translate('common:RegistrationSteps')}
        description={
          <div style={styles.descriptionStyle}>{successDialogMessage}</div>
        }
        open={showSuccessDialog}
        onConfirm={() => {
          setShowSuccessDialog(false);
        }}
        onClose={() => setShowSuccessDialog(false)}
        hasCancel={false}
        badge
      />
      <Footer
        isDirty={isTurnsDirty}
        discard={() => reset()}
        sendData={() => {
          update(turns);
          warningPopup();
        }}
      />
    </div>
  );
};

TurnsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  turns: PropTypes.array,
  getTurns: PropTypes.func,
  turnsGroups: PropTypes.array,
  getTurnsGroups: PropTypes.func,
  setTurnValue: PropTypes.func,
  updateTurns: PropTypes.func,
  addTurn: PropTypes.func,
  resetTurns: PropTypes.func,
  isTurnsDirty: PropTypes.bool,
};

TurnsTab.defaultProps = {
  turns: [],
  getTurns: null,
  turnsGroups: [],
  getTurnsGroups: null,
  setTurnValue: null,
  updateTurns: null,
  addTurn: null,
  resetTurns: null,
  isTurnsDirty: false,
};

const mapStateToProps = state => ({
  turns: state.manager.turns,
  turnsGroups: state.manager.turnsGroups,
  isTurnsDirty: state.manager.isTurnsDirty,
});

const mapDispatchToProps = {
  getTurnsGroups,
  getTurns,
  setTurnValue,
  updateTurns,
  addTurn,
  resetTurns,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TurnsTab));
