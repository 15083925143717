import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import styles from '../styles';
import Header from '~/pages/Manager/Header';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import { useTranslation } from 'react-i18next';

import {
  getEquipmentsGroups,
  getEquipmentsGroupsTemplate,
  exportEquipmentsGroups,
  importEquipmentsGroups,
  resetFileUploadErrors,
} from '~/store/manager/actions';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';

const EquipmentsGroups = ({
  classes,
  equipmentsGroups,
  getEquipmentsGroups: getAll,
  getEquipmentsGroupsTemplate: getTemplateAction,
  exportEquipmentsGroups: exportAction,
  importEquipmentsGroups: importAction,
}) => {
  const dispatch = useDispatch();

  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();
  const [dialogMessage, setDialogMessage] = useState('');
  const [showDialogImport, setShowDialogImport] = useState(false);
  const fileUploadErrors = useSelector(state => state.manager.fileUploadErrors);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [successDialogMessage, setSuccessDialogMessage] = useState('');
  const fileUploadSuccess = useSelector(state => state.errors.fileUploadSuccess);

  useEffect(() => {
    async function fetchData() {
      await getAll();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll]);

  const warningPopup = () => {
    const message = (
      <>
        {translate('validation:CompleteTheRegistration')}
        <ul style={styles.listStyle}>
          <li>{translate('common:CheckList')}</li>
          <li>{translate('common:Hourmeter')}</li>
          <li>
            {translate('common:AverageLoad')}
            {' '}
            (
            {translate('common:TypesCargo')}
            )
          </li>
          <li>
            {translate('common:DrillingInformation')}
            {' '}
            (
            {translate('common:DrillingEquipment')}
            )
          </li>
        </ul>
      </>
    );
    setSuccessDialogMessage(message);
    setShowSuccessDialog(true);
  };

  useEffect(() => {
    if (fileUploadErrors && fileUploadErrors.length > 0) {
      const message = fileUploadErrors.map((error) => {
        const [rowCol, description] = error.split('-');
        const [row, col] = rowCol.split(';');
        return `${translate('validation:Row')} ${row} ${translate('validation:Col')} ${col} - ${translate(`validation:${description}`)}`;
      });
      setDialogMessage(message.join('\r\n'));
      setShowDialogImport(true);
      warningPopup();
    } else if (fileUploadSuccess === `${translate('validation:SheetImportSuccess')}`) {
      warningPopup();
    }
  }, [fileUploadErrors, fileUploadSuccess]);

  const columns = [
    { field: 'type_name', title: translate('common:Type') },
    { field: 'name', title: translate('common:Name') },
    { field: 'turn_group_name', title: translate('common:ShiftGroup') },
    {
      field: 'description',
      title: translate('common:Description'),
      width: '40%',
      wrap: true,
    },
    { field: 'mobile_workflow_number', title: translate('common:Workflow') },
    { field: 'velocity_limit', title: translate('common:VelocityLimit') },
    { field: 'active_name', title: 'STATUS' },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={getTemplateAction}
        importAction={importAction}
        exportAction={exportAction}
      />
      <PF2MTable
        data={equipmentsGroups}
        columns={columns}
      />
      <PF2MAlertDialog
        title={translate('validation:ImportErrors')}
        description={dialogMessage}
        open={showDialogImport}
        onConfirm={() => {
          setShowDialogImport(false);
          dispatch(resetFileUploadErrors());
        }}
        onClose={() => setShowDialogImport(false)}
        hasCancel={false}
      />
      <PF2MAlertDialog
        title={translate('common:RegistrationSteps')}
        description={
          <div style={styles.descriptionStyle}>{successDialogMessage}</div>
        }
        open={showSuccessDialog}
        onConfirm={() => {
          setShowSuccessDialog(false);
        }}
        onClose={() => setShowSuccessDialog(false)}
        hasCancel={false}
        badge
      />
      <Footer />
    </div>
  );
};

EquipmentsGroups.propTypes = {
  classes: PropTypes.object.isRequired,
  equipmentsGroups: PropTypes.array,
  getEquipmentsGroups: PropTypes.func,
  getEquipmentsGroupsTemplate: PropTypes.func,
  exportEquipmentsGroups: PropTypes.func,
  importEquipmentsGroups: PropTypes.func,
};

EquipmentsGroups.defaultProps = {
  equipmentsGroups: [],
  getEquipmentsGroups: () => {},
  getEquipmentsGroupsTemplate: () => {},
  exportEquipmentsGroups: () => {},
  importEquipmentsGroups: () => {},
};

const mapStateToProps = state => ({
  equipmentsGroups: state.manager.equipmentsGroups,
});

const mapDispatchToProps = {
  getEquipmentsGroups,
  getEquipmentsGroupsTemplate,
  exportEquipmentsGroups,
  importEquipmentsGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EquipmentsGroups));
