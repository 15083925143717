import React from 'react';
import { withStyles } from '@material-ui/core';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  deletePerforatorItem,
  setPerforatorValues,
  restoreOriginalPerforator,
  createPerforatorItem,
  updatePerforatorList,
} from '~/store/dailyparts/actions';
import PF2MDataTable from '~/components/PF2MDataTable';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import { defaultItem, filterPerforatorList } from './utils';
import {
  diffTwoDateTimeStr,
  parseDate,
  parseDateToTimestamp,
  subtractDaysFromNow,
  newMomentDate,
} from '~/utils/moment';
import usePlatformConfiguration, {
  usePlatformConfigurationUserAccessLevel,
} from '~/services/platformConfiguration';

const calculateDuration = row => diffTwoDateTimeStr(
  row.start_date,
  row.start_time,
  row.end_date,
  row.end_time,
) / 60000;

const durationIsValid = row => calculateDuration(row) >= 0;

const renderDuration = (row) => {
  const duration = calculateDuration(row);
  const color = durationIsValid(row) ? '' : 'red';
  return <span style={{ color }}>{duration.toFixed(2)}</span>;
};

const PERFORATOR_EQUIP_IDS = [4, 11, 16, 17, 18, 19];
const FANDRILL_EQUIPS = [16, 17, 18, 19];
const JUMBO = 11;
const ROCK_BOLT = 18;

const PerforatorList = () => {
  const { t: translate } = useTranslation();

  const perforatorList = useSelector(
    state => state.dailyParts.perforatorList,
  );
  const filters = useSelector(state => state.dailyParts.filters);
  const userAccessLevel = useSelector(state => state.auth.user.level);
  const turns = useSelector(state => state.manager.turns);
  const teams = useSelector(state => state.manager.teams);
  const perforatorGroups = useSelector(state => (
    state.manager.equipmentsGroups.filter(eg => PERFORATOR_EQUIP_IDS.includes(eg.id_equipament))
  ), shallowEqual);
  const perforators = useSelector(state => state.manager.equipments
    .filter(equip => PERFORATOR_EQUIP_IDS.includes(equip.id_equip)), shallowEqual);
  const operationTypes = useSelector(state => state.manager.operationTypes
    .filter(e => PERFORATOR_EQUIP_IDS.includes(e.id_equipment_type)), shallowEqual);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const operatorsGroups = useSelector(state => state.manager.operatorsGroups
    .filter(o => o.name), shallowEqual);
  const operators = useSelector(state => state.manager.operators.filter(o => o.name), shallowEqual);
  const materials = useSelector(state => state.manager.materials);
  const exceptionTypes = useSelector(state => state.manager.exceptionTypes);
  const drillholeTypes = useSelector(state => state.manager.drillholeTypes);
  const fireIds = useSelector(state => state.manager.fireIds.filter(o => o.name), shallowEqual);
  const fandrillFans = useSelector(state => state.manager.fandrillFans);
  const fandrillBitDiametersNames = useSelector(
    state => state.manager.fandrillBitDiametersNames,
  );
  const fandrillBitDiameters = useSelector(
    state => state.manager.fandrillBitDiameters,
  );

  const boomReasons = useSelector(state => state.manager.boomReasons);

  const closures = useSelector(state => state.manager.closures);
  const { value: userLimitEditConfig } = usePlatformConfigurationUserAccessLevel(userAccessLevel);
  const { value: enableClosure } = usePlatformConfiguration('enable_closure');

  const jumboHoleDepthCalc = ({ rod_depth: rodDepth, ...rowData }, keyToSearch) => {
    const nDrillholes = rowData[keyToSearch];
    if (!nDrillholes || !rodDepth) return 0;
    return rodDepth * nDrillholes;
  };

  const dispatch = useDispatch();

  const validateNumericField = key => (rowData) => {
    const value = rowData[key];

    if (['', NaN].includes(value) || !Number.isInteger(Number(value))) {
      return { isValid: false, helperText: translate('validation:RequiredField') };
    }

    return { isValid: true };
  };

  const validateDate = key => (row) => {
    const startDateTimestamp = parseDateToTimestamp(row.start_date, row.start_time);
    const endDateTimestamp = parseDateToTimestamp(row.end_date, row.end_time);

    const isEnableClosureOn = enableClosure && closures?.length > 0;
    let userLimitEdit = subtractDaysFromNow(userLimitEditConfig);

    if (isEnableClosureOn) {
      const dateNow = newMomentDate();
      const lastClosureDate = parseDate(closures[0].timestampClose, true);
      const currentDaysFromLastClosure = dateNow.diff(lastClosureDate, 'days');

      const lockEditConfig = subtractDaysFromNow(userLimitEditConfig);
      const lockEditLastClosure = subtractDaysFromNow(currentDaysFromLastClosure);

      userLimitEdit = currentDaysFromLastClosure > userLimitEditConfig
        ? lockEditConfig : lockEditLastClosure;
    }

    if (key === 'end_date' && startDateTimestamp > endDateTimestamp) {
      return { isValid: true, helperText: translate('validation:FinalDateSmallerThanInitialDate') };
    }

    const invalidEditPeriodDateMsg = {
      isValid: false, helperText: translate('validation:InvalidPeriodDate'),
    };

    if (key === 'start_date' && parseDate(row[key]).isBefore(userLimitEdit)) return invalidEditPeriodDateMsg;

    return { isValid: true };
  };

  const perforatorColumns = [
    {
      editable: 'always',
      title: translate('common:InitialDate'),
      field: 'start_date',
      customType: 'date',
      validate: validateDate('start_date'),
    },
    {
      editable: 'always',
      title: translate('common:FinalDate'),
      field: 'end_date',
      customType: 'date',
      validate: validateDate('end_date'),
    },
    {
      editable: 'always',
      title: translate('common:InitialHour'),
      field: 'start_time',
      customType: 'time',
      validate: validateDate('start_time'),
    },
    {
      editable: 'always',
      title: translate('common:FinalHour'),
      field: 'end_time',
      customType: 'time',
      validate: validateDate('end_time'),
    },
    {
      editable: 'always',
      title: translate('common:DrillingStartDate'),
      field: 'drilling_start_date',
    },
    {
      editable: 'always',
      title: translate('common:DrillingStartTime'),
      field: 'drilling_start_time',
    },
    {
      editable: 'never',
      title: translate('common:Duration'),
      field: 'duration',
      render: renderDuration,
      validate: durationIsValid,
    },
    {
      editable: 'always',
      title: translate('common:Shift'),
      field: 'turn',
      lookup: turns.map(e => ({
        id: e.id,
        name: `${e.turn_group_name} - ${e.name}`,
        active: e.active,
      })),
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Team'),
      field: 'team_id',
      lookup: teams,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:EquipmentGroup'),
      field: 'equip_group_id',
      lookup: perforatorGroups,
      customType: 'filterLookup',
      lookupFilter: option => String(option.id_equipament) === String(filters.equipmentType)
        || String(filters.equipmentType) === '0',
    },
    {
      editable: 'always',
      title: translate('common:Equipment'),
      field: 'equip_id',
      lookup: perforators,
      customType: 'filterLookup',
      lookupFilter: (option, row) => String(option.id_group) === String(row.equip_group_id)
        || String(row.equip_group_id) === '0',
    },
    {
      editable: 'always',
      title: translate('common:OperationType'),
      field: 'operation_type_id',
      lookup: operationTypes.filter(ot => [filters.equipmentType].includes(ot.id_equipment_type)),
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:OperatorGroup'),
      field: 'operator_group_id',
      lookup: operatorsGroups,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Operator'),
      field: 'operator_id',
      lookup: operators,
      lookupKey: 'id_operator',
      customType: 'filterLookup',
      lookupFilter: (option, row) => String(option.id_group) === String(row.operator_group_id)
        || String(row.operator_group_id) === '0',
    },
    {
      editable: 'always',
      title: translate('common:Origin'),
      customType: 'filterLookup',
      field: 'origin_id',
      lookup: elements,
    },
    {
      editable: 'always',
      title: translate('common:OriginPoint'),
      customType: 'filterLookup',
      field: 'point_id',
      lookupFilter: (option, row) => String(option.id_element) === String(row.origin_id)
        || String(row.origin_id) === '0',
      lookup: subElements,
    },
    {
      title: translate('common:Material'),
      field: 'material_id',
      customType: 'filterLookup',
      lookup: materials,
    },
    {
      editable: 'always',
      title: translate('common:FireID'),
      field: 'fire_id',
      lookup: fireIds,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:DrillingType'),
      field: 'drilling_type',
      lookup: drillholeTypes,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:BitDiameter'),
      field: 'fandrill_bit_diameter_id',
      lookup: fandrillBitDiameters,
      customType: 'filterLookup',
      lookupFilter: (option, row) => (
        String(option.equipment_group_id) === String(row.equip_group_id)
        || String(row.equip_group_id) === '0'
      ),
    },
    {
      editable: 'always',
      title: translate('common:BitDiameterName'),
      field: 'fandrill_bit_diameter_name_id',
      lookup: fandrillBitDiametersNames,
      lookupFilter: (option, row) => String(option.fandrill_bit_diameter_id)
        === String(row.fandrill_bit_diameter_id)
        || String(row.fandrill_bit_diameter_id) === '0',
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:DrillingCount'),
      field: 'n_drillholes',
    },
    {
      editable: 'always',
      title: translate('common:DrillingDepth'),
      field: 'hole_depth',
    },
    {
      editable: 'always',
      title: translate('common:HoleID'),
      field: 'hole_id',
    },
    {
      editable: 'always',
      title: translate('common:IdleTime'),
      field: 'idle_time',
    },
    {
      editable: 'always',
      title: translate('common:DrillingDuration'),
      field: 'drilling_time',
    },
    {
      editable: 'always',
      title: translate('common:Latitude'),
      field: 'X',
    },
    {
      editable: 'always',
      title: translate('common:Longitude'),
      field: 'Y',
    },
    {
      editable: 'always',
      title: translate('common:Altitude'),
      field: 'Z',
    },
    {
      editable: 'never',
      title: translate('common:EntryType'),
      field: 'exception_type',
      lookup: exceptionTypes,
      customType: 'filterLookup',
    },
  ];
  const fandrillColumns = [
    {
      editable: 'always',
      title: translate('common:InitialDate'),
      field: 'start_date',
      customType: 'date',
      validate: validateDate('start_date'),
    },
    {
      editable: 'always',
      title: translate('common:FinalDate'),
      field: 'end_date',
    },
    {
      editable: 'always',
      title: translate('common:InitialHour'),
      field: 'start_time',
    },
    {
      editable: 'always',
      title: translate('common:FinalHour'),
      field: 'end_time',
    },
    {
      title: translate('common:DrillingStartDate'),
      field: 'drilling_start_date',
    },
    {
      editable: 'always',
      title: translate('common:DrillingStartTime'),
      field: 'drilling_start_time',
    },
    {
      editable: 'never',
      title: translate('common:Duration'),
      field: 'duration',
      render: renderDuration,
      validate: durationIsValid,
    },
    {
      editable: 'always',
      title: translate('common:Shift'),
      field: 'turn',
      lookup: turns.map(e => ({
        id: e.id,
        name: `${e.turn_group_name} - ${e.name}`,
        active: e.active,
      })),
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Team'),
      field: 'team_id',
      lookup: teams,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:EquipmentGroup'),
      field: 'equip_group_id',
      lookup: perforatorGroups,
      customType: 'filterLookup',
      lookupFilter: option => String(option.id_equipament) === String(filters.equipmentType)
        || String(filters.equipmentType) === '0',
    },
    {
      editable: 'always',
      title: translate('common:Equipment'),
      field: 'equip_id',
      lookup: perforators,
      customType: 'filterLookup',
      lookupFilter: (option, row) => String(option.id_group) === String(row.equip_group_id)
        || String(row.equip_group_id) === '0',
    },
    {
      editable: 'always',
      title: translate('common:OperationType'),
      field: 'operation_type_id',
      lookup: operationTypes.filter(ot => [filters.equipmentType].includes(ot.id_equipment_type)),
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:OperatorGroup'),
      field: 'operator_group_id',
      lookup: operatorsGroups,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Operator'),
      field: 'operator_id',
      lookup: operators,
      customType: 'filterLookup',
      lookupKey: 'id_operator',
      lookupFilter: (option, row) => String(option.id_group) === String(row.operator_group_id)
        || String(row.operator_group_id) === '0',
    },
    {
      editable: 'always',
      title: translate('common:Origin'),
      customType: 'filterLookup',
      field: 'origin_id',
      lookup: elements,
    },
    {
      editable: 'always',
      title: translate('common:OriginPoint'),
      customType: 'filterLookup',
      field: 'point_id',
      lookupFilter: (option, row) => String(option.id_element) === String(row.origin_id)
        || String(row.origin_id) === '0',
      lookup: subElements,
    },
    {
      editable: 'always',
      title: translate('common:Material'),
      field: 'material_id',
      customType: 'filterLookup',
      lookup: materials,
    },
    {
      editable: 'always',
      title: translate('common:FireID'),
      field: 'fire_id',
      lookup: fireIds,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:DrillingType'),
      field: 'drilling_type',
      lookup: drillholeTypes,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Fan'),
      field: 'fandrill_fan_id',
      lookup: fandrillFans,
      customType: 'filterLookup',
      lookupFilter: (option, row) => (
        String(option.equipment_group_id) === String(row.equip_group_id)
        || String(row.equip_group_id) === '0'
      ),
    },
    {
      editable: 'always',
      title: translate('common:HolePosition'),
      field: 'rod_position',
    },
    {
      editable: 'always',
      title: translate('common:BitDiameter'),
      field: 'fandrill_bit_diameter_id',
      lookup: fandrillBitDiameters,
      customType: 'filterLookup',
      lookupFilter: (option, row) => (
        String(option.id_group) === String(row.equipment_group_id)
        || String(row.equipment_group_id) === '0'
      ),
    },
    {
      editable: 'always',
      title: translate('common:RodFootage'),
      field: 'rod_depth',
    },
    {
      editable: 'always',
      title: translate('common:DrillingCount'),
      field: 'n_drillholes',
    },
    {
      editable: 'always',
      title: translate('common:RodNumber'),
      field: 'rod_number',
    },

    {
      editable: 'always',
      title: translate('common:DrillingDepth'),
      field: 'hole_depth',
    },
    {
      editable: 'always',
      title: translate('common:IdleTime'),
      field: 'idle_time',
    },
    {
      editable: 'always',
      title: translate('common:DrillingDuration'),
      field: 'drilling_time',
    },
    {
      editable: 'always',
      title: translate('common:Latitude'),
      field: 'X',
    },
    {
      editable: 'always',
      title: translate('common:Longitude'),
      field: 'Y',
    },
    {
      editable: 'always',
      title: translate('common:Altitude'),
      field: 'Z',
    },
    {
      editable: 'never',
      title: translate('common:EntryType'),
      field: 'exception_type',
      lookup: exceptionTypes,
      customType: 'filterLookup',
    },
  ];
  const jumboColumns = [
    {
      editable: 'always',
      title: translate('common:InitialDate'),
      field: 'start_date',
      customType: 'date',
      validate: validateDate('start_date'),
    },
    {
      editable: 'always',
      title: translate('common:FinalDate'),
      field: 'end_date',
    },
    {
      editable: 'always',
      title: translate('common:InitialHour'),
      field: 'start_time',
    },
    {
      editable: 'always',
      title: translate('common:FinalHour'),
      field: 'end_time',
    },
    {
      editable: 'always',
      title: translate('common:DrillingStartDate'),
      field: 'drilling_start_date',
    },
    {
      editable: 'always',
      title: translate('common:DrillingStartTime'),
      field: 'drilling_start_time',
    },
    {
      editable: 'never',
      title: translate('common:Duration'),
      field: 'duration',
      render: renderDuration,
      validate: durationIsValid,
    },
    {
      editable: 'always',
      title: translate('common:Shift'),
      field: 'turn',
      lookup: turns.map(e => ({
        id: e.id,
        name: `${e.turn_group_name} - ${e.name}`,
        active: e.active,
      })),
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Team'),
      field: 'team_id',
      lookup: teams,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:EquipmentGroup'),
      field: 'equip_group_id',
      lookup: perforatorGroups,
      customType: 'filterLookup',
      lookupFilter: option => String(option.id_equipament) === String(filters.equipmentType)
        || String(filters.equipmentType) === '0',
    },
    {
      editable: 'always',
      title: translate('common:Equipment'),
      field: 'equip_id',
      lookup: perforators,
      customType: 'filterLookup',
      lookupFilter: (option, row) => String(option.id_group) === String(row.equip_group_id)
        || String(row.equip_group_id) === '0',
    },
    {
      editable: 'always',
      title: translate('common:OperationType'),
      field: 'operation_type_id',
      lookup: operationTypes.filter(ot => [filters.equipmentType].includes(ot.id_equipment_type)),
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:OperatorGroup'),
      field: 'operator_group_id',
      lookup: operatorsGroups,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Operator'),
      field: 'operator_id',
      lookup: operators,
      customType: 'filterLookup',
      lookupKey: 'id_operator',
      lookupFilter: (option, row) => String(option.id_group) === String(row.operator_group_id)
        || String(row.operator_group_id) === '0',
    },
    {
      editable: 'always',
      title: translate('common:Origin'),
      customType: 'filterLookup',
      field: 'origin_id',
      lookup: elements,
    },
    {
      editable: 'always',
      title: translate('common:OriginPoint'),
      customType: 'filterLookup',
      field: 'point_id',
      lookupFilter: (option, row) => String(option.id_element) === String(row.origin_id)
        || String(row.origin_id) === '0',
      lookup: subElements,
    },
    {
      editable: 'always',
      title: translate('common:Material'),
      customType: 'filterLookup',
      field: 'material_id',
      lookup: materials,
    },
    {
      editable: 'always',
      title: translate('common:FireID'),
      field: 'fire_id',
      lookup: fireIds,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:DrillingType'),
      field: 'drilling_type',
      lookup: drillholeTypes,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:RodFootage'),
      field: 'rod_depth',
    },
    {
      editable: 'always',
      title: translate('common:NumOfBoons'),
      field: 'num_of_booms',
      type: 'numeric',
    },
    {
      editable: 'always',
      title: translate('common:BitDiameter'),
      field: 'fandrill_bit_diameter_id',
      lookup: fandrillBitDiameters,
      customType: 'filterLookup',
      lookupFilter: (option, row) => (
        String(option.equipment_group_id) === String(row.equip_group_id)
        || String(row.equip_group_id) === '0'
      ),
    },
    {
      editable: 'always',
      title: translate('common:BitDiameterName'),
      field: 'fandrill_bit_diameter_name_id',
      lookup: fandrillBitDiametersNames,
      customType: 'filterLookup',
      lookupFilter: (option, row) => (
        String(option.fandrill_bit_diameter_id) === String(row.fandrill_bit_diameter_id)
        || String(row.equip_group_id) === '0'
      ),
    },
    {
      editable: 'always',
      title: translate('common:BitDiameterSecond'),
      field: 'fandrill_bit_diameter_id_second',
      lookup: fandrillBitDiameters,
      customType: 'filterLookup',
      lookupFilter: (option, row) => (
        String(option.equipment_group_id) === String(row.equip_group_id)
        || String(row.equip_group_id) === '0'
      ),
    },
    {
      editable: 'always',
      title: translate('common:BitDiameterNameSecond'),
      field: 'fandrill_bit_diameter_name_id_second',
      lookup: fandrillBitDiametersNames,
      customType: 'filterLookup',
      lookupFilter: (option, row) => (
        String(option.fandrill_bit_diameter_id) === String(row.fandrill_bit_diameter_id_second)
        || String(row.equip_group_id) === '0'
      ),
    },
    {
      editable: 'always',
      title: translate('common:DrillingCount'),
      field: 'n_drillholes',
    },
    {
      editable: 'always',
      title: translate('common:DrillingCountSecond'),
      field: 'n_drillholes_second',
    },
    {
      editable: 'never',
      title: translate('common:DrillingDepth'),
      field: 'hole_depth',
      render: rowData => jumboHoleDepthCalc(rowData, 'n_drillholes'),
    },
    {
      editable: 'never',
      title: translate('common:DrillingDepthSecond'),
      field: 'hole_depth_second',
      render: rowData => jumboHoleDepthCalc(rowData, 'n_drillholes_second'),
    },
    {
      editable: 'always',
      title: translate('common:IdleTime'),
      field: 'idle_time',
    },
    {
      editable: 'always',
      title: translate('common:DrillingDuration'),
      field: 'drilling_time',
    },
    {
      editable: 'always',
      title: translate('common:Latitude'),
      field: 'X',
    },
    {
      editable: 'always',
      title: translate('common:Longitude'),
      field: 'Y',
    },
    {
      editable: 'always',
      title: translate('common:Altitude'),
      field: 'Z',
    },
    {
      editable: 'never',
      title: translate('common:EntryType'),
      field: 'exception_type',
      lookup: exceptionTypes,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Reason'),
      field: 'use_partial_boom_reason_id',
      lookup: [
        { id: 0, name: translate('common:None') },
        ...boomReasons.map(e => ({
          id: e.id,
          name: e.reason,
        })),
      ],
      customType: 'filterLookup',
    },

  ];

  const customRockBoltColumns = [
    {
      editable: 'always',
      title: translate('common:ScreenCount'),
      field: 'screens_number',
    },
    {
      editable: 'always',
      title: translate('common:NumberOfArches'),
      field: 'arches_number',
      type: 'numeric',
      validate: validateNumericField('arches_number'),
      required: true,
    },
    {
      editable: 'always',
      title: translate('common:NumberOfLatticeGirders'),
      field: 'lattice_girder_number',
      type: 'numeric',
      validate: validateNumericField('lattice_girder_number'),
      required: true,
    },
  ];

  const setColumns = () => {
    let columnList = perforatorColumns;
    if (FANDRILL_EQUIPS.includes(filters.equipmentType)) {
      columnList = fandrillColumns;
      if (filters.equipmentType === ROCK_BOLT) {
        columnList.splice(26, 0, ...customRockBoltColumns);
      }
    }
    if (JUMBO === filters.equipmentType) columnList = jumboColumns;

    return columnList;
  };

  return (
    <>
      <PF2MDataTable
        title={translate('common:Cycles')}
        options={{
          showTitle: true,
          search: true,
          selection: true,
          cacheKey: 'daily_parts_perforator',
        }}
        onAdd={(newData) => {
          dispatch(createPerforatorItem(newData));
        }}
        onDiscard={() => {
          dispatch(restoreOriginalPerforator());
        }}
        onApply={() => {
          let filteredDataList = [...perforatorList];
          filteredDataList = filterPerforatorList(filteredDataList);

          dispatch(updatePerforatorList(filteredDataList, filters));
        }}
        onChange={(newData) => {
          newData.forEach(({ ...row }) => {
            dispatch(
              setPerforatorValues({
                ...row,
                duration: calculateDuration(row),
              }),
            );
          });
        }}
        onDelete={(newData) => {
          newData.forEach((e) => {
            dispatch(deletePerforatorItem(e));
          });
        }}
        initialFormData={{
          ...perforatorColumns.reduce((acc, e) => ({ ...acc, [e.field]: '0' }), {}),
          ...defaultItem,
        }}
        columns={setColumns()}
        data={perforatorList.length ? perforatorList : []}
      />
    </>
  );
};

export default withStyles(styles)(PerforatorList);
