import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getTurnsGroups,
  setTurnGroupValue,
  updateTurnsGroups,
  addTurnsGroup,
  resetTurnsGroups,
} from '~/store/manager/actions';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';

const TurnGroupsTab = ({
  classes,
  turnsGroups,
  getTurnsGroups: getAll,
  setTurnGroupValue: updateItem,
  updateTurnsGroups: update,
  addTurnsGroup: add,
  resetTurnsGroups: reset,
  isTurnsGroupDirty,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [successDialogMessage, setSuccessDialogMessage] = useState('');

  useEffect(() => {
    async function fetchData() {
      await getAll(true);
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll]);

  const warningPopup = () => {
    const message = (
      `${translate('validation:CompleteTheRegistrationMenuTabs')}`
    );
    setSuccessDialogMessage(message);
    setShowSuccessDialog(true);
  };

  const columns = [
    {
      field: 'name', title: translate('common:Name'), editable: true, updateOnChange: true,
    },
    {
      field: 'description', title: translate('common:Description'), width: '100%', editable: true, updateOnChange: true,
    },
    {
      field: 'active', title: 'STATUS', editable: true,
    },
  ];

  const renderHeader = () => (
    <Formik
      initialValues={{ name: '', description: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.name) {
          errors.name = 'Required';
        }
        if (!values.description) {
          errors.description = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        await add(values);
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form style={{ display: 'flex', alignItems: 'center' }}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:ShiftGroup')}:`}
            </FormLabel>
            <FormGroup>
              <Field type="text" name="name" className={classnames('form-control', errors.name ? classes.fieldError : classes.field)} />
            </FormGroup>
          </FormControl>
          <FormControl className={classes.turnName}>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Name')}:`}
            </FormLabel>
            <FormGroup>
              <Field type="text" name="description" className={classnames('form-control', errors.description ? classes.fieldError : classes.field)} />
            </FormGroup>
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <PF2MAddButton disabled={isSubmitting || !isValid} />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={turnsGroups}
        columns={columns}
        updateItem={updateItem}
      />
      <PF2MAlertDialog
        title={translate('common:RegistrationSteps')}
        description={
          <div style={styles.descriptionStyle}>{successDialogMessage}</div>
        }
        open={showSuccessDialog}
        onConfirm={() => {
          setShowSuccessDialog(false);
        }}
        onClose={() => setShowSuccessDialog(false)}
        hasCancel={false}
        badge
      />
      <Footer
        isDirty={isTurnsGroupDirty}
        discard={() => reset()}
        sendData={() => {
          update(turnsGroups);
          warningPopup();
        }}
      />
    </div>
  );
};

TurnGroupsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  turnsGroups: PropTypes.array,
  getTurnsGroups: PropTypes.func,
  setTurnGroupValue: PropTypes.func,
  updateTurnsGroups: PropTypes.func,
  addTurnsGroup: PropTypes.func,
  resetTurnsGroups: PropTypes.func,
  isTurnsGroupDirty: PropTypes.bool,
};

TurnGroupsTab.defaultProps = {
  turnsGroups: [],
  getTurnsGroups: null,
  setTurnGroupValue: null,
  updateTurnsGroups: null,
  addTurnsGroup: null,
  resetTurnsGroups: null,
  isTurnsGroupDirty: false,
};

const mapStateToProps = state => ({
  turnsGroups: state.manager.turnsGroups,
  isTurnsGroupDirty: state.manager.isTurnsGroupsDirty,
});

const mapDispatchToProps = {
  getTurnsGroups,
  setTurnGroupValue,
  updateTurnsGroups,
  addTurnsGroup,
  resetTurnsGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TurnGroupsTab));
