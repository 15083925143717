import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import warningAttention from '~/assets/dispatch/warning_attention.png';

const PF2MAlertDialog = ({
  classes,
  title,
  description,
  cancelText,
  confirmText,
  onClose,
  onConfirm,
  open,
  hasCancel,
  badge,
}) => {
  const { t: translate } = useTranslation();

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialog }}
      >
        {badge && (
          <div className={classes.iconWrapper}>
            <img src={warningAttention} alt="Warning Attention" className={classes.icon} />
          </div>
        )}
        {title ? (
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        ) : null}
        <DialogContent className={classes.dialogContent}>
          {description ? (
            <DialogContentText
              id="alert-dialog-description"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {description}
            </DialogContentText>
          ) : null}
        </DialogContent>
        <DialogActions>
          {hasCancel ? (
            <Button onClick={onClose} variant="text" color="primary">
              {cancelText || translate('common:Cancel')}
            </Button>
          ) : null}
          <Button onClick={onConfirm} variant="contained" color="secondary" autoFocus>
            {confirmText || translate('common:Exit')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

PF2MAlertDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  hasCancel: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  badge: PropTypes.bool,
};

PF2MAlertDialog.defaultProps = {
  title: '',
  description: undefined,
  cancelText: undefined,
  confirmText: undefined,
  open: false,
  onClose: () => {},
  onConfirm: () => {},
  hasCancel: true,
  badge: false,
};

export default withStyles(styles)(PF2MAlertDialog);
