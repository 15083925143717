const styles = theme => ({
  dialog: {
    position: 'relative',
    overflow: 'visible',
  },
  iconWrapper: {
    position: 'absolute',
    top: '-40px',
    left: theme.spacing(2),
    zIndex: 2,
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    borderRadius: '10%',
    padding: theme.spacing(1.5),
    boxShadow: theme.shadows[4],
    height: '50px',
    width: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    paddingTop: theme.spacing(4),
  },
  icon: {
    height: '100%',
    width: '100%',
  },
});

export default styles;
