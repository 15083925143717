import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  root: {
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 40,
    minWidth: 200,
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    paddingLeft: '5px',
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    paddingLeft: 5,
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 14,
    color: '#647886', // '#CDCDCD',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 300,
    paddingLeft: 8,
  },
  paper: {
    position: 'absolute',
    zIndex: 999,
    marginTop: theme.spacing(1),
    left: 0,
    minWidth: '100%',
  },
  divider: {
    height: theme.spacing(2),
  },
});

export default styles;
