import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import PF2MTopBar from '~/components/PF2MTopBar';
import { isAuthenticated, getToken, getConfig } from '~/services/authentication';
import 'react-notifications/lib/notifications.css';
import { clearMessages } from '~/store/errors/actions';
import { logoutUser, setCurrentUser } from '~/store/auth/actions';
import PF2MLoading from '~/components/PF2MLoading';
import { useTranslation } from 'react-i18next';
import PF2MTopBarMain from '~/components/PF2MTopBarMain';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const Main = ({
  children,
  errors,
  fileUploadSuccess,
  clearMessages: clearMessagesAction,
  auth,
  logoutUser: logout,
  loading,
  hasWarning,
  hasLoading,
  type,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const notifiedMessages = useRef(new Set());

  useEffect(() => {
    if (isAuthenticated()) {
      dispatch(setCurrentUser(getToken(), getConfig()));
    }
  }, [auth.isAuthenticated, logout, dispatch]);

  useEffect(() => {
    if (errors.length > 0) {
      const uniqueErrorMessages = errors
        .filter((obj, index, self) => index === self.findIndex(t => t.message === obj.message))
        .map(e => e.message);

      const newErrorMessages = uniqueErrorMessages.filter(
        message => !notifiedMessages.current.has(message),
      );

      if (newErrorMessages.length > 0) {
        clearMessagesAction();
        notifiedMessages.current.clear();
        const messagesToShow = newErrorMessages.join('-');
        NotificationManager.error(
          messagesToShow, translate('common:Error'), 3000, () => { },
        );
        newErrorMessages.forEach(message => notifiedMessages.current.add(message));
      }
    }
    if (fileUploadSuccess.length > 0 && hasWarning) {
      NotificationManager.warning(fileUploadSuccess, translate('common:Warning'), 3000, () => { });
    } else if (fileUploadSuccess.length > 0) {
      NotificationManager.success(fileUploadSuccess, translate('common:Success'), 3000, () => { });
    }
    const interval = setInterval(() => {
      if (fileUploadSuccess.length > 0 || hasWarning) {
        clearMessagesAction();
        notifiedMessages.current.clear();
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [errors, fileUploadSuccess, hasWarning, clearMessagesAction, translate, location]);

  return (
    <>
      <div data-testid="123">
        {isAuthenticated() && type === 'home' && <PF2MTopBarMain />}
        {isAuthenticated() && type !== 'home' && <PF2MTopBar />}
        {children}
      </div>
      {hasLoading && <PF2MLoading loading={loading} />}
      <NotificationContainer />
    </>
  );
};

Main.propTypes = {
  children: PropTypes.any.isRequired,
  errors: PropTypes.array,
  clearMessages: PropTypes.func,
  fileUploadSuccess: PropTypes.string,
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func,
  loading: PropTypes.bool,
  hasWarning: PropTypes.bool,
  hasLoading: PropTypes.bool,
  type: PropTypes.string,
};

Main.defaultProps = {
  errors: [],
  clearMessages: null,
  fileUploadSuccess: '',
  logoutUser: null,
  loading: false,
  hasWarning: false,
  hasLoading: true,
  type: '',
};

const mapStateToProps = state => ({
  errors: state.errors.errors,
  fileUploadSuccess: state.errors.fileUploadSuccess,
  auth: state.auth,
  loading: state.loadingReducer.loading,
  hasWarning: state.errors.hasWarning,
});

const mapDispatchToProps = {
  clearMessages,
  logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
