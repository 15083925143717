import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getEquipmentTurnGroups,
  setEquipmentTurnGroupValues,
  updateEquipmentTurnGroups,
  addEquipmentTurnGroup,
  resetEquipmentTurnGroups,
  getTurnsGroups,
  getEquipmentsGroups,
} from '~/store/manager/actions';
import Header from './Header';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';

const EquipmentsGroupsTurns = ({
  classes,
  equipmentTurnGroups,
  turnsGroups,
  equipmentsGroups,
  getEquipmentsGroups: loadEquipmentsGroups,
  getTurnsGroups: loadTurnsGroups,
  getEquipmentTurnGroups: getAll,
  setEquipmentTurnGroupValues: updateItem,
  updateEquipmentTurnGroups: update,
  addEquipmentTurnGroup: add,
  resetEquipmentTurnGroups: reset,
  isEquipmentTurnGroupsDirty,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const { t: translate } = useTranslation();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [successDialogMessage, setSuccessDialogMessage] = useState('');

  useEffect(() => {
    async function fetchData() {
      await getAll();
      await loadEquipmentsGroups();
      await loadTurnsGroups();
    }

    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, loadTurnsGroups, loadEquipmentsGroups]);

  const warningPopup = () => {
    const message = (
      `${translate('validation:CompleteTheRegistrationMenuTabs')}`
    );
    setSuccessDialogMessage(message);
    setShowSuccessDialog(true);
  };

  useEffect(() => {
    const types = [];
    equipmentsGroups.forEach((e) => {
      if (!types.find(y => y.id_equipment === e.id_equipament)) {
        types.push({ id_equipment: e.id_equipament, name: e.type_name });
      }
    });
    setEquipmentTypes(types);
  }, [equipmentsGroups]);

  const columns = [
    {
      field: 'equipment_name',
      title: translate('common:EquipmentGroup'),
      editable: false,
    },
    {
      field: 'id_turn_group',
      title: translate('common:ShiftsGroup'),
      editable: true,
      selectItems: turnsGroups,
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
      align: 'left',
      width: '100%',
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        add={add}
        turnsGroups={turnsGroups}
        equipmentTypes={equipmentTypes}
        equipmentsGroups={equipmentsGroups}
        equipmentTurnGroups={equipmentTurnGroups}

      />
      <PF2MTable
        data={equipmentTurnGroups}
        columns={columns}
        updateItem={updateItem}
      />
      <PF2MAlertDialog
        title={translate('common:RegistrationSteps')}
        description={
          <div style={styles.descriptionStyle}>{successDialogMessage}</div>
        }
        open={showSuccessDialog}
        onConfirm={() => {
          setShowSuccessDialog(false);
        }}
        onClose={() => setShowSuccessDialog(false)}
        hasCancel={false}
        badge
      />
      <Footer
        isDirty={isEquipmentTurnGroupsDirty}
        discard={() => reset()}
        sendData={() => {
          update(equipmentTurnGroups);
          warningPopup();
        }}
      />
    </div>
  );
};

EquipmentsGroupsTurns.propTypes = {
  classes: PropTypes.object.isRequired,
  equipmentTurnGroups: PropTypes.array,
  turnsGroups: PropTypes.array,
  equipmentsGroups: PropTypes.array,
  getTurnsGroups: PropTypes.func,
  getEquipmentsGroups: PropTypes.func,
  getEquipmentTurnGroups: PropTypes.func,
  setEquipmentTurnGroupValues: PropTypes.func,
  updateEquipmentTurnGroups: PropTypes.func,
  addEquipmentTurnGroup: PropTypes.func,
  resetEquipmentTurnGroups: PropTypes.func,
  isEquipmentTurnGroupsDirty: PropTypes.bool,
};

EquipmentsGroupsTurns.defaultProps = {
  equipmentTurnGroups: [],
  turnsGroups: [],
  equipmentsGroups: [],
  getTurnsGroups: () => { },
  getEquipmentsGroups: () => { },
  getEquipmentTurnGroups: () => { },
  setEquipmentTurnGroupValues: () => { },
  updateEquipmentTurnGroups: () => { },
  addEquipmentTurnGroup: () => { },
  resetEquipmentTurnGroups: () => { },
  isEquipmentTurnGroupsDirty: false,
};

const mapStateToProps = state => ({
  equipmentsGroups: state.manager.equipmentsGroups,
  turnsGroups: state.manager.turnsGroups,
  equipmentTurnGroups: state.manager.equipmentTurnGroups,
  isEquipmentTurnGroupsDirty: state.manager.isEquipmentTurnGroupsDirty,
});

const mapDispatchToProps = {
  getEquipmentTurnGroups,
  setEquipmentTurnGroupValues,
  updateEquipmentTurnGroups,
  addEquipmentTurnGroup,
  resetEquipmentTurnGroups,
  getTurnsGroups,
  getEquipmentsGroups,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(EquipmentsGroupsTurns));
