import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import { ZendeskAPI } from 'react-zendesk';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MMenu from '~/components/PF2MMenu';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import { useFeatureFlagPlatformConfiguration } from '~/services/platformConfiguration';
import useTabletConfiguration from '~/services/tabletConfiguration';
import { getTabletConfiguration, setAlertDialog } from '~/store/manager/actions';
import {
  getCodeGroups,
  getCodes,
  getCodeTypes,
  getElements,
  getEquipmentGroups,
  getEquipments,
  getEquipmentTypes,
  getSubElements,
} from '~/store/nref/actions';
import Main from '../Main';
import DispatchControl from './DispatchControl';
import EquipmentPrograms from './EquipmentPrograms';
import EquipmentStatus from './EquipmentStatus';
import LoadSchedules from './LoadSchedules';
import OperationalControl from './OperationalControl';
import PerforatorSchedules from './PerforatorSchedules';
import styles from './styles';
import Terrain from './Terrain';

const Dispatch = ({ classes, match, history }) => {
  const dispatch = useDispatch();
  const alertDialog = useSelector(state => state.manager.alertDialog);
  // eslint-disable-next-line max-len
  const isEquipmentsSchedulesDirty = useSelector(state => state.dispatch.isEquipmentsSchedulesDirty);
  // eslint-disable-next-line no-underscore-dangle
  const roles = useSelector(state => state.auth.user._roles);
  const { t: translate } = useTranslation();
  const showDispatchOperationalControl = useFeatureFlagPlatformConfiguration(
    'dispatch_operational_control_tab', 0,
  );

  const [dataLoaded, setDataLoaded] = useState(false);

  const validateNavigation = useCallback(({ pathname = '' }) => {
    if ((isEquipmentsSchedulesDirty) && !pathname.includes('equipmentprogram')) {
      dispatch(setAlertDialog());
      return false;
    }
    return true;
  }, [isEquipmentsSchedulesDirty, dispatch]);

  const handleNavigation = (e, pathname = '') => {
    if (!validateNavigation(pathname)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (history.location && history.location.length > 0) {
      validateNavigation(history.location);
    }
  }, [history.location, validateNavigation]);

  useEffect(() => {
    const timer = setTimeout(() => {
      ZendeskAPI('webWidget', 'hide');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const excludeTabs = [
    !showDispatchOperationalControl && 'operationalcontrol',
  ].filter(Boolean);

  const { value: dispatchUpdateTime } = useTabletConfiguration('dispatch_update_time');

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'SKIP_LOADING', payload: true });
      dispatch(getCodes());
      dispatch(getCodeTypes());
      dispatch(getCodeGroups());

      dispatch(getEquipments());
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentGroups());

      dispatch(getElements());
      dispatch(getSubElements());
      dispatch({ type: 'SKIP_LOADING', payload: false });
    };
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    dispatch(getTabletConfiguration());
    if (dispatchUpdateTime !== undefined) {
      const time = dispatchUpdateTime * 1000;
      const interval = setInterval(() => setDataLoaded(false), time);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [dispatchUpdateTime]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Main>
        <div className={classes.container}>
          <PF2MScrollbar style={{ width: 150, padding: 10 }}>
            <PF2MMenu
              handleNavigation={handleNavigation}
              moduleName="dispatch"
              excludePaths={excludeTabs}
              roles={roles}
            />
          </PF2MScrollbar>
          <div style={{ flex: 1, overflow: 'auto' }}>
            <Switch>
              <Route exact path={`${match.path}/equipmentprogram`} component={EquipmentPrograms} />
              <Route exact path={`${match.path}/equipmentstatus`} component={EquipmentStatus} />
              <Route exact path={`${match.path}/loadschedules`} component={LoadSchedules} />
              <Route exact path={`${match.path}/perforatorschedules`} component={PerforatorSchedules} />
              <Route exact path={`${match.path}/dispatchcontrol`} component={DispatchControl} />
              <Route exact path={`${match.path}/operationalcontrol`} component={OperationalControl} />
              <Route exact path={`${match.path}/terrain`} component={Terrain} />
              <Redirect from={`${match.path}`} to={`${match.path}/equipmentprogram`} />
            </Switch>
          </div>
          <PF2MAlertDialog
            hasCancel={false}
            confirmText={translate('common:Understood')}
            description={translate('validation:YouHaveUnsavedData')}
            open={alertDialog}
            onConfirm={() => dispatch(setAlertDialog())}
          />
        </div>
      </Main>
    </DndProvider>
  );
};

Dispatch.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

Dispatch.defaultProps = {};

export default withRouter(withStyles(styles)(Dispatch));
