import React, { useState, useEffect, useMemo } from 'react';
import { Checkbox, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getMaterialsGroups,
  getSubElements,
  getHandlingTypes,
  getSubElementTypes,
  getFlowMovements,
  setFlowMovementValues,
  updateFlowMovements,
  addFlowMovement,
  resetFlowMovements,
} from '~/store/manager/actions';
import Header from './Header';

const mapIdToValue = (list, id = 'id', value = 'name') => (list || []).reduce(
  (acc, item) => ({ ...acc, [item[id]]: item[value] }),
  {},
);

const MaterialMovementTab = ({
  classes,
  getMaterialsGroups: loadMaterialsGroups,
  getSubElements: loadSubElements,
  getHandlingTypes: loadHandlingTypes,
  getSubElementTypes: loadSubElementTypes,
  getFlowMovements: loadFlowMovements,
  materialsGroups,
  subElements,
  subElementTypes,
  handlingTypes,
  flowMovements,
  getFlowMovements: getAll,
  setFlowMovementValues: updateItem,
  updateFlowMovements: update,
  addFlowMovement: add,
  resetFlowMovements: reset,
  isFlowMovementsDirty,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      // await getAll();
      await loadMaterialsGroups();
      await loadSubElements();
      await loadHandlingTypes(true);
      await loadSubElementTypes();
      await loadFlowMovements();
    }

    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [
    dataLoaded,
    getAll,
    loadMaterialsGroups,
    loadSubElements,
    loadHandlingTypes,
    loadFlowMovements,
    loadSubElementTypes,
  ]);

  const materialGroupsLookup = useMemo(() => mapIdToValue(materialsGroups), [materialsGroups]);
  const subElementTypesLookup = useMemo(() => mapIdToValue(subElementTypes), [subElementTypes]);

  const columns = [
    {
      field: 'material_groups_id',
      title: translate('common:MaterialsGroup'),
      lookup: materialGroupsLookup,
    },
    {
      field: 'origin_sub_element_type_id',
      title: translate('common:OriginType'),
      lookup: subElementTypesLookup,
    },
    {
      field: 'destination_sub_element_type_id',
      title: translate('common:DestinationType'),
      lookup: subElementTypesLookup,
    },
    {
      field: 'handling_type_id',
      title: translate('common:MovementType'),
      editable: true,
      selectItems: handlingTypes,
    },
    {
      field: 'active',
      title: 'STATUS',
      editable: true,
      align: 'left',
    },
  ];

  const filteredFlowMovements = showInactive
    ? flowMovements
    : flowMovements.filter(({ active }) => active === 1);

  return (
    <div className={classes.tabContainer}>
      <Header
        add={add}
        materialsGroups={materialsGroups}
        elements={subElements}
        handlingTypes={handlingTypes}
        subElementTypes={subElementTypes}
      />
      <div style={{ display: 'flex', marginBottom: 1, minHeight: 5 }}>
        <div>
          <Checkbox checked={showInactive} onChange={() => setShowInactive(!showInactive)} />
        </div>
        <div>
          <p>{translate('common:ShowInactive')}</p>
        </div>
      </div>
      <PF2MTable
        data={filteredFlowMovements}
        columns={columns}
        updateItem={updateItem}
      />
      <Footer
        isDirty={isFlowMovementsDirty}
        discard={() => reset()}
        sendData={() => update(flowMovements)}
      />
    </div>
  );
};

MaterialMovementTab.propTypes = {
  classes: PropTypes.object.isRequired,
  getMaterialsGroups: PropTypes.func,
  getSubElements: PropTypes.func,
  getHandlingTypes: PropTypes.func,
  getSubElementTypes: PropTypes.func,
  getFlowMovements: PropTypes.func,
  materialsGroups: PropTypes.array,
  subElements: PropTypes.array,
  subElementTypes: PropTypes.array,
  flowMovements: PropTypes.array,
  handlingTypes: PropTypes.array,
  setFlowMovementValues: PropTypes.func,
  updateFlowMovements: PropTypes.func,
  addFlowMovement: PropTypes.func,
  resetFlowMovements: PropTypes.func,
  isFlowMovementsDirty: PropTypes.bool,
};

MaterialMovementTab.defaultProps = {
  getMaterialsGroups: () => {},
  materialsGroups: [],
  subElements: [],
  subElementTypes: [],
  handlingTypes: [],
  flowMovements: [],
  getSubElements: () => {},
  getHandlingTypes: () => {},
  getSubElementTypes: () => {},
  getFlowMovements: () => {},
  setFlowMovementValues: () => {},
  updateFlowMovements: () => {},
  addFlowMovement: () => {},
  resetFlowMovements: () => {},
  isFlowMovementsDirty: false,
};

const mapStateToProps = state => ({
  materialsGroups: state.manager.materialsGroups,
  subElements: state.manager.subElements,
  subElementTypes: state.manager.subElementTypes,
  handlingTypes: state.manager.handlingTypes,
  flowMovements: state.manager.flowMovements,
  materialMovements: state.manager.materialMovements,
  isFlowMovementsDirty: state.manager.isFlowMovementsDirty,
});

const mapDispatchToProps = {
  getMaterialsGroups,
  getSubElements,
  getHandlingTypes,
  getSubElementTypes,
  getFlowMovements,
  setFlowMovementValues,
  updateFlowMovements,
  addFlowMovement,
  resetFlowMovements,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(MaterialMovementTab));
