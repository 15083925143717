import React from 'react';
import {
  TableCell,
  TableRow,
  Fab,
  withStyles,
  TextField,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import PF2MSearchSelect from '~/components/PF2MSearchSelect';
import PF2MMaskTextField from '~/components/PF2MMaskTextField';
import styles from '../styles';
import {
  formatDateToServerFormat,
  parseDateToServerFormat,
  subtractDaysFromNow,
  newMomentDate,
  parseDate,
} from '~/utils/moment';
import { parseFloat } from '~/utils';
import usePlatformConfiguration, {
  usePlatformConfigurationUserAccessLevel,
} from '~/services/platformConfiguration';

const ListRow = ({
  data,
  classes,
  onEdit,
  onDelete,
  onRestore,
  turns,
  equipmentsGroups,
  equipments,
  operatorsGroups,
  operators,
  elements,
  subElements,
  materials,
}) => {
  const { t: translate } = useTranslation();
  const handleChange = (item = [], name = '') => (e) => {
    const value = parseFloat(e.target.value) || 0;
    onEdit(item, name, value);
  };

  const handleSelectChange = (item = [], name = '') => e => onEdit(item, name, e.target.value);

  const renderNumberCell = (name, value = 0, item) => (
    <NumberFormat
      customInput={TextField}
      value={parseFloat(value)}
      InputProps={{ classes: { input: classes.input } }}
      onChange={handleChange(item, name)}
      disabled={item.update_status === 'D'}
    />
  );

  // handle clearing outside if value can be changed outside of the component
  const handleMask = value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []);

  const userAccessLevel = useSelector(state => state.auth.user.level);
  const { value: userLimitEditConfig } = usePlatformConfigurationUserAccessLevel(userAccessLevel);
  const closures = useSelector(state => state.manager.closures);
  const { value: enableClosure } = usePlatformConfiguration('enable_closure');

  const renderDatePicker = (d, attr) => {
    let userLimitEdit = subtractDaysFromNow(userLimitEditConfig);
    const isEnableClosureOn = enableClosure && closures?.length > 0;

    if (isEnableClosureOn) {
      const dateNow = newMomentDate();
      const lastClosureDate = parseDate(closures[0].timestampClose, true);
      const currentDaysFromLastClosure = dateNow.diff(lastClosureDate, 'days');

      const lockEditConfig = subtractDaysFromNow(userLimitEditConfig);
      const lockEditLastClosure = subtractDaysFromNow(currentDaysFromLastClosure);

      userLimitEdit = currentDaysFromLastClosure > userLimitEditConfig
        ? lockEditConfig : lockEditLastClosure.add(1, 'day');
    }

    return (
      <KeyboardDatePicker
        clearable
        mask={handleMask}
        format={translate('date:DateFormat')}
        disableFuture
        autoOk
        minDate={userLimitEdit}
        onChange={e => onEdit(d, attr, formatDateToServerFormat(e))}
        value={d[attr] ? parseDateToServerFormat(d[attr]) : ''}
        invalidDateMessage={translate('validation:InvalidDate')}
      />
    );
  };

  const renderTime = (d, attr) => (
    <PF2MMaskTextField
      mask="99:99:99"
      value={d[attr]}
      onChange={e => onEdit(d, attr, e.target.value)}
    />
  );

  return (
    <TableRow>
      <TableCell className={classes.tableCell}>
        {renderDatePicker(data, 'start_date')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderTime(data, 'start_time')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderDatePicker(data, 'end_date')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderTime(data, 'end_time')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.equip_group_id}
          onChange={handleSelectChange(data, 'equip_group_id')}
          disabled={data.update_status === 'D'}
        >
          {equipmentsGroups
            .map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.equip_id}
          onChange={handleSelectChange(data, 'equip_id')}
          disabled={data.update_status === 'D'}
        >
          {equipments
            .filter(d => data.equip_group_id === 0 || d.id_group === data.equip_group_id)
            .map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.operator_group_id}
          onChange={handleSelectChange(data, 'operator_group_id')}
          disabled={data.update_status === 'D'}
        >
          {operatorsGroups
            .map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.operator_id}
          onChange={handleSelectChange(data, 'operator_id')}
          disabled={data.update_status === 'D'}
        >
          {operators
            .filter(o => o.name && (data.operator_group_id === 0
              || o.id_group === data.operator_group_id))
            .map(e => ({ value: e.id_operator, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderDatePicker(data, 'return_date')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderTime(data, 'return_time')}
      </TableCell>
      <TableCell className={classes.tableCell} style={{ paddingBottom: 0 }}>
        {renderNumberCell('return_latitude', data.return_latitude, data)}
      </TableCell>
      <TableCell className={classes.tableCell} style={{ paddingBottom: 0 }}>
        {renderNumberCell('return_longitude', data.return_longitude, data)}
      </TableCell>
      <TableCell className={classes.tableCell} style={{ paddingBottom: 0 }}>
        {renderNumberCell('return_altitude', data.return_altitude, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.return_gps_area}
          onChange={handleSelectChange(data, 'return_gps_area')}
          disabled={data.update_status === 'D'}
        >
          {elements
            .sort((i, k) => (i.name < k.name ? 1 : -1)) // alphanumeric sorting
            .sort(i => i.active * -1) // move inactive items to the end of the array
            .map(e => ({
              value: e.id,
              label: `${e.name} ${!e.active ? `- ${translate('common:Inactive')}` : ''}`,
            }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.return_gps_subarea}
          onChange={handleSelectChange(data, 'return_gps_subarea')}
          disabled={data.update_status === 'D'}
        >
          {subElements
            .filter(d => data.return_gps_area === 0 || d.id_element === data.return_gps_area)
            .sort((i, k) => (i.name < k.name ? 1 : -1)) // alphanumeric sorting
            .sort(i => i.active * -1) // move inactive items to the end of the array
            .map(e => ({
              value: e.id,
              label: `${e.name} ${!e.active ? `- ${translate('common:Inactive')}` : ''}`,
            }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell} style={{ paddingBottom: 0 }}>
        {renderNumberCell('dist_return', data.dist_return, data)}
      </TableCell>
      <TableCell className={classes.tableCell} style={{ paddingBottom: 0 }}>
        {renderNumberCell('return_duration', data.return_duration, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderDatePicker(data, 'cut_date')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderTime(data, 'cut_time')}
      </TableCell>
      <TableCell className={classes.tableCell} style={{ paddingBottom: 0 }}>
        {renderNumberCell('cut_latitude', data.cut_latitude, data)}
      </TableCell>
      <TableCell className={classes.tableCell} style={{ paddingBottom: 0 }}>
        {renderNumberCell('cut_longitude', data.cut_longitude, data)}
      </TableCell>
      <TableCell className={classes.tableCell} style={{ paddingBottom: 0 }}>
        {renderNumberCell('cut_altitude', data.cut_altitude, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.cut_gps_area}
          onChange={handleSelectChange(data, 'cut_gps_area')}
          disabled={data.update_status === 'D'}
        >
          {elements
            .sort((i, k) => (i.name < k.name ? 1 : -1)) // alphanumeric sorting
            .sort(i => i.active * -1) // move inactive items to the end of the array
            .map(e => ({
              value: e.id,
              label: `${e.name} ${!e.active ? `- ${translate('common:Inactive')}` : ''}`,
            }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.cut_gps_subarea}
          onChange={handleSelectChange(data, 'cut_gps_subarea')}
          disabled={data.update_status === 'D'}
        >
          {subElements
            .filter(d => data.cut_gps_area === 0 || d.id_element === data.cut_gps_area)
            .sort((i, k) => (i.name < k.name ? 1 : -1)) // alphanumeric sorting
            .sort(i => i.active * -1) // move inactive items to the end of the array
            .map(e => ({
              value: e.id,
              label: `${e.name} ${!e.active ? `- ${translate('common:Inactive')}` : ''}`,
            }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.material}
          onChange={handleSelectChange(data, 'material')}
          disabled={data.update_status === 'D'}
        >
          {materials
            .map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.cut_type}
          onChange={handleSelectChange(data, 'cut_type')}
          // disabled={data.update_status === 'D'}
          disabled
        >
          {[].map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell} style={{ paddingBottom: 0 }}>
        {renderNumberCell('prod', data.prod, data)}
      </TableCell>
      <TableCell className={classes.tableCell} style={{ paddingBottom: 0 }}>
        {renderNumberCell('dist_cut', data.dist_cut, data)}
      </TableCell>
      <TableCell className={classes.tableCell} style={{ paddingBottom: 0 }}>
        {renderNumberCell('cut_duration', data.cut_duration, data)}
      </TableCell>
      <TableCell className={classes.tableCell} style={{ paddingBottom: 0 }}>
        {renderNumberCell('code_time', data.code_time, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.turn}
          onChange={handleSelectChange(data, 'turn')}
          disabled={data.update_status === 'D'}
        >
          {turns
            .map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell} style={{ paddingBottom: 0 }}>
        {renderNumberCell('duration', data.duration, data)}
      </TableCell>
      <TableCell style={{
        position: 'sticky',
        right: 0,
        height: 48,
      }}
      >
        {data.isDirty ? (
          <Fab
            size="small"
            color="primary"
            aria-label="Restore"
            onClick={() => onRestore(data)}
          >
            <RestoreIcon />
          </Fab>
        ) : (
          <Fab
            size="small"
            color="primary"
            aria-label="Delete"
            onClick={() => onDelete(data)}
          >
            <DeleteIcon />
          </Fab>
        )}
      </TableCell>
    </TableRow>
  );
};

ListRow.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func,
  turns: PropTypes.array,
  equipmentsGroups: PropTypes.array,
  equipments: PropTypes.array,
  operatorsGroups: PropTypes.array,
  operators: PropTypes.array,
  elements: PropTypes.array,
  subElements: PropTypes.array,
  materials: PropTypes.array,
};

ListRow.defaultProps = {
  data: {},
  classes: {},
  onEdit: null,
  onDelete: null,
  onRestore: null,
  turns: [],
  equipmentsGroups: [],
  equipments: [],
  operatorsGroups: [],
  operators: [],
  elements: [],
  subElements: [],
  materials: [],
};

export default withStyles(styles)(ListRow);
