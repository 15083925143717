import React, { useEffect, useState, useRef } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getTeamGroups,
  addTeamGroup,
  getTurnsGroups,
  setTeamGroupValue,
  resetTeamGroups,
  updateTeamGroups,
} from '~/store/manager/actions';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';

const ClassGroupsTab = ({ classes }) => {
  const teamGroups = useSelector(state => state.manager.teamGroups);
  const turnsGroups = useSelector(state => state.manager.turnsGroups);
  const isTeamGroupsDirty = useSelector(state => state.manager.isTeamGroupsDirty);
  const [selectedTurnGroup, setSelectedTurnGroup] = useState(0);
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [successDialogMessage, setSuccessDialogMessage] = useState('');

  useEffect(() => {
    async function fetchData() {
      dispatch(getTurnsGroups(true));
      dispatch(getTeamGroups(true));
    }
    fetchData();
  }, [dispatch]);

  const warningPopup = () => {
    const message = (
      `${translate('validation:CompleteTheRegistrationMenuTabs')}`
    );
    setSuccessDialogMessage(message);
    setShowSuccessDialog(true);
  };

  const isValidName = (turnGroupId, name) => {
    const existingItem = teamGroups
      .find(c => c.name === name);

    return !existingItem;
  };

  const columns = [
    { field: 'turn_group_name', title: translate('common:ShiftGroup'), width: '200px' },
    {
      field: 'name',
      title: translate('common:TeamGroup'),
      editable: true,
      updateOnChange: true,
    },
    { field: 'active', title: translate('common:STATUS'), editable: true },
  ];

  const renderHeader = () => (
    <Formik
      ref={formikRef}
      initialValues={{ turnGroup: selectedTurnGroup, name: '' }}
      validate={(values) => {
        const errors = {};
        const { turnGroup, name } = values;

        if (!name) {
          errors.name = translate('validation:RequiredField');
        }

        if (turnGroup <= 0 && name) {
          errors.turnGroup = translate('validation:RequiredField');
        }

        if (turnGroup > 0 && name) {
          if (!isValidName(turnGroup, name)) {
            errors.name = translate('validation:AlreadyRegisteredName');
          }
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        const turnGroup = turnsGroups.find(t => t.id === values.turnGroup);
        const newValues = {
          id_turn_group: turnGroup.id,
          turn_group_name: turnGroup.name,
          name: values.name,
        };
        dispatch(addTeamGroup(newValues));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form>
          <div style={{ display: 'flex', marginBottom: 15, minHeight: 75 }}>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:ShiftGroup')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="turnGroup"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      placeholder={null}
                      onChange={async (e) => {
                        form.setFieldValue('turnGroup', e.target.value);
                        setSelectedTurnGroup(e.target.value);
                      }}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(...turnsGroups
                          .map(e => ({ value: e.id, label: e.name })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
                <ErrorMessage
                  name="turnGroup"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <FormControl className={classes.classGroupName}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:TeamGroup')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  type="text"
                  name="name"
                  className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
                  style={{ width: '100%' }}
                />
                <ErrorMessage
                  name="name"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <div style={{ marginTop: 12 }}>
              <PF2MAddButton disabled={isSubmitting || !isValid} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={teamGroups
          .filter(c => selectedTurnGroup === 0 || c.id_turn_group === selectedTurnGroup)}
        columns={columns}
        updateItem={(row, field, value) => dispatch(setTeamGroupValue(row, field, value))}
      />
      <PF2MAlertDialog
        title={translate('common:RegistrationSteps')}
        description={
          <div style={styles.descriptionStyle}>{successDialogMessage}</div>
        }
        open={showSuccessDialog}
        onConfirm={() => {
          setShowSuccessDialog(false);
        }}
        onClose={() => setShowSuccessDialog(false)}
        hasCancel={false}
        badge
      />
      <Footer
        isDirty={isTeamGroupsDirty}
        discard={() => {
          dispatch(resetTeamGroups());

          if (formikRef) {
            formikRef.current.resetForm({ name: '', turnGroup: 0 });
          }
        }}
        sendData={() => dispatch(updateTeamGroups(teamGroups), warningPopup())}
      />
    </div>
  );
};

ClassGroupsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ClassGroupsTab);
