import React, {
  useEffect, useState, useRef,
} from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import {
  getTeams,
  addTeam,
  getTeamGroups,
  setTeamValue,
  resetTeams,
  updateTeams,
} from '~/store/manager/actions';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';

const TeamTab = ({ classes }) => {
  const teams = useSelector(state => state.manager.teams);
  const teamGroups = useSelector(state => state.manager.teamGroups);
  const isTeamsDirty = useSelector(state => state.manager.isTeamsDirty);
  const dispatch = useDispatch();
  const [selectedTeamGroup, setSelectedTeamGroup] = useState(0);
  const formikRef = useRef();
  const { t: translate } = useTranslation();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [successDialogMessage, setSuccessDialogMessage] = useState('');

  useEffect(() => {
    async function fetchData() {
      dispatch(getTeamGroups());
      dispatch(getTeams(true));
    }
    fetchData();
  }, [dispatch]);

  const warningPopup = () => {
    const message = (
      `${translate('validation:CompleteTheRegistrationMenuTabs')}`
    );
    setSuccessDialogMessage(message);
    setShowSuccessDialog(true);
  };

  const isValidName = (teamGroupId, name) => {
    const existingItem = teams
      .find(c => c.id_team_group === teamGroupId && c.name === name);

    return !existingItem;
  };

  const columns = [
    { field: 'team_group_name', title: translate('common:TeamGroup'), width: '200px' },
    {
      field: 'name',
      title: translate('common:Team'),
      editable: true,
      updateOnChange: true,
    },
    { field: 'active', title: 'STATUS', editable: true },
  ];

  const renderHeader = () => (
    <Formik
      ref={formikRef}
      initialValues={{ teamGroup: selectedTeamGroup, name: '' }}
      validate={(values) => {
        const errors = {};
        const { teamGroup, name } = values;

        if (!name) {
          errors.name = translate('validation:RequiredField');
        }

        if (teamGroup <= 0 && name) {
          errors.teamGroup = translate('validation:RequiredField');
        }

        if (teamGroup > 0 && name) {
          if (!isValidName(teamGroup, name)) {
            errors.name = translate('validation:AlreadyRegisteredName');
          }
        }

        return errors;
      }}
      onSubmit={async (values, { resetForm }) => {
        const teamGroup = teamGroups.find(t => t.id === values.teamGroup);
        const newValues = {
          id_team_group: teamGroup.id,
          team_group_name: teamGroup.name,
          name: values.name,
        };
        dispatch(addTeam(newValues));
        resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        isValid,
      }) => (
        <Form>
          <div style={{ display: 'flex', marginBottom: 15, minHeight: 75 }}>
            <FormControl>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:TeamGroup')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  name="teamGroup"
                  render={({ field, form }) => (
                    <PF2MSearchSelectOutlined
                      {...field}
                      placeholder={null}
                      onChange={async (e) => {
                        form.setFieldValue('teamGroup', e.target.value);
                        setSelectedTeamGroup(e.target.value);
                      }}
                    >
                      {[{ value: 0, label: translate('common:Select') }]
                        .concat(...teamGroups
                          .map(e => ({ value: e.id, label: e.name })))}
                    </PF2MSearchSelectOutlined>
                  )}
                />
                <ErrorMessage
                  name="teamGroup"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <FormControl className={classes.classGroupName}>
              <FormLabel className={classes.formLabel}>
                {`${translate('common:Team')}:`}
              </FormLabel>
              <FormGroup>
                <Field
                  type="text"
                  name="name"
                  className={classnames('form-control', errors.name ? classes.fieldError : classes.field)}
                  style={{ width: '100%' }}
                />
                <ErrorMessage
                  name="name"
                  component="span"
                  className={classes.errorMessage}
                />
              </FormGroup>
            </FormControl>
            <div style={{ marginTop: 12 }}>
              <PF2MAddButton disabled={isSubmitting || !isValid} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={teams
          .filter(c => selectedTeamGroup === 0 || c.id_team_group === selectedTeamGroup)}
        columns={columns}
        updateItem={(row, field, value) => dispatch(setTeamValue(row, field, value))}
      />
      <PF2MAlertDialog
        title={translate('common:RegistrationSteps')}
        description={
          <div style={styles.descriptionStyle}>{successDialogMessage}</div>
        }
        open={showSuccessDialog}
        onConfirm={() => {
          setShowSuccessDialog(false);
        }}
        onClose={() => setShowSuccessDialog(false)}
        hasCancel={false}
        badge
      />
      <Footer
        isDirty={isTeamsDirty}
        discard={() => {
          dispatch(resetTeams());

          if (formikRef) {
            formikRef.current.resetForm({ name: '', teamGroup: 0 });
          }
        }}
        sendData={() => dispatch(updateTeams(teams, warningPopup()))}
      />
    </div>
  );
};

TeamTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TeamTab);
