import {
  Accordion,
  AccordionDetails,
  FormControl,
  FormGroup,
  FormLabel,
  styled,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { Delete as DeleteIcon, ExpandLess } from '@material-ui/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MButton from '~/components/PF2MButton';
import PF2MEquipmentPool from '~/components/PF2MEquipmentPool';
import PF2MFullScreenButton from '~/components/PF2MFullScreenButton';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import {
  setSelectedLoadAllocation,
  setSelectedTruckAllocation,
  deallocateAllLoadAllocations,
  deallocateTruckAllocations,
  updateLoadAllocations,
  updateTruckAllocations,
  deallocateLoadAllocations,
  setPendingTruckAllocationUpdate,
  getDispatchOriginsTrucks,
  getDispatchOriginsExcavators,
  getEquipmentStatuses,
  getLoadAllocations,
  getTruckAllocations,
} from '~/store/dispatch/actions';
import { DispatchDragNDropTypes, DispatchStatus } from '~/utils';
import styles from '../styles';
import { LoadAllocationModal, TruckAllocationModal, TruckAllocationModeModal } from './AllocationModal';
import AllocationRow from './AllocationRow';
import {
  getCodes,
  getOperators,
  getSleepLevels,
  getTabletConfiguration,
} from '~/store/manager/actions';
import useTabletConfiguration from '~/services/tabletConfiguration';

import {
  getEquipmentTypes,
  getEquipmentGroups,
  getEquipments,
  getElements,
  getSubElements,
  getMaterials,
} from '~/store/nref/actions';


const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    {...props}
  />
))(() => ({
  minHeight: '10px !important',
  '& .MuiAccordionSummary-content': {
    margin: 5,
  },
}));

const TRUCK = 2;
const AllocationsTab = ({ classes }) => {
  const rootRef = React.useRef(0);
  const handle = useFullScreenHandle();

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const equipmentTypes = useSelector(
    state => state.nref.equipmentTypes.filter(r => [1, 2, 15, 29].includes(r.id)),
  );
  const equipmentGroups = useSelector(
    state => state.nref.equipmentGroups.filter(eg => eg.active),
  );
  const truckAllocations = useSelector(state => state.dispatch.truckAllocations);
  const tabletConfiguration = useSelector(state => state.manager.tabletConfiguration);

  const equipmentStatuses = useSelector(state => state.dispatch.equipmentStatuses);
  const codes = useSelector(state => state.manager.codes);
  const operators = useSelector(state => state.manager.operators);

  const truckOriginsAllocations = useSelector(state => state.dispatch.truckOrigins);

  const durationsByTruckOrigins = truckAllocations.map((truck) => {
    const matchingStatuses = equipmentStatuses.filter(
      status => status.id_equip === truck.equip_id,
    );

    const durations = matchingStatuses.flatMap(status => status.duration);

    const mappedStatuses = matchingStatuses.map((status) => {
      const foundCode = codes.find(c => c.id === status.id_code);
      const foundOperator = operators.find(op => op.id_operator === status.id_operator);

      return {
        ...status,
        codeName: foundCode ? foundCode.name : translate('common:CodeNotFound'),
        operatorName: foundOperator ? foundOperator.name : translate('common:OperatorNotFound'),
      };
    });

    const codesNames = mappedStatuses.map(status => status.codeName);
    const operatorsNames = mappedStatuses.map(status => status.operatorName);
    const originAllocation = truckOriginsAllocations.find(
      origin => origin.equip_id === truck.equip_id,
    );

    return {
      ...truck,
      durations,
      codes_name: codesNames,
      operators_name: operatorsNames,
      current_origin_point_name: originAllocation?.current_origin_point_name,
      current_material_name: originAllocation?.current_material_name,
      operation_type: originAllocation?.operation_type,
      load_allocation_excavator_name: originAllocation?.load_allocation_excavator_name,
    };
  });

  const excavatorOrigins = useSelector(
    state => state.dispatch.excavatorOrigins,
  ).filter(item => item.id);

  const durationsByExcavatorOrigins = excavatorOrigins.map((load) => {
    const matchingStatuses = equipmentStatuses.filter(
      status => status.id_equip === load.equip_id,
    );

    const durations = matchingStatuses.flatMap(status => status.duration);

    const mappedStatuses = matchingStatuses.map((status) => {
      const foundCode = codes.find(c => c.id === status.id_code);
      const foundOperator = operators.find(op => op.id_operator === status.id_operator);

      return {
        ...status,
        codeName: foundCode ? foundCode.name : translate('common:CodeNotFound'),
        operatorName: foundOperator ? foundOperator.name : translate('common:OperatorNotFound'),
      };
    });

    const codesNames = mappedStatuses.map(status => status.codeName);
    const operatorsNames = mappedStatuses.map(status => status.operatorName);

    return {
      ...load,
      durations,
      codes_name: codesNames,
      operators_name: operatorsNames,
    };
  });

  const loadAllocations = useSelector(state => state.dispatch.loadAllocations);
  const availablePoolEquips = useSelector(
    state => [
      ...state.dispatch.truckAllocations
        .filter(r => (!r.load_allocation_id && r.status !== DispatchStatus.MA)),
      ...state.dispatch.loadAllocations
        .filter(r => (!r.priority && r.status !== DispatchStatus.MA)),
    ],
  );
  const maintenancePoolEquips = useSelector(
    state => [
      ...state.dispatch.truckAllocations
        .filter(r => (!r.load_allocation_id && r.status === DispatchStatus.MA)),
      ...state.dispatch.loadAllocations
        .filter(r => (!r.priority && r.status === DispatchStatus.MA)),
    ],
  );
  const pendingTruckAllocationUpdate = useSelector(
    state => state.dispatch.pendingTruckAllocationUpdate,
  );

  const [dataLoaded, setDataLoaded] = useState(false);
  const [poolEquipmentType, setPoolEquipmentType] = useState([]);
  const [poolEquipmentGroup, setPoolEquipmentGroup] = useState([]);
  const [isDeallocationAlertOpen, setIsDeallocationAlertOpen] = useState(false);
  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
  const [isTruckModalOpen, setIsTruckModalOpen] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [filterTextOrigin, setFilterTextOrigin] = useState('');
  const [filterTextLoad, setFilterTextLoad] = useState('');
  const [filterTextTruck, setFilterTextTruck] = useState('');

  useEffect(() => {
    async function fetchData() {
      dispatch(getLoadAllocations());
      dispatch(getTruckAllocations());
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentGroups());
      dispatch(getEquipments());
      dispatch(getMaterials());
      dispatch(getElements());
      dispatch(getSubElements());
      dispatch(getTabletConfiguration());
      dispatch(getSleepLevels(true));
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    if (tabletConfiguration.find(k => k.key === 'dispatch_update_time')) {
      const time = tabletConfiguration.find(k => k.key === 'dispatch_update_time').value * 1000;
      const interval = setInterval(() => {
        setDataLoaded(false);
      }, time);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [tabletConfiguration]);

  const { value: dispatchUpdateTime } = useTabletConfiguration('dispatch_update_time');

  useEffect(() => {
    async function fetchData() {
      dispatch(getDispatchOriginsTrucks());
      dispatch(getDispatchOriginsExcavators());
      dispatch(getEquipmentStatuses());
      dispatch(getCodes());
      dispatch(getOperators());
    }
    if (dispatchUpdateTime !== undefined) {
      fetchData();
      const interval = setInterval(fetchData, dispatchUpdateTime * 1000);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [dispatch, dispatchUpdateTime]);

  const authLevel = useSelector(state => state.auth.user.level);

  const applyFiltersToPoolList = (list, listType, listGroup) => {
    if (listType?.length === 0) {
      return list;
    }

    const listFilteredByTypeList = list.filter(r => listType.includes(r.equip_type_id));

    const listFilteredByGroup = listFilteredByTypeList.filter(
      r => listGroup.includes(r.equip_group_id),
    );

    return listFilteredByGroup;
  };

  const renderEmptyRow = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 90,
        backgroundColor: '#F9F9F9',
        color: '#647886',
        fontSize: '14px',
        fontStyle: 'normal',
        fontFamily: 'Roboto',
      }}
    >
      <span>{translate('common:NoRegisteredAllocations')}</span>
    </div>
  );

  const renderTableActionButtons = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        // height: 90,
      }}
    >
      {authLevel === 1 && (
        <PF2MButton
          color="primary"
          onClick={() => setIsDeallocationAlertOpen(true)}
        >
          <DeleteIcon className={classes.icon} />
          {translate('common:ClearAllocations')}
        </PF2MButton>
      )}
      <PF2MAddButton
        text={translate('common:NewAllocation')}
        onClick={() => {
          dispatch(setSelectedLoadAllocation(null));
          setIsLoadModalOpen(true);
        }}
      />
    </div>
  );

  const setEquipmentTypes = (e) => {
    setPoolEquipmentType([...e.target.value.map(r => r.value)]);
    setPoolEquipmentGroup(
      equipmentGroups.filter(
        equip => [...e.target.value.map(target => target.value)]
          .includes(equip.id_equipament),
      ).map(eg => eg.id),
    );
  };

  const renderPoolFilters = () => (
    <div style={{ width: '15%' }}>
      <FormControl style={{ marginTop: 10 }}>
        <FormLabel className={classes.formLabel}>
          {`${translate('common:EquipmentType')}:`}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            value={poolEquipmentType}
            placeholder={translate('common:Select')}
            multiple
            hideSelectedOptions={false}
            onChange={e => setEquipmentTypes(e)}
            className={classnames(classes.field)}
          >
            {equipmentTypes.map(e => ({ value: e.id, label: e.name }))}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
      <FormControl style={{ marginTop: 10 }}>
        <FormLabel className={classes.formLabel}>
          {`${translate('common:EquipmentGroup')}:`}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            value={poolEquipmentGroup}
            placeholder={translate('common:Select')}
            multiple
            hideSelectedOptions={false}
            onChange={(e) => {
              setPoolEquipmentGroup(
                [...e.target.value.map(r => r.value)],
              );
            }}
            className={classnames(classes.field)}
          >
            { equipmentGroups.filter(r => poolEquipmentType.includes(r.id_equipament))
              .map(e => ({ value: e.id, label: e.name }))
            }
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
    </div>
  );

  const renderPools = () => (
    <Accordion
      defaultExpanded
      onChange={() => setExpanded(!expanded)}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={<ExpandLess />}
        id="1"
      >
        <Typography>{translate('common:AvailableEquipments')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ display: 'flex', width: '100%' }}>
          {renderPoolFilters()}
          <div style={{
            display: 'flex',
            marginTop: 10,
            width: '85%',
          }}
          >
            <PF2MEquipmentPool
              equips={
                applyFiltersToPoolList(
                  availablePoolEquips, poolEquipmentType, poolEquipmentGroup,
                )
              }
              onEquipClick={(equip) => {
                if (equip.equip_type_id === TRUCK) {
                  dispatch(setSelectedTruckAllocation(equip));
                  setIsTruckModalOpen(true);
                } else {
                  dispatch(setSelectedLoadAllocation(equip));
                  setIsLoadModalOpen(true);
                }
              }}
              onItemDropped={(id, type) => {
                if (type === DispatchDragNDropTypes.TRUCK) {
                  dispatch(deallocateTruckAllocations(id));
                } else {
                  dispatch(deallocateLoadAllocations(id));
                }
              }}
              headerText={translate('common:AvailableEquipments').toUpperCase()}
            />
            <PF2MEquipmentPool
              equips={
                applyFiltersToPoolList(
                  maintenancePoolEquips, poolEquipmentType, poolEquipmentGroup,
                )
              }
              onEquipClick={(equip) => {
                if (equip.equip_type_id === TRUCK) {
                  dispatch(setSelectedTruckAllocation(equip));
                  setIsTruckModalOpen(true);
                } else {
                  dispatch(setSelectedLoadAllocation(equip));
                  setIsLoadModalOpen(true);
                }
              }}
              onItemDropped={(id, type) => {
                if (type === DispatchDragNDropTypes.TRUCK) {
                  dispatch(deallocateTruckAllocations(id));
                } else {
                  dispatch(deallocateLoadAllocations(id));
                }
              }}
              headerText={translate('common:MaintenanceEquipments').toUpperCase()}
              customStyles={{ marginLeft: 10 }}
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );

  const tableHeader = ({ width, span }) => (
    <div style={{
      width, display: 'flex', justifyContent: 'center', alignItems: 'center',
    }}
    >
      <span className={classes.labelTypeSelector}>{span}</span>
    </div>
  );

  const renderTableHeader = () => (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 50,
      }}
    >
      {
        handle.active ? null : (
          <>
            {tableHeader({ width: '8%', span: translate('common:Priority') })}
            {tableHeader({ width: '4%', span: translate('common:Group') })}
          </>
        )
      }
      {tableHeader({ width: '6%', span: translate('common:Origin') })}
      {tableHeader({ width: '9%', span: translate('common:LoadEquipment') })}
      {tableHeader({ width: '65%', span: translate('common:AllocatedTrucks') })}
    </div>
  );

  const renderFilterTable = () => (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 50,
      }}
    >
      {
        handle.active ? null : (
          <>
            {tableHeader({ width: '12%' })}
          </>
        )
      }
      {tableHeader({
        width: '6%',
        span: (
          <TextField
            type="text"
            value={filterTextOrigin}
            onChange={e => setFilterTextOrigin(e.target.value)}
            placeholder={translate('common:Search')}
          />
        ),
      })}
      {tableHeader({
        width: '9%',
        span: (
          <TextField
            type="text"
            value={filterTextLoad}
            onChange={e => setFilterTextLoad(e.target.value)}
            placeholder={translate('common:Search')}
          />
        ),
      })}
      {tableHeader({
        width: '65%',
        span: (
          <TextField
            type="text"
            value={filterTextTruck}
            onChange={e => setFilterTextTruck(e.target.value)}
            placeholder={translate('common:Search')}
          />
        ),
      })}
    </div>
  );

  const filterTruck = durationsByTruckOrigins
    .filter(truck => truck.equip_name.toLowerCase().includes(filterTextTruck.toLowerCase()))
    .map(truck => truck.load_allocation_excavator_name);

  const uniqueNames = [...new Set(filterTruck.filter(name => name !== null))];

  const filteredLoadAllocations = loadAllocations.filter((load) => {
    if (filterTextLoad !== '') {
      return load.equip_name.toLowerCase().includes(filterTextLoad.toLowerCase());
    }

    if (filterTextTruck !== '') {
      return uniqueNames.some(name => load.equip_name?.toLowerCase() === name.toLowerCase());
    }

    return true;
  });

  const filterOrigins = filteredLoadAllocations.filter((load) => {
    if (filterTextOrigin !== '') {
      return load.origin_element_point_name
        ?.toLowerCase().includes(filterTextOrigin?.toLowerCase());
    }

    return true;
  });

  const renderAllocationTable = () => (
    <div style={{ width: '100%', flex: 1, overflow: 'hidden' }}>
      {renderTableHeader()}
      {renderFilterTable()}
      <PF2MScrollbar style={{
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        // maxHeight: expanded ? 450 : 650,
        transition: 'max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        // height: 'auto',
      }}
      >
        <>
          {
            filterOrigins && filterOrigins.filter(r => !!r.priority).length
              ? filterOrigins.filter(r => !!r.priority).map(
                (r, i) => (
                  <AllocationRow
                    isFullscreen={handle.active}
                    key={r.id}
                    loadAllocation={r}
                    onTruckDrop={(item, allocationId) => {
                      if (!item.load_allocation_id) {
                        dispatch(updateTruckAllocations(item, 'load_allocation_id', allocationId));
                        return;
                      }

                      const newTruckAllocation = {
                        ...item,
                        load_allocation_id: allocationId,
                      };
                      dispatch(setPendingTruckAllocationUpdate(newTruckAllocation));
                    }}
                    onLoadDrop={(item, materialMappings) => {
                      dispatch(updateLoadAllocations(item, materialMappings || []));
                    }}
                    loadAllocations={loadAllocations}
                    trucks={truckAllocations.filter(x => x.load_allocation_id === r.id)}
                    isFirst={i === 0}
                    isLast={i === loadAllocations.length - 1}
                    openLoadModal={setIsLoadModalOpen}
                    openTruckModal={setIsTruckModalOpen}
                    dataTrucksOrigins={durationsByTruckOrigins.filter(
                      x => x.load_allocation_id === r.id,
                    )}
                    dataExcavatorOrigins={durationsByExcavatorOrigins}
                  />
                ),
              )
              : renderEmptyRow()
          }
        </>
      </PF2MScrollbar>
    </div>
  );


  return (
    <FullScreen handle={handle}>
      <div style={handle.active ? { height: '100vh' } : null} className={classes.tabContainer}>
        <PF2MFullScreenButton
          isFullScreenActive={handle.active}
          open={handle.enter}
          close={handle.exit}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: handle.active ? '95%' : '82vh',
            backgroundColor: '#ffffff',
          }}
        >
          {renderAllocationTable()}
          {
            handle.active ? null : (
              <>
                {renderTableActionButtons()}
                {renderPools()}
              </>
            )
          }

        </div>
      </div>
      <div ref={rootRef}>
        <LoadAllocationModal
          isOpen={isLoadModalOpen}
          close={() => setIsLoadModalOpen(false)}
          container={rootRef.current}
        />

        <TruckAllocationModal
          modalData={isTruckModalOpen}
          closeModal={() => setIsTruckModalOpen(false)}
          container={rootRef.current}
        />
        <TruckAllocationModeModal
          isOpen={!!pendingTruckAllocationUpdate}
          close={() => dispatch(setPendingTruckAllocationUpdate(null))}
          container={rootRef.current}
        />

        <PF2MAlertDialog
          hasCancel
          onClose={() => setIsDeallocationAlertOpen(false)}
          confirmText={translate('common:Yes')}
          description={translate('validation:ThisActionWillClearAllYourAllocations')}
          open={isDeallocationAlertOpen}
          onConfirm={() => {
            setIsDeallocationAlertOpen(false);
            dispatch(deallocateAllLoadAllocations());
          }}
          container={rootRef.current}
        />
      </div>
    </FullScreen>
  );
};

AllocationsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

AllocationsTab.defaultProps = {};

export default withStyles(styles)(AllocationsTab);
