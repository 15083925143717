import React, { useState } from 'react';
import { IconButton, Modal, withStyles } from '@material-ui/core';
import { AccessTime, LocalShipping, Build } from '@material-ui/icons';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  setRailroadValues,
  newRailroadItem,
  restoreOriginalRailroad,
  updateRailroadList,
  deleteRailroadItem,
  searchRailroadList,
} from '~/store/dailyparts/actions';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MDataTable from '~/components/PF2MDataTable';
import RailroadMaterialModal from './RailroadMaterialModal';
import RailroadCycleStepTimesModal from './RailroadCycleStepTimesModal';
import LoadEquipmentNumberModal from './LoadEquipmentNumberModal';
import {
  diffTwoDateTimeStr,
  formatDate,
  formatTime,
  newMomentDate,
  subtractDaysFromNow,
  parseDate,
} from '~/utils/moment';
import PF2MLoading from '~/components/PF2MLoading';
import usePlatformConfiguration, {
  usePlatformConfigurationUserAccessLevel,
} from '~/services/platformConfiguration';


const iconStyle = { color: 'rgb(242 170 0)', fontSize: '2rem' };

const RailRoadList = () => {
  const { t: translate } = useTranslation();

  const [modalMaterialOpen, setModalMaterialOpen] = useState(false);
  const [modalRailroadCycleStepTimesOpen, setModalRailroadCycleStepTimesOpen] = useState(false);
  const [modalLoadEquipmentNumberOpen, setModalLoadEquipmentNumberOpen] = useState(false);
  const [railroadReport, setRailroadReport] = useState();

  const railroadList = useSelector(state => state.dailyParts.railroadList);
  const filters = useSelector(state => state.dailyParts.filters);
  const userAccessLevel = useSelector(state => state.auth.user.level);
  const page = useSelector(state => state.dailyParts.page);
  const limit = useSelector(state => state.dailyParts.limit);
  const isLoadingList = useSelector(state => state.dailyParts.isLoadingList);

  const equipmentType = useSelector(
    state => state.manager.equipmentTypes.find(r => r.id === filters.equipmentType), shallowEqual,
  );
  const turns = useSelector(state => state.manager.turns);
  const teams = useSelector(state => state.manager.teams);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const locomotiveGroups = useSelector(state => state.manager.equipmentsGroups
    .filter(e => e.id_equipament === equipmentType.id), shallowEqual);
  const locomotives = useSelector(state => state.manager.equipments
    .filter(e => e.id_equip === equipmentType.id), shallowEqual);
  const operatorsGroups = useSelector(state => state.manager.operatorsGroups
    .filter(o => o.name), shallowEqual);
  const operators = useSelector(state => state.manager.operators);
  const materials = useSelector(state => state.manager.materials);
  const exceptionTypes = useSelector(state => state.manager.exceptionTypes);
  const operationTypes = useSelector(state => state.manager.operationTypes
    .filter(e => e.id_equipment_type === equipmentType.id), shallowEqual);
  const dispatch = useDispatch();

  const { value: userLimitEditConfig } = usePlatformConfigurationUserAccessLevel(userAccessLevel);
  const closures = useSelector(state => state.manager.closures);
  const { value: enableClosure } = usePlatformConfiguration('enable_closure');

  const getDuration = ({
    start_date,
    start_time,
    end_date,
    end_time,
  }) => {
    const diff = diffTwoDateTimeStr(start_date, start_time, end_date, end_time);
    const duration = diff / 1000 / 60;
    return Number(duration.toFixed(2));
  };

  const equipments = useSelector(state => state.manager.equipments).filter(e => e.id_equip === 32);

  const initialDataMaterials = [];
  equipments
    .filter(e => e.active === 1)
    .forEach((e, index) => {
      initialDataMaterials.push({
        id: index, update_status: 'I', isFakeId: true, id_equip: e.id,
      });
    });

  const validateDate = key => (row) => {
    let userLimitEdit = subtractDaysFromNow(userLimitEditConfig);
    const isEnableClosureOn = enableClosure && closures?.length > 0;

    if (isEnableClosureOn) {
      const dateNow = newMomentDate();
      const lastClosureDate = parseDate(closures[0].timestampClose, true);
      const currentDaysFromLastClosure = dateNow.diff(lastClosureDate, 'days');

      const lockEditConfig = subtractDaysFromNow(userLimitEditConfig);
      const lockEditLastClosure = subtractDaysFromNow(currentDaysFromLastClosure);

      userLimitEdit = currentDaysFromLastClosure > userLimitEditConfig
        ? lockEditConfig : lockEditLastClosure;
    }

    const invalidEditPeriodDateMsg = {
      isValid: false, helperText: translate('validation:InvalidPeriodDate'),
    };

    if (key === 'start_date' && parseDate(row[key]).isBefore(userLimitEdit)) return invalidEditPeriodDateMsg;

    return { isValid: true };
  };

  const columns = [
    {
      editable: 'always',
      title: translate('common:InitialDate'),
      field: 'start_date',
      customType: 'dateRailroad',
      validate: validateDate('start_date'),
    },
    {
      editable: 'always',
      title: translate('common:FinalDate'),
      field: 'end_date',
      customType: 'dateRailroad',
    },
    {
      editable: 'always',
      title: translate('common:InitialHour'),
      field: 'start_time',
      customType: 'time',
    },
    {
      editable: 'always',
      title: translate('common:FinalHour'),
      field: 'end_time',
      customType: 'time',
    },
    {
      editable: 'never',
      title: translate('common:Duration'),
      field: 'duration',
      render: railroad => getDuration(railroad),
    },
    {
      editable: 'always',
      title: translate('common:Shift'),
      field: 'id_turn',
      lookup: turns.map(e => ({
        id: e.id,
        name: `${e.turn_group_name} - ${e.name}`,
      })),
      validate: row => row.id_turn && row.id_turn !== '0',
    },
    {
      editable: 'always',
      title: translate('common:Team'),
      field: 'id_team',
      lookup: teams,
      validate: row => row.id_team && row.id_team !== '0',
    },
    {
      editable: 'always',
      title: translate('common:EquipmentGroup'),
      field: 'id_equipment_group',
      lookup: locomotiveGroups,
      // validate: row => row.id_equip && row.id_equip!== '0',
    },
    {
      editable: 'always',
      title: translate('common:Equipment'),
      field: 'id_equip',
      lookup: locomotives,
      customType: 'filterLookup',
      lookupFilter: (option, row) => String(option.id_group) === String(row.id_equipment_group)
          || String(row.id_equipment_group) === '0',
      validate: row => row.id_equip && row.id_equip !== '0',
    },
    {
      editable: 'always',
      title: translate('common:OperationType'),
      field: 'id_operation_type',
      lookup: operationTypes,
      validate: row => row.id_operation_type && row.id_operation_type !== '0',
    },
    {
      editable: 'never',
      title: translate('common:Material'),
      lookup: materials,
      render: row => (
        <IconButton
          style={{ outline: 'none' }}
          onClick={() => {
            setRailroadReport(row);
            setModalMaterialOpen(true);
          }}
        >
          <Build style={iconStyle} />
        </IconButton>
      ),
    },
    {
      editable: 'never',
      title: translate('common:LoadEquipmentNumber'),
      field: 'railroad_equipment_report',
      render: row => (
        <IconButton
          style={{ outline: 'none' }}
          onClick={() => {
            setRailroadReport(row);
            setModalLoadEquipmentNumberOpen(true);
          }}
        >
          <LocalShipping style={iconStyle} />
        </IconButton>
      ),
    },
    {
      editable: 'always',
      title: translate('common:OperatorGroup'),
      field: 'id_operator_group',
      lookup: operatorsGroups,
      validate: row => row.id_operator_group && row.id_operator_group !== '0',
    },
    {
      editable: 'always',
      title: translate('common:Operator'),
      field: 'id_operator',
      customType: 'filterLookup',
      lookupKey: 'id_operator',
      lookup: operators,
      lookupFilter: (e, f) => String(e.id_group) === String(f.id_operator_group)
        || String(f.id_operator_group) === '0',
      validate: row => row.id_operator && row.id_operator !== '0',
    },
    {
      editable: 'always',
      title: translate('common:Origin'),
      field: 'id_origin',
      customType: 'filterLookup',
      lookup: elements,
      validate: row => row.id_origin && row.id_origin !== '0',
    },
    {
      editable: 'always',
      title: translate('common:OriginPoint'),
      field: 'id_origin_point',
      lookup: subElements,
      customType: 'filterLookup',
      lookupFilter: (option, row) => String(option.id_element) === String(row.id_origin)
           || String(row.id_origin) === '0',
      validate: row => row.id_origin_point && row.id_origin_point !== '0',
    },
    {
      editable: 'always',
      title: translate('common:Destination'),
      field: 'id_destination',
      customType: 'filterLookup',
      lookup: elements,
      validate: row => row.id_destination && row.id_destination !== '0',
    },
    {
      editable: 'always',
      title: translate('common:DestinationPoint'),
      field: 'id_destination_point',
      lookup: subElements,
      customType: 'filterLookup',
      lookupFilter: (option, row) => String(option.id_element) === String(row.id_destination)
           || String(row.id_destination) === '0',
      validate: row => row.id_destination_point && row.id_destination_point !== '0',
    },
    {
      editable: 'never',
      title: translate('common:CycleStageTimes'),
      field: 'cycle_stage_times',
      render: row => (
        <IconButton
          style={{ outline: 'none' }}
          onClick={() => {
            setRailroadReport(row);
            setModalRailroadCycleStepTimesOpen(true);
          }}
        >
          <AccessTime style={iconStyle} />
        </IconButton>
      ),
    },
    {
      editable: 'always',
      title: translate('common:TotalInCode'),
      field: 'code_time',
      validate: row => !!row.code_time || row.code_time === 0,
    },
    {
      editable: 'always',
      title: translate('common:FullDistance'),
      field: 'full_distance',
    },
    {
      editable: 'always',
      title: translate('common:EmptyDistance'),
      field: 'empty_distance',
    },
    {
      editable: 'always',
      title: translate('common:LoadLatitude'),
      field: 'load_lat',
    },
    {
      editable: 'always',
      title: translate('common:LoadLongitude'),
      field: 'load_lon',
    },
    {
      editable: 'always',
      title: translate('common:LoadAltitude'),
      field: 'load_alt',
    },
    {
      editable: 'always',
      title: translate('common:UnloadLatitude'),
      field: 'unload_lat',
    },
    {
      editable: 'always',
      title: translate('common:UnloadLongitude'),
      field: 'unload_lon',
    },
    {
      editable: 'always',
      title: translate('common:UnloadAltitude'),
      field: 'unload_alt',
    },
    {
      editable: 'never',
      title: translate('common:CycleType'),
      field: 'exception_type',
      lookup: exceptionTypes,
    },
  ];

  const handleOnApply = () => {
    const newRailroadList = railroadList.map(({ id_equipment_group, ...obj }) => obj);

    dispatch(updateRailroadList(newRailroadList, filters));
  };

  return (
    <>
      <PF2MLoading loading={isLoadingList} />

      <PF2MDataTable
        onChangePage={(newPage, newLimit) => {
          if (newLimit <= 100 && newPage - 1 <= 10000 / newLimit) {
            dispatch(searchRailroadList(filters, newPage, newLimit));
          }
        }}
        page={page}
        totalCount={100}
        title={translate('common:Cycles')}
        options={{
          showTitle: true,
          search: false,
          selection: true,
          pageSize: limit === 30 ? 10 : limit,
          pageSizeOptions: [10, 20, 50, 100],
          cacheKey: 'daily_parts_railroad',
        }}
        onAdd={newData => dispatch(newRailroadItem(newData))}
        onChange={(newData) => {
          newData.forEach((row) => {
            dispatch(setRailroadValues(row));
          });
        }}
        onDiscard={() => dispatch(restoreOriginalRailroad())}
        onApply={handleOnApply}
        onDelete={(newData) => {
          newData.forEach((e) => {
            dispatch(deleteRailroadItem(e));
          });
        }}
        initialFormData={
          {
            ...columns.reduce((acc, e) => ({ ...acc, [e.field]: '0' }), {}),
            start_date: formatDate(newMomentDate(), 'DD-MM-YYYY'),
            end_date: formatDate(newMomentDate(), 'DD-MM-YYYY'),
            start_time: formatTime(newMomentDate(), 'HH:mm:ss'),
            end_time: formatTime(newMomentDate(), 'HH:mm:ss'),
            materials: initialDataMaterials,
            load_equips: [{
              update_status: 'I',
              op_date: formatDate(newMomentDate(), 'DD-MM-YYYY'),
              op_time: formatTime(newMomentDate(), 'HH:mm:ss'),
            }],
          }
        }
        columns={columns}
        data={railroadList.length > 0 ? railroadList : []}
      />

      <Modal
        open={modalMaterialOpen}
        onClose={() => setModalMaterialOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <RailroadMaterialModal
          handleClose={() => setModalMaterialOpen(false)}
          railroadReport={railroadReport}
        />
      </Modal>

      <Modal
        open={modalRailroadCycleStepTimesOpen}
        onClose={() => setModalRailroadCycleStepTimesOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <RailroadCycleStepTimesModal
          handleClose={() => setModalRailroadCycleStepTimesOpen(false)}
          railroadReport={railroadReport}
        />
      </Modal>

      <Modal
        open={modalLoadEquipmentNumberOpen}
        onClose={() => setModalLoadEquipmentNumberOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadEquipmentNumberModal
          handleClose={() => setModalLoadEquipmentNumberOpen(false)}
          railroadReport={railroadReport}
        />
      </Modal>
    </>
  );
};

RailRoadList.propTypes = {};

RailRoadList.defaultProps = {};

export default withStyles(styles)(RailRoadList);
