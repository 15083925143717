import React, { useEffect, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  deleteLoadItem,
  setLoadValues,
  restoreOriginalLoad,
  updateLoadList,
  newLoadItem,
} from '~/store/dailyparts/actions';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import { getOperationTypes } from '~/store/manager/actions';
import PF2MDataTable from '~/components/PF2MDataTable';
import {
  formatDate, formatTime, newMomentDate, parseDateToTimestamp, subtractDaysFromNow, parseDate,
} from '~/utils/moment';
import usePlatformConfiguration, {
  usePlatformConfigurationUserAccessLevel,
} from '~/services/platformConfiguration';

// const EXCAVATOR_ID = 1;
const TRUCK_ID = 2;
const WHEEL_LOADER = 15;

const getTruckByIdTruck = (trucks, idTruck) => trucks.find(t => Number(t.id) === Number(idTruck));

const createTruckGroupFieldInLoadList = (equipments, loadList) => {
  const trucks = equipments;
  return loadList.map((l) => {
    const truck = getTruckByIdTruck(trucks, l.id_truck);
    return {
      ...l,
      id_group_truck: truck?.id_group ?? '',
      control_type_id: l?.control_type_id ?? 0,
    };
  });
};

const LoadList = () => {
  const { t: translate } = useTranslation();

  const filters = useSelector(state => state.dailyParts.filters);
  const userAccessLevel = useSelector(state => state.auth.user.level);
  const equipmentType = useSelector(
    state => state.manager.equipmentTypes.find(r => r.id === filters.equipmentType), shallowEqual,
  );
  const turns = useSelector(state => state.manager.turns);
  const teams = useSelector(state => state.manager.teams);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const excavatorGroups = useSelector(state => state.manager.equipmentsGroups
    .filter(e => e.id_equipament === equipmentType.id), shallowEqual);
  const equipments = useSelector(state => state.manager.equipments);
  const truckGroups = useSelector(state => state.manager.equipmentsGroups
    .filter(e => e.id_equipament === TRUCK_ID), shallowEqual);
  const trucks = useSelector(state => state.manager.equipments
    .filter(e => e.id_equip === TRUCK_ID), shallowEqual);
  const loadListState = useSelector(state => state.dailyParts.loadList);

  const loadList = useMemo(() => createTruckGroupFieldInLoadList(equipments, loadListState),
    [loadListState, equipments]);

  const operatorsGroups = useSelector(
    state => state.manager.operatorsGroups.filter(o => o.name), shallowEqual,
  );
  const operators = useSelector(state => state.manager.operators);
  const materials = useSelector(state => state.manager.materials);
  const exceptionTypes = useSelector(state => state.manager.exceptionTypes);
  const operationTypes = useSelector(state => state.manager.operationTypes
    .filter(e => e.id_equipment_type === equipmentType.id), shallowEqual);

  const controlTypes = useSelector(state => state.manager.controlTypes);

  const closures = useSelector(state => state.manager.closures);
  const { value: userLimitEditConfig } = usePlatformConfigurationUserAccessLevel(userAccessLevel);
  const { value: enableClosure } = usePlatformConfiguration('enable_closure');

  const dispatch = useDispatch();

  const validateDates = key => (row) => {
    const startDateTimestamp = parseDateToTimestamp(row.start_date, row.start_time);
    const endDateTimestamp = parseDateToTimestamp(row.end_date, row.end_time);

    const isEnableClosureOn = enableClosure && closures?.length > 0;
    let userLimitEdit = subtractDaysFromNow(userLimitEditConfig);

    if (isEnableClosureOn) {
      const dateNow = newMomentDate();
      const lastClosureDate = parseDate(closures[0].timestampClose, true);
      const currentDaysFromLastClosure = dateNow.diff(lastClosureDate, 'days');

      const lockEditConfig = subtractDaysFromNow(userLimitEditConfig);
      const lockEditLastClosure = subtractDaysFromNow(currentDaysFromLastClosure);

      userLimitEdit = currentDaysFromLastClosure > userLimitEditConfig
        ? lockEditConfig : lockEditLastClosure;
    }

    if (key === 'end_date' && startDateTimestamp > endDateTimestamp) {
      return { error: true, helperText: translate('validation:FinalDateSmallerThanInitialDate') };
    }

    const invalidEditPeriodDateMsg = {
      isValid: false, helperText: translate('validation:InvalidPeriodDate'),
    };

    if (key === 'start_date' && parseDate(row[key]).isBefore(userLimitEdit)) return invalidEditPeriodDateMsg;

    return { isValid: true };
  };

  const columns = [
    {
      editable: 'always',
      title: translate('common:InitialDate'),
      field: 'start_date',
      customType: 'date',
      validate: validateDates('start_date'),
    },
    {
      editable: 'always',
      title: translate('common:FinalDate'),
      field: 'end_date',
      customType: 'date',
      validate: validateDates('end_date'),
    },
    {
      editable: 'always',
      title: translate('common:InitialHour'),
      field: 'start_time',
      customType: 'time',
      validate: validateDates('start_time'),
    },
    {
      editable: 'always',
      title: translate('common:FinalHour'),
      field: 'end_time',
      customType: 'time',
      validate: validateDates('end_time'),
    },
    {
      editable: 'never',
      title: translate('common:Duration'),
      field: 'duration',
      customType: 'numeric',
    },
    {
      editable: 'always',
      title: translate('common:Shift'),
      field: 'turn',
      lookup: turns.map(e => ({
        id: e.id,
        name: `${e.turn_group_name} - ${e.name}`,
        active: e.active,
      })),
      validate: row => row.turn !== '0'
        && row.turn !== 0 && Boolean(row.turn),
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Team'),
      field: 'team_id',
      lookup: teams,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:OperatorGroup'),
      field: 'id_group_excavator_operator',
      lookup: operatorsGroups,
      validate: row => row.id_group_excavator_operator !== '0',
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Operator'),
      field: 'id_excavator_operator',
      customType: 'filterLookup',
      lookupKey: 'id_operator',
      lookup: operators,
      lookupFilter: (e, f) => String(e.id_group) === String(f.id_group_excavator_operator)
        || String(f.id_group_excavator_operator) === '0',
      validate: row => row.id_operator !== '0',
    },
    {
      editable: 'always',
      title: translate('common:Material'),
      field: 'id_material_esc',
      customType: 'filterLookup',
      lookup: materials,
      validate: row => row.id_material_esc && row.id_material_esc !== '0',
    },
    {
      editable: 'always',
      title: translate('common:EquipmentGroup'),
      field: 'id_excavator_group',
      lookup: excavatorGroups,
      validate: row => row.id_excavator_group && row.id_excavator_group !== '0',
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Equipment'),
      field: 'id_excavator',
      lookup: equipments.filter(e => e.id_equip === equipmentType.id),
      validate: row => row.id_excavator && row.id_excavator !== '0',
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:OperationType'),
      field: 'id_operation_type',
      lookup: operationTypes,
      validate: row => row.id_operation_type && row.id_operation_type !== '0',
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:TruckEquipmentGroup'),
      field: 'id_group_truck',
      lookup: truckGroups,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:TruckEquipment'),
      field: 'id_truck',
      lookup: [{ id: '0', name: 'Nenhum' }, ...trucks],
      customType: 'filterLookup',
      lookupFilter: (option, row) => {
        const idTruck = Number(option.id);
        const truck = trucks.find(t => t.id === idTruck);
        const idGroupTruck = Number(truck?.id_group);
        const rowIdGroupTruck = Number(row.id_group_truck);
        return idGroupTruck === rowIdGroupTruck || option.id === '0';
      },
    },
    {
      editable: 'always',
      title: translate('common:Origin'),
      field: 'id_mine',
      customType: 'filterLookup',
      lookup: elements,
      validate: row => row.id_mine && row.id_mine !== '0',
    },
    {
      editable: 'always',
      title: translate('common:OriginPoint'),
      field: 'id_mine_origin',
      customType: 'filterLookup',
      lookupFilter: (option, row) => (
        String(option.id_element) === String(row.id_mine)
        || String(row.id_mine) === '0'
      ),
      lookup: subElements,
      validate: row => row.id_mine_origin && row.id_mine_origin !== '0',
    },
    {
      editable: 'always',
      title: translate('common:Destination'),
      field: 'id_destination_esc',
      customType: 'filterLookup',
      lookup: elements,
      validate: row => row.id_destination_esc && row.id_destination_esc !== '0',
    },
    {
      editable: 'always',
      title: translate('common:DestinationPoint'),
      customType: 'filterLookup',
      field: 'id_destination_point_esc',
      lookupFilter: (option, row) => (
        String(option.id_element) === String(row.id_destination_esc)
        || String(row.id_destination_esc) === '0'
      ),
      lookup: subElements,
      validate: row => row.id_destination_point_esc && row.id_destination_point_esc !== '0',
    },
    {
      editable: 'always',
      title: translate('common:MaterialWeight'),
      field: 'load_manager',
      validate: row => !!row.load_manager || row.load_manager === 0,
    },
    {
      editable: 'always',
      title: translate('common:LoadTime'),
      field: 'load_time',
      validate: row => !Number.isNaN(Number.parseInt(row.load_time)),
    },
    {
      editable: 'always',
      title: translate('common:IdleTime'),
      field: 'stop_time',
      validate: row => !Number.isNaN(Number.parseInt(row.stop_time)),
    },
    {
      editable: 'always',
      title: translate('common:CodeTime'),
      field: 'code_time',
      validate: row => !Number.isNaN(Number.parseInt(row.code_time)),
    },
    {
      editable: 'never',
      title: translate('common:EntryType'),
      field: 'exception_type',
      lookup: exceptionTypes,
      validate: row => row.exception_type && row.exception_type !== '0',
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Latitude'),
      field: 'load_lat',
    },
    {
      editable: 'always',
      title: translate('common:Longitude'),
      field: 'load_lon',
    },
    {
      editable: 'always',
      title: translate('common:Altitude'),
      field: 'load_alt',
    },
    {
      editable: 'always',
      title: translate('common:ControlType'),
      field: 'control_type_id',
      customType: 'filterLookup',
      lookup: [{ id: '0', name: translate('common:None') }, ...controlTypes],
      lookupFilter: option => (
        option.equip_type_id === equipmentType.id || !option.equip_type_id
      ),
    },
  ];

  useEffect(() => {
    if (equipmentType.id === WHEEL_LOADER) {
      dispatch(getOperationTypes());
    }
  }, [equipmentType, dispatch]);

  return (
    <>
      <PF2MDataTable
        title={translate('common:Cycles')}
        options={{
          showTitle: true,
          search: true,
          selection: true,
          cacheKey: 'daily_parts_loads',
        }}
        onAdd={newData => dispatch(newLoadItem(newData))}
        onChange={(newData) => {
          newData.forEach((row) => {
            dispatch(setLoadValues(row));
          });
        }}
        onDiscard={() => dispatch(restoreOriginalLoad())}
        onApply={() => {
          dispatch(
            updateLoadList(
              loadList.map(l => ({ ...l, id_group_truck: undefined })),
              filters,
            ),
          );
        }}
        onDelete={(newData) => {
          newData.forEach((e) => {
            dispatch(deleteLoadItem(e));
          });
        }}
        initialFormData={
          {
            ...columns.reduce((acc, e) => ({ ...acc, [e.field]: '0' }), {}),
            start_date: formatDate(newMomentDate(), 'DD-MM-YYYY'),
            end_date: formatDate(newMomentDate(), 'DD-MM-YYYY'),
            start_time: formatTime(newMomentDate(), 'HH:mm:ss'),
            end_time: formatTime(newMomentDate(), 'HH:mm:ss'),
          }
        }
        columns={columns}
        data={loadList.length > 0 ? loadList : []}
      />
    </>
  );
};

LoadList.propTypes = {};

LoadList.defaultProps = {};

export default withStyles(styles)(LoadList);
