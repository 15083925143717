import { useSelector } from 'react-redux';

export default function useTabletConfiguration(key) {
  if (!key) {
    throw new Error('Missing key to get tablet_configuration');
  }

  const tabletConfig = useSelector(state => state.manager.tabletConfiguration);
  const configuration = tabletConfig.find(conf => conf.key === key) || { error: 'Not Found', key };
  return configuration;
}
