/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import {
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { DispatchDragNDropTypes, DispatchOperationType } from '~/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import PF2MEquipIcon from '~/components/PF2MEquipIcon';
import PF2MDispatchIcon from '~/components/PF2MDispatchIcon';
import { setSelectedElementDestination, setSelectedTruckDestination } from '~/store/dispatch/actions';

const getWarningType = (truckOrigin, destinations, isGenericRow) => {
  if (isGenericRow) return 'standardWarning';
  const expectedDestination = destinations
    .find(r => r.id === truckOrigin.destination_point_id);

  if (expectedDestination
    && truckOrigin.current_destination_id !== expectedDestination.id) {
    return 'standardWarning';
  }
  return null;
};

const getWarningTypeForDestination = (destinationRow) => {
  if (!destinationRow.is_programmed) return 'standardWarning';
  return '';
};

const DestinationRow = ({
  onTruckDrop, destination, leftLane, rightLane, isGenericRow = false,
  destinations, classes, openTruckModal, openElementModal, onTruckContextMenu,
}) => {
  const { t: translate } = useTranslation();

  const order = [
    DispatchOperationType.LOAD,
    DispatchOperationType.UNLOAD,
    DispatchOperationType.LOAD_MANEUVER,
    DispatchOperationType.UNLOAD_MANEUVER,
    DispatchOperationType.LOAD_QUEUE,
    DispatchOperationType.UNLOAD_QUEUE,
    DispatchOperationType.LOAD_TRAFFIC,
    DispatchOperationType.UNLOAD_TRAFFIC,
  ];

  const equipmentStatuses = useSelector(state => state.dispatch.equipmentStatuses);
  const codes = useSelector(state => state.manager.codes);
  const operators = useSelector(state => state.manager.operators);

  const leftLaneSorted = useMemo(() => leftLane.sort(
    (a, b) => order.indexOf(a.operation_type) - order.indexOf(b.operation_type),
  ), [leftLane, order]);

  const leftLaneDurations = leftLaneSorted.map((left) => {
    const matchingStatuses = equipmentStatuses.filter(
      status => status.id_equip === left.equip_id,
    );

    const durations = matchingStatuses.flatMap(status => status.duration);

    const mappedStatuses = matchingStatuses.map((status) => {
      const foundCode = codes.find(c => c.id === status.id_code);
      const foundOperator = operators.find(op => op.id_operator === status.id_operator);

      return {
        ...status,
        codeName: foundCode ? foundCode.name : translate('common:CodeNotFound'),
        operatorName: foundOperator ? foundOperator.name : translate('common:OperatorNotFound'),
      };
    });

    const codesNames = mappedStatuses.map(status => status.codeName);
    const operatorsNames = mappedStatuses.map(status => status.operatorName);

    return {
      ...left,
      durations,
      codes_name: codesNames,
      operators_name: operatorsNames,
    };
  });


  const rightLaneSorted = useMemo(() => rightLane.sort(
    (a, b) => order.indexOf(a.operation_type) - order.indexOf(b.operation_type),
  ), [rightLane, order]);

  const rightLaneDurations = rightLaneSorted.map((right) => {
    const matchingStatuses = equipmentStatuses.filter(
      status => status.id_equip === right.equip_id,
    );

    const durations = matchingStatuses.flatMap(status => status.duration);

    const mappedStatuses = matchingStatuses.map((status) => {
      const foundCode = codes.find(c => c.id === status.id_code);
      const foundOperator = operators.find(op => op.id_operator === status.id_operator);

      return {
        ...status,
        codeName: foundCode ? foundCode.name : translate('common:CodeNotFound'),
        operatorName: foundOperator ? foundOperator.name : translate('common:OperatorNotFound'),
      };
    });

    const codesNames = mappedStatuses.map(status => status.codeName);
    const operatorsNames = mappedStatuses.map(status => status.operatorName);

    return {
      ...right,
      durations,
      codes_name: codesNames,
      operators_name: operatorsNames,
    };
  });

  const dispatch = useDispatch();
  const [{ dragItem }, truckDrop] = useDrop({
    accept: DispatchDragNDropTypes.DESTINATION_TRUCK,
    drop: (monitor) => {
      if (destination.id !== monitor.obj.destination_point_id && destination.id !== null
        && monitor.obj.current_destination_id !== destination.id && destination.is_programmed) {
        onTruckDrop(monitor.obj, destination.id);
      }
    },
    collect: monitor => ({
      dragItem: monitor.getItem(),
    }),
  });

  return (
    <div
      key={destination.id}
      style={{
        display: 'flex',
        width: '100%',
        height: 110,
      }}
      ref={truckDrop}
    >
      <PF2MScrollbar
        className={classes.scrollbars}
        style={{ width: '50%' }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row-reverse',
            backgroundColor: '#dedede',
          }}
        >
          <PF2MDispatchIcon
            iconType={'excavator'}
            customStyles={{
              marginLeft: 15,
              marginRight: 15,
              cursor: 'default',
            }}
          />
          {
            leftLaneDurations.map(r => (
              <PF2MEquipIcon
                key={r.equip_id}
                extraInfo={`${r.current_excavator_name}`}
                item={r}
                itemType={DispatchDragNDropTypes.DESTINATION_TRUCK}
                useOperationType
                customStyles={{
                  marginRight: 15,
                  opacity: (dragItem && dragItem.obj?.equip_id === r.equip_id) ? 0 : 1,
                }}
                onClick={() => {
                  dispatch(setSelectedTruckDestination(r));
                  openTruckModal(true);
                }}
                warningType={getWarningType(r, destinations, isGenericRow)}
                onContextMenu={onTruckContextMenu}
                tooltipData={r}
              />
            ))
          }
        </div>
      </PF2MScrollbar>
      <PF2MScrollbar
        className={classes.scrollbars}
        style={{ width: '50%' }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row-reverse',
            backgroundColor: '#dedede',
          }}
        >
          <PF2MDispatchIcon
            iconType={'stack'}
            subtitle={isGenericRow ? '' : destination.name}
            customStyles={{
              marginLeft: 15,
              marginRight: 15,
              cursor: 'pointer',
            }}
            warningType={getWarningTypeForDestination(destination)}
            onClick={() => {
              if (!isGenericRow) {
                dispatch(setSelectedElementDestination(destination));
                openElementModal(true);
              }
            }}
          />
          {
            rightLaneDurations.map(r => (
              <PF2MEquipIcon
                key={r.equip_id}
                // extraInfo={r.destination_point_name || ''}
                extraInfo={`${r.current_destination_point_name}`}
                item={r}
                itemType={DispatchDragNDropTypes.DESTINATION_TRUCK}
                useOperationType
                customStyles={{
                  marginRight: 15,
                  opacity: (dragItem && dragItem.obj?.equip_id === r.equip_id) ? 0 : 1,
                }}
                onClick={() => {
                  dispatch(setSelectedTruckDestination(r));
                  openTruckModal(true);
                }}
                warningType={getWarningType(r, destinations, isGenericRow)}
                onContextMenu={onTruckContextMenu}
                tooltipData={r}
              />
            ))
          }
        </div>
      </PF2MScrollbar>
    </div>
  );
};

DestinationRow.propTypes = {
  classes: PropTypes.object,
  onTruckDrop: PropTypes.func,
  leftLane: PropTypes.array,
  rightLane: PropTypes.array,
  isGenericRow: PropTypes.bool,
  destination: PropTypes.object,
  destinations: PropTypes.array,
  openTruckModal: PropTypes.func,
  openElementModal: PropTypes.func,
  onTruckContextMenu: PropTypes.func,
};

DestinationRow.defaultProps = {
  classes: {},
  onTruckDrop: () => null,
  leftLane: [],
  rightLane: [],
  isGenericRow: false,
  destination: {},
  destinations: [],
  openTruckModal: () => null,
  openElementModal: () => null,
  onTruckContextMenu: null,
};

export default withStyles(styles)(DestinationRow);
