const { updateEquipmentsSchedules } = require('~/store/dispatch/actions');
const { newMomentDate, formatTime } = require('~/utils/moment');

export const createPerforatorScheduleItem = ({ comment, subelement_id, spa }) => ({
  observation: comment,
  flowchart_element_point_id: subelement_id,
  update_status: 'I',
  equipaments_id: 4,
  group_equipament_links_id: spa.equip_id,
  code_types_id: 2,
  code_groups_id: 1,
  status_id: 1,
  codes_id: -1,
  start_date: newMomentDate(),
  start_time: formatTime(new Date()),
  duration: '00:00:00',
  delay: '00:00:00',
});

export const onPerforatorScheduleDelete = ({ dispatch, spa, scheduleId }) => {
  const si = createPerforatorScheduleItem({
    comment: spa.observation,
    subelement_id: spa.flowchart_element_point_id,
    spa,
  });
  const es = [{ ...si, id: scheduleId, update_status: 'D' }];
  dispatch(updateEquipmentsSchedules(es, {}, true));
};

export const onPerforatorScheduleSave = ({
  subelement_id,
  dispatch,
  spa,
  comment = '',
}) => {
  const si = createPerforatorScheduleItem({ comment, subelement_id, spa });
  dispatch(updateEquipmentsSchedules([si], {}, true));
};
